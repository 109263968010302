import React, { useEffect, useState } from 'react'
import { Trip } from '../../../../api-requests/Meeting'
import { Select, Table } from 'antd';
import { ExtendedTableColumnType } from '../../../timesheet/components/TimesheetTable';
import ActionCell from '../../../../components/cell/ActionCell';
import TravellersCell from '../../../../components/cells/TravellersCell';
import { useAuth } from '../../../auth';
import { EditOutlined, DeleteOutlined, EyeOutlined, CarOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Beneficiary, fetchBeneficiaries } from '../../../../api-requests/Projects';
import { useMeetings } from '../../meeting/provider/useMeetings';


const { Option } = Select;
type Props = {
    trips: Trip[];
    title: string;
    setDuplicateTrip: any;
    values: any;
    setFieldValue: any;
    resetForm: any;
    single: number;
    setSingle: (d: number) => void;
}

const BeneficiariesTable = ({ trips, title, single, setSingle, values, setDuplicateTrip, setFieldValue, resetForm }: Props) => {
    const { id } = useAuth();
    const navigate = useNavigate();
    const { meetings } = useMeetings();
    const [ben, setBen] = useState<Beneficiary[]>([]);


    const fetchBeneficiariesTable = async () => {
        const beneficiaries = await fetchBeneficiaries({ projectId: values.Meeting.Project.Id });
        return beneficiaries;
    }
    useEffect(() => {
        fetchBeneficiariesTable().then((res) => { setBen(res); setSingle(res[0].Id) })
    }, [])
    return (
        <div>
            <span>{title}</span>
            <Select
                placeholder="Select an organization"
                onChange={(value) => setSingle(value)}
                style={{ width: '100%' }}
                value={single}
                className='mt-4'
            >
                {ben.map((org: any) => (
                    <Option key={org.Id} value={org.Id}>
                        {org.Organisation.ShortName} {values.Meeting.Trips.filter((trip: Trip) => trip.Beneficiary.Id === org.Id).length > 0 ? '(trip exists)' : ''}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default BeneficiariesTable;