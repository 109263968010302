
import { Typography } from 'antd';
import { TableProvider } from '../../table/provider/TableContext';
import { Meeting } from '../../../api-requests/Meeting';
import { pageSelectOptions } from '../../../mock/PageSelection';
import { Team } from '../../../api-requests/Employees';
import { useTeam } from './provider/useTeam';
import TeamTable from './components/teamsTable';


const TeamTableWrapper = () => {
    const { teams, getTeams, teamsTotal } = useTeam();

    return (
        <TableProvider<Team>
            data={teams}
            defaultSort={[]}
            page={pageSelectOptions[0]}
            filters={[
            ]}
            actions={[getTeams]}
            totalSizeData={teamsTotal}
            fetchData={getTeams}
        >
            <TeamTable />
        </TableProvider>
    );
};

export default TeamTableWrapper;
