import "./Sentry"
import { createRoot } from 'react-dom/client';
// Axios
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
// Apps
import AppRoutes from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/features/auth';
import './styles.css'
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

setupAxios(axios);

const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
  const routes: RouteObject[] = [
    { path: '/*', element: <AppRoutes /> }
  ];
  const router = createBrowserRouter(
    routes
  );
  if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
  }
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>,
  );
}
