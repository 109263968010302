import { Button, DatePicker, Input, Select, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { ErrorMessage, useFormikContext } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
    FormOutlined,
    EnvironmentOutlined,
    FileAddOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { Meeting, Trip } from '../../../../api-requests/Meeting'
import { useAuth } from '../../../auth'
import { fetchRelatedPeople } from '../../../../api-requests/Employees'
import { Beneficiary, Project, fetchBeneficiaries, fetchProjects } from '../../../../api-requests/Projects'
import LoomInput from '../../../../components/StyledComponents/LoomInput'
import LoomSelect from '../../../../components/StyledComponents/LoomSelect'
import LoomDatePicker from '../../../../components/StyledComponents/LoomDatePicker'
import { useTrip } from '../provider/useTrip'
import WarningModal from '../../../../components/modal/WarningModal'
import { LeaveColors } from '../../../../../shared/colors'
type Props = {
    values: Trip;
    setFieldValue: any;
    data: Trip | undefined;
    approving?: boolean;
}


const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal', // Use decimal style to avoid currency symbols
        minimumFractionDigits: 2, // Ensures two decimal places
        maximumFractionDigits: 2, // Ensures two decimal places
    }).format(amount);
};
const TripBasicInformation = ({ values, setFieldValue, data, approving = false }: Props) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [beneficiaryChangeModal, setBeneficiaryChangeModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [newBeneficiary, setNewBeneficiary] = useState()
    const { id } = useAuth();
    const { getBeneficiaries, beneficiaries } = useTrip();
    useEffect(() => {
        fetchProjects({
            searchTerm: searchValue,
        }).then((res) => setProjects(res.Results));
    }, []);
    const formatInitiaProjectslData = async (searchTerm = '') => {
        const fetchedData = await fetchProjects({
            searchTerm,
        });
        setProjects(fetchedData.Results);
    };


    // useEffect(() => {
    //     if (values.Meeting && values.Meeting.Id !== data?.Meeting?.Id && !values.Meeting.AllDay)
    //         setFieldValue('EarliestDepartureTime', dayjs(values.Meeting.To).add(1, 'hour'))

    // }, [values.Meeting?.To]);
    // useEffect(() => {
    //     console.log('lateeest', values.LatestArrivalTime)
    //     if (values.Meeting && values.Meeting.Id !== data?.Meeting?.Id && !values.Meeting.AllDay)
    //         setFieldValue('LatestArrivalTime', dayjs(values.Meeting.From).subtract(1, 'hour'))

    // }, [values.Meeting?.From]);
    useEffect(() => {
        if (values.Destination.Country?.Name !== '' && values.Project.Acronym)
            setFieldValue('Title', (values.Destination.Country && values.Destination.Country.Name) ? 'Trip to ' + values.Destination.Country?.Name + ", for Project: " + values.Project.Acronym : 'Trip for project: ' + values.Project.Acronym);

    }, [values.Destination?.Country?.Name, values.Project.Id]);

    return (
        <div className={`flex flex-col justify-center gap-4`}>
            <WarningModal
                title="Beneficiary changes"
                isOpen={beneficiaryChangeModal}
                setIsOpen={setBeneficiaryChangeModal}
                footerButtons={[
                    <Button onClick={() => {
                        setBeneficiaryChangeModal(false);

                    }}>Cancel</Button>,
                    <Button
                        onClick={() => { setBeneficiaryChangeModal(false); setFieldValue('Travellers', []); setFieldValue('Beneficiary', beneficiaries.filter((ben) => ben.Id === newBeneficiary)[0]) }}
                        style={{ background: LeaveColors[0].value, color: 'white' }}
                    >
                        Continue
                    </Button>,

                ]}
                mainText="Are you sure you want to change beneficiary? This action will remove the travellers."
            />
            <div className="flex justify-center flex-row w-full gap-4">
                <div
                    data-testid="leaveTypeDropdown"
                    className="w-full flex gap-2 flex-col"
                >
                    <LoomInput
                        required
                        label="Title"
                        name="Title"
                        placeholder="Enter title"
                        prefix={<FormOutlined />}
                        value={values.Title}
                        onChange={(e) => { setFieldValue('Title', e.target.value) }}
                        disabled={approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)}
                    />
                </div>
            </div>

            <div className='w-full flex flex-row gap-2 '>
                <div className='w-full flex gap-2 flex-col'>
                    <LoomSelect<number | { value: number, label: string }>
                        name="Project.Acronym"
                        label={"Project"}
                        required
                        disabled={approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id) || values.Meeting.Id !== 0}
                        placeholder="Type project..."
                        valueFunc={
                            values?.Project.Id !== 0 ? { value: values.Project.Id, label: values.Project.Acronym } as any : undefined
                        }
                        onChange={async (val: number | { value: number, label: string }) => {
                            setFieldValue(
                                'Project',
                                projects.filter((proj) => proj.Id === val)[0],
                            );
                            const ben = await getBeneficiaries(projects.filter((proj) => proj.Id === val)[0].Id);
                            if (ben && ben.length > 0) {
                                setFieldValue('Beneficiary', ben[0]);

                            }
                        }}
                        onSearch={(value: string) => formatInitiaProjectslData(value)}
                        options={projects.map((d) => ({
                            label: d.Acronym,
                            value: d.Id,
                        }))}
                    />
                </div>
                <LoomSelect<number | { value: number, label: string } | null>
                    name="Beneficiary"
                    label={"Beneficiary"}
                    required
                    disabled={values.Meeting.Trips.length > 0 || approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)}
                    placeholder=""
                    valueFunc={{ value: values.Beneficiary ? values.Beneficiary.Id : 0, label: values.Beneficiary ? values.Beneficiary.Organisation.BusinessName : '' }}
                    options={[...beneficiaries.map((ben) => {
                        return {
                            value: ben.Id,
                            label: ben.Organisation.BusinessName
                        }
                    })]}
                    onChange={(val) => { if (values.Travellers.length > 0) { setBeneficiaryChangeModal(true); setNewBeneficiary(val as any) } else { setFieldValue('Beneficiary', beneficiaries.filter((ben) => ben.Id === val)[0]) } }}
                />
            </div>
            <div className='w-full flex flex-row gap-2'>
                <div className='w-full flex gap-2 flex-col'>
                    <LoomInput
                        placeholder='Bugdet'
                        onChange={() => { }}
                        name="To"
                        label={'Project Travel Budget'}
                        disabled={true}
                        value={
                            values.Beneficiary ?
                                formatCurrency(values.Beneficiary.OtherDirectCosts_TravelCosts) : ''
                        }
                    />
                    <ErrorMessage name="From" className="text-red-500" >{msg => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                </div>
                <LoomInput
                    placeholder='Sponsor'
                    onChange={() => { }}
                    name="To"
                    label={'Sponsor'}
                    disabled={true}
                    value={
                        values.Beneficiary ?
                            values.Beneficiary.Sponsor : ''
                    }
                />

                <ErrorMessage name="From" className="text-red-500" >{msg => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>


            </div>
            <div className="w-full grid grid-cols-12 gap-2 ">
                <div
                    data-testid="commentInput"
                    className="flex flex-row w-full col-span-12"
                    style={{ alignItems: 'center', gap: 12, flex: '1 1 0' }}
                >
                    <div className="flex w-full flex-col gap-2">
                        <LoomDatePicker
                            name='From'
                            label={<Tooltip title={"Meeting Location Timezone"} placement={'topLeft'}>
                                <label>Latest Arrival Time ({values?.TimezoneOffset >= 0 ? '+' + values.TimezoneOffset : + values?.TimezoneOffset} UTC)</label>
                            </Tooltip>}

                            onChange={(date) => {
                                setFieldValue('LatestArrivalTime', date.format());
                            }}
                            disabled={approving || (values.Id !== 0 && id !== values.CreatedBy.Id)}
                            maxDate={dayjs(values.Meeting?.From).add(2, 'day')}

                            showTime={
                                {
                                    format: 'HH:mm',
                                    minuteStep: 30,
                                    defaultValue: dayjs('00:00', 'HH:mm'),
                                    // disabledMinutes: () => timeOptions,
                                }
                            }
                            format={
                                'DD MMM YYYY HH:mm'
                            }
                            required
                            value={
                                values.Meeting.AllDay ? dayjs(new Date(values.LatestArrivalTime)).utcOffset(values.TimezoneOffset) :
                                    values.TimezoneOffset
                                        ? dayjs(values.LatestArrivalTime).utcOffset(
                                            Number(values.TimezoneOffset),
                                        ) : dayjs(values.LatestArrivalTime)

                            }

                        // value={
                        //     values.From ? dayjs(values.From).toISOString() : dayjs(new Date())
                        // }
                        />
                    </div>

                    <div className="flex flex-col w-full gap-2">
                        <LoomDatePicker
                            required
                            name="To"
                            label={<Tooltip title={"Meeting Location Timezone"} placement={'topLeft'}>
                                <label>Earliest Departure Time ({(values.TimezoneOffset && values.TimezoneOffset >= 0) ? '+' + values.TimezoneOffset : + values?.TimezoneOffset} UTC)</label>
                            </Tooltip>}
                            minDate={values.Meeting?.Id !== 0 ? dayjs(values.Meeting.To) : dayjs(values.Meeting.To)}
                            onChange={(date) => {
                                setFieldValue('EarliestDepartureTime', date.format());
                            }}
                            disabled={approving || (values.Id !== 0 && id !== values.CreatedBy.Id)}
                            disabledTime={(current) => {
                                const currentFromDate = dayjs(values.Meeting.To).utcOffset(values.TimezoneOffset);
                                const selectedDate = dayjs(current);
                                const minDate = currentFromDate
                                if (selectedDate.isSame(currentFromDate, 'date')) {
                                    // If it's the same date as values.From, disable times within 30 minutes from values.From
                                    const disabledMinutes = (selectedHour: any) => {
                                        if (selectedHour === currentFromDate.hour()) {
                                            return [
                                                ...Array(currentFromDate.minute() + 60).keys(),
                                            ];
                                        }
                                        if (
                                            selectedHour === minDate.hour() &&
                                            currentFromDate.minute() > 30
                                        ) {
                                            return [
                                                ...Array(currentFromDate.minute() - 30).keys(),
                                            ];
                                        }
                                        return [];
                                    };

                                    return {
                                        disabledHours: () => [
                                            ...Array(currentFromDate.hour() + 1).keys(),
                                            ...(currentFromDate.minute() >= 30
                                                ? [currentFromDate.hour()]
                                                : []),
                                        ],
                                        disabledMinutes,
                                    };
                                } else if (
                                    selectedDate.isBefore(currentFromDate, 'date')
                                ) {
                                    // If it's before values.From date, disable all hours
                                    return {
                                        disabledHours: () => [...Array(24).keys()],
                                    };
                                }

                                return {}; // No disabled hours// No disabled hours
                            }}
                            showTime={
                                {
                                    format: 'HH:mm',
                                    minuteStep: 30,
                                    defaultValue: dayjs('00:00', 'HH:mm'),
                                }
                            }
                            format={
                                'DD MMM YYYY HH:mm'
                            }
                            value={
                                values.Meeting.AllDay ? dayjs(new Date(values.EarliestDepartureTime)).utcOffset(values.TimezoneOffset) :
                                    values.TimezoneOffset
                                        ? dayjs(values.EarliestDepartureTime).utcOffset(Number(values.TimezoneOffset))
                                        : dayjs(values.EarliestDepartureTime).utcOffset(Number(values.TimezoneOffset))
                            }
                        />
                        <ErrorMessage name="From" className="text-red-500" >{msg => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                    </div>

                    <ErrorMessage name="From" className="text-red-500">
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage>
                </div>
            </div>
        </div>
    )
}

export default TripBasicInformation