import { Button, Table, TableColumnType, TableColumnsType, TableProps, Tooltip } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CarOutlined,
} from '@ant-design/icons';
import { useState } from 'react';

import moment from 'moment';

import { initMeeting, initTeam } from '../../../../utils/FormValidation/CreateAccountWizardHelper';
import useTableContext from '../../../table/provider/TableContext';
import {
    Meeting,
    createTripFromMeeting,
    deleteMeetingById,
    fetchMeetings,
} from '../../../../api-requests/Meeting';
import ParticipantsCell from '../../../../components/cells/ParticipantsCell';
import LocationCell from '../../../../components/cells/LocationCell';
import ActionCell from '../../../../components/cell/ActionCell';
import EmployeeSidebar from '../../../../components/employeeSidebar/EmployeeSidebar';
import { useNavigate } from 'react-router-dom';

import TableExport from '../../../table/components/TableExport';
import { SortObject, stringifySorting } from '../../../../utils/sortingParser';
import { useAuth } from '../../../auth';
import WarningModal from '../../../../components/modal/WarningModal';
import { AxiosError } from 'axios';
import useNotification from 'antd/es/notification/useNotification';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { LeaveColors } from '../../../../../shared/colors';
import dayjs from 'dayjs';
import TableFilters, { ExtendedColumnType } from '../../../../utils/TableFilters/TableFilters';
import { LeaveData } from '../../../../mock/Leave';
import { Team } from '../../../../api-requests/Employees';
import { useTeam } from '../provider/useTeam';
import TeamCreateModal from './teamCreateModal';
import TravellersCell from '../../../../components/cells/TravellersCell';
import AvatarGroupCell from '../../../../components/cells/AvatarGroupCell';
import { ExtendedTableColumnType } from '../../../timesheet/components/TimesheetTable';


export interface CustomColumnType<T> extends TableColumnType<T> {
    default?: boolean;
}
const TeamTable = () => {
    const { teams, teamsTotal, getTeams, loading, deleteTeam } = useTeam();
    const { id, allowIf } = useAuth();
    const { openNotification } = useNotifications();
    const [createModal, setCreateModal] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [teamSelected, setTeamSelected] = useState<Team>(initTeam)
    const [selectedId, setSelectedId] = useState(0);
    const [columns, setColumns] = useState<ExtendedTableColumnType<Team>[]>([
        {
            dataIndex: 'Name',
            key: 'Name',
            title: 'Name',
            sorter: { multiple: 1 },
            align: 'center',
            default: true,
            width: 100,
        },
        {
            dataIndex: 'Leader',
            key: 'Leader',
            title: 'Leader',
            align: 'center',
            render: (cell) => cell.FullName,
            default: false,
        },
        {
            dataIndex: 'Members',
            key: 'Member',
            title: 'Members',
            align: 'center',
            render: (cell) => <AvatarGroupCell cell={cell} />,
            default: false
        },
        {
            dataIndex: 'CreatedBy',
            key: 'Creator',
            title: 'Creator',
            align: 'center',
            render: (cell) => cell?.FullName,
            default: false
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (cell) => (
                <ActionCell
                    data={cell}
                    items={[
                        cell.Leader.Id !== id ? {
                            key: 'view_team',
                            label: 'View Team',
                            icon: <EyeOutlined />,
                            onClick: () => {
                                setTeamSelected(cell);
                                setCreateModal(true)
                            },
                        } : null,
                        cell.Leader.Id === id || allowIf(['Teams_EditAll']) ? {
                            key: 'edit_trip',
                            label: 'Edit Team',
                            icon: <EditOutlined />,
                            onClick: () => {
                                setTeamSelected(cell);
                                setCreateModal(true)
                            },

                        } : null,
                        cell.Leader.Id === id || allowIf(['Teams_EditAll']) ? {
                            key: 'delete_trip',
                            label: "Delete Team",
                            icon: <DeleteOutlined />,
                            onClick: () => {
                                setSelectedId(cell.Id);
                                setWarningOpen(true);
                            },

                        } : null
                    ]}

                />
            ),
            fixed: 'right',
            align: 'center',
        },

    ])
    const {
        pageSize,
        currentPage,
        handleTableChange,
    } = useTableContext();
    const handleDeleteTeam = () => {
        try {
            deleteTeam(selectedId);
            openNotification('topRight', {
                title: `Team`,
                description: 'Team deleted successfully.',
                type: 'Success',
            });
        } catch (err: any) {
            openNotification('topRight', {
                title: `Team`,
                description: err.response,
                type: 'Danger',
            });
        } finally {
            setWarningOpen(false);
        }
    }
    return (
        <>
            <WarningModal
                title="Delete Team"
                isOpen={warningOpen}
                setIsOpen={setWarningOpen}
                footerButtons={[
                    <Button onClick={() => setWarningOpen(false)}>Close</Button>,
                    <Button
                        onClick={() => {
                            handleDeleteTeam()
                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Delete
                    </Button>,
                ]}
                mainText="Are you sure you want to delete this team? Your action will remove it permanently."
            />
            <TeamCreateModal isOpen={createModal} data={teamSelected} setData={setTeamSelected} onClose={() => { setCreateModal(false); setTeamSelected(initTeam) }} setIsOpen={setCreateModal} fetchData={() => { }} />
            <TableFilters<Team>
                hasFilters={false}
                localStorageKey='TeamTableColumns'
                addNavigate={() => setCreateModal(true)}
                setIsCreateModal={setCreateModal}
                columns={columns}
                newColumns={columns}
                setNewColumns={setColumns}
                assigneesFilter={true}
                hasStatus={false}
            />
            <Table<Team>
                columns={columns}
                dataSource={teams}
                size={'middle'}
                pagination={{
                    size: 'default',
                    position: ['bottomCenter'],
                    current: currentPage,
                    pageSize: pageSize?.value,
                    total: teamsTotal,
                    pageSizeOptions: ['10', '25', '50'],
                }}
                sortDirections={['ascend', 'descend']}
                onChange={handleTableChange}
                loading={loading}
                rowKey={'Id'}
                className='custom-antd-header-bg'

            />
        </>
    );
};

export default TeamTable;
