import { ReactElement } from 'react';
import moment from 'moment';

interface DateFormatCellProps {
  date: Date | string; // Specify which property to use as a date
  time?: boolean;
}

const DateFormatCell = ({ date, time = false }: DateFormatCellProps): ReactElement => {
  const date3 = moment(date).toDate();
  return (
    <div >
      {time ? <div className='font-semibold'>{moment(date3).format('DD-MM-YYYY HH:mm')}</div> : <div>{moment(date3).format('DD-MM-YYYY')}</div>}

    </div>
  );
};

export default DateFormatCell;
