import { Input } from 'antd';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';

export function DebouncedInput({
  onChange,
  debounce = 500,
  initValue = '',
  placeholder = "Search...",
  pre = false
}: {
  value?: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  initValue?: string;
  placeholder?: string;
  pre?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initValue);
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | null>(null);

  const handleOnChange = (newValue: string) => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    const newTimeoutRef = setTimeout(() => {
      onChange(newValue);
    }, debounce);
    setTimeoutRef(newTimeoutRef);
  };

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return (
    <Input
      prefix={pre && <UserOutlined />}
      data-testid="searchTimesheet"
      placeholder={placeholder}
      allowClear
      value={value}
      onChange={(e) => {
        const newValue = e.target.value;
        setValue(newValue);
        handleOnChange(newValue);
      }}
    />
  );
}
