import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import React, {
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

type ToastOptions = {
  title: string;
  description: string;
  type: 'Light' | 'Danger' | 'Success' | 'Warning' | 'Info';
  duration?: number;
};

type NotificationsContextProps = {

  openNotification: (placement: NotificationPlacement, data: ToastOptions) => void;
};

const initNotificationsContextPropsState = {
  showNotification: () => { },
  hideNotification: () => { },
  setOptions: () => { },
  openNotification: () => { },
};

const initialToastOptions: ToastOptions = {
  title: '',
  description: '',
  type: 'Light',
};

const NotificationsContext = createContext<NotificationsContextProps>(
  initNotificationsContextPropsState,
);
NotificationsContext.displayName = 'Notifications';

const useNotifications = () => {
  return useContext(NotificationsContext);
};

type Props = {
  children: React.ReactNode;
}

const NotificationProvider = ({ children }: Props) => {

  const [api, contextHolder] = notification.useNotification();


  const openNotification = (placement: NotificationPlacement, data: ToastOptions,) => {
    api.info({
      message: data.title,
      description: data.description,
      placement,
      duration: data.duration,
      icon: data.type === "Success" ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
      style: {
        width: 300,

      },
    });
  };


  return (
    <NotificationsContext.Provider
      value={{
        openNotification,
      }}
    >
      {contextHolder}
      {children}
    </NotificationsContext.Provider>
  );
};
export { NotificationProvider, useNotifications };
