import React, { useEffect, useState } from 'react'
import { Timesheet, fetchTimesheet } from '../../api-requests/Timesheet';
import moment from 'moment';
import { CommonListResponse } from '../../@types/Response';
import ChartsWidget9 from '../../components/charts/ChartsWidget9';
import ChartsWidget11 from '../../components/charts/ChartsWidget11';



interface DashboardData {
    oneYearAgo: Timesheet[];
    oneMonthAgo: Timesheet[];
}
const WidgetWrapper = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DashboardData>({} as DashboardData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD');
                const oneMonthAgo = moment().subtract(1, 'month');
                const beginningOfPreviousMonth = oneMonthAgo
                    .startOf('month')
                    .format('YYYY-MM-DD');
                const endOfPreviousMonth = oneMonthAgo
                    .endOf('month')
                    .format('YYYY-MM-DD');

                const promises: Promise<CommonListResponse<Timesheet>>[] = [
                    fetchTimesheet({
                        from: beginningOfPreviousMonth,
                        to: endOfPreviousMonth,
                        status: '0,1,2',
                        pageSize: 500,
                    }),
                    fetchTimesheet({
                        from: oneYearAgo,
                        to: endOfPreviousMonth,
                        status: '0,1,2',
                        pageSize: 500,
                    }),
                ];

                const [monthData, yearData] = await Promise.all(promises);

                setData({
                    oneMonthAgo: monthData.Results,
                    oneYearAgo: yearData.Results,
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
                // Handle error appropriately, e.g., show an error message to the user
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <h4 className='text-title'>Recorded Timesheets</h4>
            <div className="grid grid-cols-12 gap-4">
                <div className="lg:col-span-6 md:col-span-12 sm:col-span-12 xs:col-span-12">
                    <ChartsWidget11
                        className=""
                        data={data.oneMonthAgo}
                        loading={loading}
                    />
                </div>
                <div className="lg:col-span-6 md:col-span-12 sm:col-span-12 xs:col-span-12">
                    <ChartsWidget9
                        className=""
                        data={data.oneYearAgo}
                        loading={loading}
                    />
                </div>
            </div>
        </>
    )
}

export default WidgetWrapper