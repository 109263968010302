import { Button, Select, Spin, Tooltip, Typography } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React, { useState } from 'react'
import { getFile, uploadFile } from '../../../../../api-requests/Upload';
import { Attachements, Meeting } from '../../../../../api-requests/Meeting';
import { useNotifications } from '../../../../../permissions/components/timesheet/Notification-service';
import { useAuth } from '../../../../auth';
import {
    InboxOutlined,
    EyeOutlined,
    DeleteOutlined,
    FileOutlined,
    SnippetsOutlined
} from '@ant-design/icons';
import FormCard from '../../../../../utils/Forms/FormCard';
import TextArea from 'antd/es/input/TextArea';
type Props = {
    values: Meeting;
    setFieldValue: any;
    fileList: any;
    setFileList: any;
}
const { Title } = Typography;
const AttachmentsMeeting = ({ values, setFieldValue, fileList, setFileList }: Props) => {
    const { id } = useAuth();
    const [loading, setLoading] = useState(false);
    const { openNotification } = useNotifications();
    const formatAttachments = (attachments: Attachements[]) => {
        return attachments.map((file) => ({
            uid: file.Id,
            name: file.FileName,
            FileName: file.FileName,
            Id: file.Id,
            status: 'done',
            url: `path_to_your_file_storage/${file.FileName}`, // Adjust the URL to point to your actual file storage location
            uploadedBy: file.UploadedBy,
            uploadDateTime: file.UploadedDateTime,
            Description: file.Description === '' ? 'Meeting Agenda' : file.Description,
        }));
    };

    const handlePreview = async (file: any) => {
        await getFile(file.uid).then((res) => {
            const fileBlob = new Blob([res.data]);
            const fileURL = URL.createObjectURL(fileBlob);

            // Infer file type from the extension
            const fileName = file.name.toLowerCase();
            const isPdf = fileName.endsWith('.pdf');
            const isImage =
                fileName.endsWith('.jpg') ||
                fileName.endsWith('.jpeg') ||
                fileName.endsWith('.png') ||
                fileName.endsWith('.gif');

            const previewWindow = window.open();
            if (previewWindow) {
                if (isPdf) {
                    const fileBlob = new Blob([res.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(fileBlob);
                    previewWindow.location.href = fileURL;
                } else if (isImage) {
                    previewWindow.document.write(
                        `<img src="${fileURL}" style="width: 100%; height: 100%;" />`,
                    );
                } else {
                    previewWindow.document.write(
                        `<p>Unsupported file type: ${fileName.split('.').pop()}</p>`,
                    );
                }
            }
        });
    };
    const handleFileUpload = async (event: any) => {
        setLoading(true);
        if (!event.file) {
            return;
        }
        try {
            const formdata = new FormData();
            event.file.Description = 'test';
            formdata.append('data', event.file);
            uploadFile(formdata).then((d) => {
                setFileList((prev: any) => [...formatAttachments([d]), ...prev]);
                event.onSuccess(d, event.file);
                setLoading(false);
            });
        } catch (error) {
            event.onError(error);
            setLoading(false);
        }
    };
    const beforeUpload = (file: any) => {
        const isLt2M =
            file.size / 1024 / 1024 < import.meta.env.VITE_APP_MAX_FILE_SIZE;
        if (!isLt2M) {
            openNotification('topRight', {
                title: `File`,
                description: `File size must be smaller than ${import.meta.env.VITE_APP_MAX_FILE_SIZE}MB.`,
                type: 'Danger',
            });
        }
        return isLt2M;
    };
    const truncateFileName = (name: string) => {
        const splitName = name.split('_')[1];
        return splitName.length > 20
            ? splitName.substring(0, 80) + '...'
            : splitName;
    };
    const handleDescriptionChange = (value: string, file: any) => {
        const updatedFileList = fileList.map((f: any) => {
            if (f.uid === file.uid) {
                return { ...f, Description: value };
            }
            return f;
        });
        setFileList(updatedFileList);
    };
    const handleDelete = (f: any) => {
        if (values && values.CreatedBy?.Id) {
            setFileList(fileList.filter((file: any) => file.name !== f.name));
        } else {
            setFileList(fileList.filter((file: any) => file.name !== f.name));
        }
    };
    const options = [
        { value: 0, label: 'Meeting Agenda' },
        { value: 1, label: 'Participant List' },
        { value: 2, label: 'Other' },
    ];

    const getLabelForValue = (value: number) => {
        const option = options.find((opt) => opt.value === value);
        return option ? option.label : '';
    };
    return (
        <div className="flex flex-col gap-2">
            <FormCard title="Other" icon={<SnippetsOutlined />}>
                <div className="gap-4 w-full h-full flex flex-col">
                    <div
                        data-testid="leaveTypeDropdown"
                        className="w-full gap-2 grid grid-cols-12"
                    >
                        <div className="col-span-12 flex flex-col gap-2">
                            <div>
                                <label className="form-label">
                                    Meeting Description
                                </label>
                            </div>
                            <TextArea
                                rows={2}
                                data-testid="desc"
                                size="large"
                                onChange={(e) =>
                                    setFieldValue('Description', e.target.value)
                                }
                                value={values.Description}
                                placeholder="Comments"
                                disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                                style={{ color: 'black' }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 col-span-12">
                        <div>
                            <label className="form-label">
                                Attachments
                            </label>
                        </div>
                        <div className="gap-4 w-full   grid grid-cols-12 justify-between max-h-[400px]">
                            <div className="md:col-span-12 col-span-12">
                                <Dragger
                                    className="upload-list-inline "
                                    customRequest={(info) => handleFileUpload(info)}
                                    multiple={true}
                                    fileList={[]}
                                    beforeUpload={beforeUpload}
                                    disabled={values.CreatedBy && values?.CreatedBy?.Id !== id}
                                    onPreview={async (f) => {
                                        await getFile(f.uid).then((res) => {
                                            const file = new Blob([res.data], {
                                                type: 'application/pdf',
                                            });
                                            //Build a URL from the file
                                            const fileURL = URL.createObjectURL(file);
                                            //Open the URL on new Window
                                            const pdfWindow = window.open();
                                            if (pdfWindow)
                                                pdfWindow.location.href = fileURL;
                                        });
                                    }}
                                    onRemove={(f) => {
                                        if (values.CreatedBy && values.CreatedBy?.Id) {
                                            setFileList(
                                                fileList.filter(
                                                    (file: any) => file.name !== f.name,
                                                ),
                                            );

                                        }
                                    }}
                                >
                                    <div className='flex justify-center items-center flex-row'>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to upload
                                        </p>
                                    </div>
                                </Dragger>
                            </div>
                            {fileList.length > 0 && <div className="md:col-span-12  col-span-12  overflow-y-scroll scrollbar-always-visible scroll-smooth max-h-[250px]">
                                <Spin spinning={loading}  >
                                    <div className="file-list col-span-6 flex flex-col gap-2">
                                        {fileList.slice().reverse().map((file: any) => (
                                            <div
                                                key={file.uid}
                                                className="file-list-item flex flex-row items-center justify-between bg-white p-4 rounded-lg border border-gray-200 shadow-md"
                                            >
                                                <div
                                                    key={file.uid}
                                                    className="file-list-item flex flex-row items-center justify-between"
                                                >
                                                    <span>
                                                        {file.name.split("_")[1]}
                                                    </span>
                                                    <div className="file-list-actions flex flex-row">
                                                        <Tooltip title="Preview">
                                                            <Button
                                                                type="link"
                                                                icon={<EyeOutlined />}
                                                                onClick={() =>
                                                                    handlePreview(file)
                                                                }
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <Button
                                                                disabled={
                                                                    values.CreatedBy && id !== values.CreatedBy?.Id
                                                                }
                                                                type="link"
                                                                icon={<DeleteOutlined />}
                                                                onClick={() => handleDelete(file)}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Select
                                                        disabled={
                                                            values.CreatedBy && id !== values.CreatedBy?.Id
                                                        }
                                                        optionFilterProp="children"
                                                        placeholder="Type..."
                                                        className="w-[150px]"
                                                        defaultValue={0}
                                                        value={
                                                            file.Description !== ''
                                                                ? file.Description
                                                                : {
                                                                    id: 0,
                                                                    value: 'Meeting Agenda',
                                                                }
                                                        }
                                                        onChange={(v) =>
                                                            handleDescriptionChange(
                                                                getLabelForValue(v),
                                                                file,
                                                            )
                                                        }
                                                        filterOption={false}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Spin>
                            </div>}
                        </div>
                    </div>
                </div>
            </FormCard>
        </div>
    )
}

export default AttachmentsMeeting