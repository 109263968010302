import { createContext } from 'react';
import { Meeting } from '../../../../api-requests/Meeting';

export interface IMeetingContext {
    meetings: Meeting[];
    getMeeting: any;
    meetingsTotal: number;
    loading: boolean;
    getMeetingCalendar?: any;
    getTrips: any;
    trips: Meeting[];
    tripsTotal: number;
    getMeetingById: any;
    getPersonalMeetings: any;
}

const MeetingContext = createContext<IMeetingContext | undefined>(
    undefined,
);

MeetingContext.displayName = 'Meeting';

export default MeetingContext;
