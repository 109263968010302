import axios from "axios";
import envConfig from "../../enviroment/enviroment";

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const SUPPORT_REQUEST = `${API_URL}/application/support-request`;

export const createRequest = async (data: { description: string, filename: string, fileBase64: string }) => {
    try {
        const response = await axios.post<''>(SUPPORT_REQUEST, data);
        return response.data;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
};