import {
    Button,
    DatePicker,
    Switch,
    Table,
    TableProps,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
    Attendees,
    Meeting,
    Travellers,
    Trip,
} from '../../../../api-requests/Meeting';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    FormOutlined,
} from '@ant-design/icons';
import './index.css';
import dayjs, { Dayjs } from 'dayjs';
import { useAuth } from '../../../auth';
import {
    Employee,
    EmployeeSimple,
    fetchEmployees,
    fetchRelatedPeople,
} from '../../../../api-requests/Employees';
import LoomSelect from '../../../../components/StyledComponents/LoomSelect';
import moment from 'moment';
import WarningModal from '../../../../components/modal/WarningModal';
import TextArea from 'antd/es/input/TextArea';
import { checkTripConditions } from '../../../../utils/justificationCheck';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';

type Props = {
    values: Trip;
    setFieldValue: any;
    data: Meeting | undefined;
    approving?: boolean;
};

const AttendeesTransfer = ({ values, setFieldValue, approving }: Props) => {
    const { id } = useAuth();
    const tableRef = useRef(null);
    const [displayEmps, setDisplayEmps] = useState<any[]>([]);
    const [emps, setEmps] = useState<Employee[]>([]);
    const [lastInvalidEmployee, setLastInvalidEmployee] = useState(null);
    const [departDate, setDepartDate] = useState(
        dayjs(values.LatestArrivalTime).utcOffset(Number(values.TimezoneOffset)),
    );
    const [returnDate, setReturnDate] = useState(
        dayjs(values.EarliestDepartureTime).add(1, 'day').hour(0),
    );
    const [searchTerm, setSearchTerm] = useState('');
    const [targetEmp, setTargetEmp] = useState<any>(null);
    const { openNotification } = useNotifications();
    const [validationResults, setValidationResults] = useState<boolean[]>([]);
    const calculateMinDate = (values: any, record: any): Dayjs | undefined => {
        const index = values.Travellers.findIndex(
            (v: any) => v.Employee.Person.Id === record.Employee.Person.Id,
        );

        // Your logic to calculate the minimum date, e.g.:
        if (index >= 0) {
            // Example logic to return today's date plus index days (you can adjust as needed)
            return dayjs(values.Travellers[index].DepartFromBase);
        }

        // If no index found, return undefined or some default date
        return undefined;
    };
    useEffect(() => {
        // Scroll to the last row after the table renders
        if (tableRef.current) {
            const tableBody = window.document.querySelector('.ant-table-body');
            if (tableBody) {
                // Delay the scroll slightly to ensure table is fully rendered
                setTimeout(() => {
                    tableBody.scrollTop = tableBody.scrollHeight; // Scroll to the bottom
                }, 0); // You can adjust the delay if necessary
            }
        }
    }, [values.Travellers.length]);
    const check = () => {
        let lastInvalid: any;
        const results = values.Travellers.map((traveller) => {
            if (values.CreatedBy.Id === id) {
                const isValid = runValidation(traveller);
                if (!isValid) {
                    lastInvalid = traveller; // Keep track of the last invalid traveller
                }
                return isValid;
            }
            return false
        });

        setValidationResults(results);
        if (lastInvalid)
            setLastInvalidEmployee(lastInvalid);

        if (lastInvalid) {
            // Open notification for the last invalid employee
            openNotification('topRight', {
                title: 'Justification',
                description: 'Justification is needed',
                type: 'Danger',
            });
        }
    }
    useEffect(() => {
        if (targetEmp === null) {
            check();
        }

    }, [values.Travellers]);
    const columns: TableProps<any>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'Employee',
            key: 'name',
            render: (cell) => cell.Person.FullName,
            align: 'center',
            width: 200,
        },
        {
            width: 250,
            title: 'Depart From Base',
            dataIndex: 'DepartFromBase',
            render: (cell, record) => (
                <DatePicker
                    style={{ color: 'black', width: '100%' }}
                    onChange={(date) => {
                        const newDate = dayjs(date);
                        setDepartDate(newDate); // Update state with new date
                        const index = values.Travellers.findIndex(
                            (v) => v.Employee.Person.Id === record.Employee.Person.Id,
                        );
                        setFieldValue(
                            `Travellers[${index}].DepartFromBase`,
                            newDate.format(),
                        );

                        // Ensure "Return To Base" is at least 2 hours after "Depart From Base"
                        const newReturnDate = newDate.add(2, 'hours');
                        if (newReturnDate.isAfter(returnDate)) {
                            setReturnDate(newReturnDate);
                            setFieldValue(
                                `Travellers[${index}].ReturnToBase`,
                                newReturnDate.format(),
                            );
                        }
                    }}
                    maxDate={dayjs(values.LatestArrivalTime).add(1, 'day')}
                    allowClear={false}
                    className="cursor-pointer"
                    showTime={{
                        format: 'HH:mm',
                        minuteStep: 30,
                        defaultValue: dayjs('00:00', 'HH:mm'),
                    }}
                    format={'DD MMM YYYY HH:mm'}
                    use12Hours={false}
                    disabled={
                        approving ||
                        (id !== values.CreatedBy?.Id && values.CreatedBy.Id !== 0)
                    }
                    value={
                        cell
                            ? values.TimezoneOffset
                                ? dayjs(cell).utcOffset(values.TimezoneOffset)
                                : dayjs(cell)
                            : values.Meeting.AllDay
                                ? dayjs(values.LatestArrivalTime).hour(9)
                                : dayjs(values.LatestArrivalTime)
                    }
                />
            ),
        },
        {
            width: 250,
            title: 'Return To Base',
            dataIndex: 'ReturnToBase',
            render: (cell, record) => (
                <DatePicker
                    allowClear={false}
                    use12Hours={false}
                    minDate={
                        departDate
                            ? departDate.add(2, 'hours')
                            : calculateMinDate(values, record)
                    } // Set minimum date
                    onChange={(date) => {
                        const newDate = dayjs(date);
                        setReturnDate(newDate);
                        const index = values.Travellers.findIndex(
                            (v) => v.Employee.Person.Id === record.Employee.Person.Id,
                        );
                        setFieldValue(
                            `Travellers[${index}].ReturnToBase`,
                            newDate.format(),
                        );
                    }}
                    disabled={
                        approving ||
                        (id !== values.CreatedBy?.Id && values.CreatedBy.Id !== 0)
                    }
                    style={{ color: 'black', width: '100%' }}
                    disabledTime={(current) => {
                        const currentFromDate = departDate
                            ? departDate.add(2, 'hours')
                            : calculateMinDate(values, record);
                        const selectedDate = dayjs(current);
                        const minDate = currentFromDate?.add(60, 'minute');

                        if (
                            selectedDate.isSame(currentFromDate, 'date') &&
                            currentFromDate
                        ) {
                            const disabledMinutes = (selectedHour: number) => {
                                if (selectedHour === currentFromDate?.hour()) {
                                    return [...Array(currentFromDate.minute() + 60).keys()];
                                }
                                if (
                                    minDate &&
                                    selectedHour === minDate.hour() &&
                                    currentFromDate.minute() > 30
                                ) {
                                    return [...Array(currentFromDate.minute() - 30).keys()];
                                }
                                return [];
                            };

                            return {
                                disabledHours: () => [
                                    ...Array(currentFromDate.hour() + 1).keys(),
                                    ...(currentFromDate.minute() >= 30
                                        ? [currentFromDate.hour()]
                                        : []),
                                ],
                                disabledMinutes,
                            };
                        } else if (selectedDate.isBefore(currentFromDate, 'date')) {
                            return {
                                disabledHours: () => [...Array(24).keys()],
                            };
                        }

                        return {};
                    }}
                    showTime={{
                        format: 'HH:mm',
                        minuteStep: 30,
                        defaultValue: dayjs('00:00', 'HH:mm'),
                    }}
                    format={'DD MMM YYYY HH:mm'}
                    className="cursor-pointer"
                    value={
                        cell
                            ? values.TimezoneOffset
                                ? dayjs(cell).utcOffset(values.TimezoneOffset)
                                : dayjs(cell)
                            : values.Meeting.AllDay
                                ? dayjs(values.EarliestDepartureTime).hour(18)
                                : dayjs(values.EarliestDepartureTime).utcOffset(
                                    values.TimezoneOffset,
                                    true,
                                )
                    }
                />
            ),
        },
        {
            title: 'Trip Extension',
            dataIndex: 'Extended',
            key: 'extended',
            render: (cell, record, index) => <Tooltip title="Are you staying in this location longer than the meeting duration?"> <Switch disabled={(approving || (values.CreatedBy.Id !== 0 && values.CreatedBy.Id !== id))} checkedChildren="Yes" unCheckedChildren="No" value={cell} onChange={(v) => { setFieldValue(`Travellers[${index}].Extended`, v); if (v && record.Justification === '') { setTargetEmp(record) } }} /></Tooltip>,
            align: 'center',
            width: 150,
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            align: 'center',
            width: 150,
            render: (cell, record, index) =>
                (id === values.CreatedBy?.Id || values.CreatedBy.Id === 0) && (
                    <div className='gap-2 flex justify-center'>
                        {validationResults[index] ? (
                            <Tooltip title="Justification">
                                <Button className="" onClick={() => setTargetEmp(cell)}>
                                    <FormOutlined />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Justification">
                                <Button
                                    className=""
                                    style={{ color: 'orange', borderColor: 'orange' }}
                                    onClick={() => setTargetEmp(cell)}
                                >
                                    <FormOutlined />
                                </Button>
                            </Tooltip>
                        )}
                        <Button
                            danger
                            className="border-danger hover:border-dangerHover"
                            onClick={() => handleRemoveTraveller(cell)}
                        >
                            <DeleteOutlined className="text-danger hover:text-dangerHover" />
                        </Button>
                    </div>
                ),
        },
    ];

    const formatInitialData = async () => {
        if (values.Beneficiary) {
            const fetchedData = await fetchEmployees({
                includeInactive: false,
                searchTerm: searchTerm,
                orgId: values.Beneficiary.Organisation.Id,
                projectId: values.Project.Id,
                fetchAllEmployees: true,
            });
            if (searchTerm.length === 0) {
                setDisplayEmps(
                    fetchedData.Results.map((emp) => {
                        return {
                            Id: emp.EmployeeId,
                            DepartFromBase: values.Meeting.AllDay
                                ? dayjs(new Date(values.LatestArrivalTime))
                                    .hour(9)
                                    .toISOString()
                                : values.LatestArrivalTime,
                            ReturnToBase: values.Meeting.AllDay
                                ? moment(new Date(values.EarliestDepartureTime))
                                    .hour(9)
                                    .utcOffset(Number(values.TimezoneOffset))
                                    .toISOString()
                                : values.EarliestDepartureTime,
                            Employee: {
                                Id: emp.EmployeeId,
                                FullName: emp.FullName,
                                Person: {
                                    Id: emp.EmployeeId,
                                    FullName: emp.FullName,
                                },
                            },
                            Justification: '',
                        };
                    }),
                );
                setEmps(fetchedData.Results);
            } else {
                setDisplayEmps(
                    fetchedData.Results.map((emp) => {
                        return {
                            Id: emp.EmployeeId,
                            DepartFromBase: values.Meeting.AllDay
                                ? dayjs(new Date(values.LatestArrivalTime))
                                    .hour(9)
                                    .toISOString()
                                : values.LatestArrivalTime,
                            ReturnToBase: values.Meeting.AllDay
                                ? moment(new Date(values.EarliestDepartureTime))
                                    .hour(9)
                                    .utcOffset(Number(values.TimezoneOffset))
                                    .toISOString()
                                : values.EarliestDepartureTime,
                            Employee: {
                                Id: emp.EmployeeId,
                                FullName: emp.FullName,
                                Person: {
                                    Id: emp.EmployeeId,
                                    FullName: emp.FullName,
                                },
                            },
                            Justification: '',
                        };
                    }),
                );
            }
        }
    };
    useEffect(() => {
        formatInitialData();
    }, [values.Beneficiary]);

    const handleRemoveTraveller = (cell: Travellers) => {
        setFieldValue(
            'Travellers',
            values.Travellers.filter((f) => f.Id !== cell.Id),
        );
    };

    useEffect(() => {
        if (values.Meeting.Attendees) {
            values.Meeting.Attendees.map((em) => {
                return {
                    Id: em.Attendee.Id,
                    Tag: 'Attendee',
                    Person: em.Attendee,
                };
            });
            const filteredEmps = emps;
            const combined = [
                ...values.Meeting.Attendees.filter((attendee) =>
                    filteredEmps.some((emp) => emp.EmployeeId === attendee.Attendee.Id),
                ).map((attendee) => {
                    return {
                        Id: attendee.Attendee.Id,
                        Tag: 'Attendee',
                        Employee: {
                            Id: attendee.Attendee.Id,
                            Person: {
                                FullName: attendee.Attendee.Person.FullName,
                            },
                        },
                    };
                }),
                // Filter out employees from filteredEmps who are in values.Meeting.Attendees
                ...filteredEmps
                    .filter(
                        (emp) =>
                            !values.Meeting.Attendees.some(
                                (attendee) => attendee.Attendee.Id === emp.EmployeeId,
                            ),
                    )
                    .map((emp) => {
                        return {
                            Id: emp.EmployeeId,
                            Tag: '',
                            Employee: {
                                Id: emp.EmployeeId,
                                Person: {
                                    FullName: emp.FullName,
                                },
                            },
                        };
                    }),
            ];
            setDisplayEmps(
                combined.filter(
                    (c) => !values.Travellers.some((t) => t.Employee.Id === c.Id),
                ),
            );
        }
    }, [values.Meeting, emps, values.Travellers]);
    const handleChange = (v: number) => {
        const filteredData = displayEmps.filter((obj) => v === obj.Id);
        const modified = filteredData?.map((sel) => ({
            Id: sel.Id,
            DepartFromBase: values.Meeting.AllDay
                ? dayjs(new Date(values.LatestArrivalTime))
                    .hour(9)
                    .utcOffset(Number(values.TimezoneOffset), true)
                    .toISOString()
                : values.LatestArrivalTime,
            ReturnToBase: values.Meeting.AllDay
                ? moment(new Date(values.EarliestDepartureTime))
                    .hour(18)
                    .utcOffset(Number(values.TimezoneOffset), true)
                    .toISOString()
                : values.EarliestDepartureTime,
            Employee: {
                Id: sel.Id,
                Person: {
                    Id: sel.Id,
                    FullName: sel.Employee.Person.FullName,
                },
            },
        }));
        setFieldValue('Travellers', [...values.Travellers, ...modified]);
        setSearchTerm('');
    };
    const handleSearchChange = (newTerm: string) => {
        setSearchTerm(newTerm);
    };
    useEffect(() => {
        formatInitialData();
    }, [searchTerm]);
    const runValidation = (cell: any) => {
        if (!checkTripConditions(values, cell)) {
            return false;
        }
        return true;
    };
    return (
        <div className="flex w-full flex-col gap-5">
            <WarningModal
                title="Justification"
                isOpen={targetEmp !== null}
                setIsOpen={() => {
                    setTargetEmp(null);
                }}
                footerButtons={[
                    <Button key={0} onClick={() => {
                        setTargetEmp(null);
                    }}>Close</Button>,
                    <Button key={1} onClick={() => { setTargetEmp(null); check(); }}>Add</Button>
                ]}
                mainText={<div className='flex gap-2 flex-col'>
                    <span>For the purposes of the trip approval, please explain why {targetEmp?.Employee.Person.FullName} is travelling on these dates. </span>
                    <TextArea
                        rows={2}
                        data-testid="desc"
                        size="middle"
                        onChange={(e) => {
                            setFieldValue(`Travellers[${values.Travellers.findIndex(
                                (v) => v.Employee.Person.Id === targetEmp.Employee.Person.Id,
                            )}].Justification`, e.target.value)
                        }
                        }
                        value={values.Travellers.filter((tra) => tra.Id === targetEmp?.Id)[0]?.Justification ? values.Travellers.filter((tra) => tra.Id === targetEmp.Id)[0].Justification : ''}
                        placeholder={`Explain the purpose of this trip for ${targetEmp?.Employee.Person.FullName}.`}
                        style={{ color: 'black' }}
                    />
                </div>}
            />
            <LoomSelect<any>
                clearable={true}
                name="Travellers"
                label="Travellers"
                placeholder="Search to add"
                disabled={
                    approving || (values.CreatedBy.Id !== 0 && values.CreatedBy.Id !== id)
                }
                onSearch={handleSearchChange}
                onChange={handleChange}
                valueFunc={searchTerm ? searchTerm : null}
                customOptionRender={(prop) => {
                    return (
                        <div className="flex justify-between w-full">
                            <div>{prop.data.label}</div>
                            {prop.data.tag && prop.data.tag.length > 0 && (
                                <div>
                                    <Tag color="blue"> {prop.data.tag}</Tag>
                                </div>
                            )}
                        </div>
                    );
                }}
                options={displayEmps
                    .map((emp) => {
                        return {
                            value: emp.Id,
                            label: emp.Employee.Person.FullName,
                            tag: emp.Tag,
                        };
                    })
                    .filter(
                        (emp) =>
                            !values.Travellers.some((att) => att.Employee.Id === emp.value),
                    )}
            />

            {values.Travellers.length > 0 && (
                <Table
                    columns={columns}
                    dataSource={values.Travellers}
                    rowKey={(record) => record.Id}
                    pagination={false}
                    scroll={{ y: 200, x: 700 }}

                    className="custom-antd-header-bg"
                    ref={tableRef} // Assign ref to the table
                />
            )}
        </div>
    );
};

export default AttendeesTransfer;
