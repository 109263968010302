import React, { useEffect, useState } from 'react';
import { getWorkableDays } from '../../../api-requests/Leave';
import { useLeaves } from '../provider/useLeaves';
import moment from 'moment';
import { LeaveRequestPost } from '../../../@types/Request';
import CircularProgressWithIcon from '../../../components/progress/CircularProgress';

type Props = {
  values: LeaveRequestPost;
  setFieldValue: any;
  workableDays: number;
  setWorkableDays: (d: number) => void;
};

const LeaveFormStats = ({ values, setFieldValue, workableDays, setWorkableDays }: Props) => {
  const { balance } = useLeaves();

  useEffect(() => {
    const func2 = async () => {
      if (values.Start && values.End) {
        if (values.LeaveType) {

          await getWorkableDays(
            moment(values.Start).format('YYYY-MM-DDTHH:mm:ss'),
            moment(values.End).format('YYYY-MM-DDTHH:mm:ss'),
            values.LeaveType.Policy.Country.ISOCode2,
          ).then((res) => setWorkableDays(res));
        }

        else {
          if (balance)
            await getWorkableDays(
              moment(values.Start).format('YYYY-MM-DDTHH:mm:ss'),
              moment(values.End).format('YYYY-MM-DDTHH:mm:ss'),
              balance[0].Policy.Country.ISOCode2,
            ).then((res) => setWorkableDays(res));
        }
      }
    };
    func2();
  }, [values.Start, values.End]);
  useEffect(() => {
    if (balance)
      setFieldValue('LeaveType', balance[0])
  }, [balance]);
  if (values.LeaveType)
    return (
      <div
        className="flex md:flex-row flex-col"
        style={{ alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center' }}
      >
        <div>
          <div>
            <CircularProgressWithIcon
              progress={
                balance.filter(
                  (bl) =>
                    bl.Policy.TypeOfLeave.Id ===
                    values.LeaveType?.Policy.TypeOfLeave.Id,
                )[0]?.RemainingDays
              }
              divider={values.LeaveType?.Policy.MaxAllowableDays}
            />
          </div>
          <span>Available days</span>
        </div>
        <div
          className="flex flex-col justify-center items-center"
          style={{ fontSize: 32 }}
        >
          -
        </div>
        <div>
          <div>
            <CircularProgressWithIcon
              progress={workableDays}
              divider={values.LeaveType?.Policy.MaxAllowableDays}
            />
          </div>
          <span>Working days</span>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ fontSize: 32 }}
        >
          =
        </div>
        <div>
          <div>
            <CircularProgressWithIcon
              progress={
                balance.filter(
                  (bl) =>
                    bl?.Policy.TypeOfLeave.Id ===
                    values.LeaveType?.Policy.TypeOfLeave.Id,
                )[0]?.RemainingDays - workableDays
              }
              divider={values.LeaveType.Policy.MaxAllowableDays}
            />
          </div>
          <span>New balance</span>
        </div>
      </div>
    );
};

export default LeaveFormStats;
