import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { CommonListResponse } from '../@types/Response';
import { LeaveOptions } from '../@types/FetchOptions';
import { EmployeeSimple } from './Employees';
import dayjs from 'dayjs';
import { Beneficiary } from './Projects';
import { GET_APPROVAL_LEAVES } from './Leave';


export interface Attendees {
    Id: number,
    Tag?: string,
    PhysicalAttendance: boolean,
    Attendee: {
        Person: {
            FullName: string
        },
        Organisation: {
            Id: number,
            ShortName: string,
        }
        Id: number,
        FirstName: string,
        LastName: string,
        FullName: string
    }
    IsTraveller: boolean;
}
export interface Travellers {
    Id: number,
    PhysicalAttendance: boolean,
    DepartFromBase: Date;
    ReturnToBase: Date;
    Justification?: string;
    Employee: {
        Id: number,
        Person: {
            Id?: number,
            FullName: string,
        }
    }
    Extended?: boolean;
    Person: {
        Id: number,
        FirstName: string,
        LastName: string,
        FullName: string
    };
    Attendee: {
        Id: number,
        FirstName: string,
        LastName: string,
        FullName: string
    }
}
export interface Attachements {
    Id: string;
    FileName: string;
    UploadedBy: string;
    UploadedDateTime: string;
    Description: string;
}


export interface Trip {

    Id: number;
    Purpose: string;
    Title: string;
    LatestArrivalTime: Date | string;
    EarliestDepartureTime: Date | string;
    Status: number;
    CreatedOn: string;
    Project: {
        Id: number;
        Acronym: string;
    };
    Travellers: Array<Travellers>;
    Meeting: Meeting;
    Destination: {
        Id: number;
        Name: string;
        Address: string;
        City: string;
        Latitude: number;
        Longitude: number;  // Corrected the spelling from "Longitute"
        GooglePlusCode: string;
        GoogleMapsLink: string;
        Country: {
            Name: string,
            Id: number
        } | null;
    };
    Beneficiary: Beneficiary;
    CreatedBy: {
        Id: number;
        FirstName: string;
        LastName: string;
        FullName: string;
        CallName: string;
    };
    TimezoneOffset: number;
}
export interface Meeting {
    TimezoneOffset: number;
    Id?: number | undefined;
    From?: Date | string;
    To?: Date | string;
    Name?: string;
    Description?: string;
    Type: number;
    IsVirtual: boolean;
    VirtualMeetingLink: string;
    CreatedOn?: string;
    Status: string;
    AllDay: boolean;
    MeetingMode?: number;
    UTC?: number | string | null;
    Location?: {
        Id?: number,
        Name: string,
        Address: string,
        City: string,
        Latitude: number,
        Longitude: number,
        GooglePlusCode: string,
        GoogleMapsLink: string,
        Country?: {
            Id?: number,
            Name: string,
            IsoCode2: string,
        }
    },
    CreatedBy?: {
        Id: number,
        FirstName: string,
        LastName: string,
        FullName: string,
    }
    Project: {
        Id?: number,
        Acronym: string,
    },
    Attendees: Attendees[] | [],
    Attachments: Attachements[];
    Trips: Trip[];
}

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const CREATE_MEETING = `${API_URL}/meetings`;
export const COUNT_MEETING = `${API_URL}/meetings/count`;
export const GET_MEETING_LIST = `${API_URL}/meetings/list`;
export const CREATE_TRIP_FROM_MEETING = `${API_URL}/trips/create-from-meeting`
export const GET_TRIP_LIST = `${API_URL}/trips/list`
export const GET_TRIP = `${API_URL}/trips`
export const ADD_TRAVELER_TO_TRIP = `${API_URL}/trips/add-traveller`
export const REMOVE_TRAVELER_FROM_TRIP = `${API_URL}/trips/remove-traveller`
export const APPROVE_TRIP = `${API_URL}/trips/approve`
export const REJECT_TRIP = `${API_URL}/trips/reject`
export const GET_TRIP_APPROVALS = `${API_URL}/trips/my-approvals`
export const createMeeting = async (data: Meeting) => {
    try {
        const response = await axios.post<''>(CREATE_MEETING, data);
        return response.data;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
};
export const fetchTrips = async ({
    pageIndex = 0,
    pageSize = 10,
    searchTerm = '',
    status = '',
    from = '',
    to = '',
    sorting = 'From:desc',
    personIds = '',
}) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        sorting,
        status,
        from,
        to,
        personIds,
    });
    const response = await axios.get<CommonListResponse<Meeting>>(
        `${GET_TRIP_LIST}?${params.toString()}`,
    );
    return response.data;
}
export const fetchMeetings = async (
    {
        pageIndex = 0,
        pageSize = 10,
        searchTerm = '',
        status = '',
        from = '',
        to = '',
        sorting = 'From:desc',
        personIds = '',
    }
) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        sorting,
        status,
        from,
        to,
        personIds,
    });
    const response = await axios.get<CommonListResponse<Meeting>>(
        `${GET_MEETING_LIST}?${params.toString()}`,
    );
    return response.data;
};

export const fetchMeetingById = async (id: number) => {
    try {
        const response = await axios.get<Meeting>(`${CREATE_MEETING}?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching timesheet by id:', error);
        throw error;
    }
};

export const deleteMeetingById = async (id: number) => {
    try {
        const response = await axios.delete(`${CREATE_MEETING}?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("Error deleting meeting", error);
        throw error;
    }
}

export const createTripFromMeeting = async (data: { meetingId: number, orgId: number }) => {
    try {
        const response = await axios.post(`${CREATE_TRIP_FROM_MEETING}`, data);
        return response.data;
    } catch (error) {
        console.error("Error creating trip from meeting", error);
        throw error;
    }
}

export const getTripList = async ({
    pageIndex = 0,
    pageSize = 100,
    sorting = 'LatestArrivalTime:desc',
    searchTerm = '',
    status = '',
    from = '',
    to = '',
    personIds = '',
    advancedFilter = ''
}: LeaveOptions = {}) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        sorting,
        status,
        from,
        to,
        personIds,
        advancedFilter
    });
    try {
        const response = await axios.get<any>(
            `${GET_TRIP_LIST}?${params.toString()}`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching trips', error);
        throw error;
    }
}

export const fetchTripById = async (id: number) => {
    try {

        const response = await axios.get<Trip>(`${GET_TRIP}?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
};

export const deleteTripById = async (id: number) => {
    try {
        const response = await axios.post<Trip>(`${GET_TRIP}/cancel`, { tripId: id });
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
}

export const saveTrip = async (data: Trip) => {
    try {
        const response = await axios.post<''>(`${GET_TRIP}`, data);
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
}
export const submitTrip = async (id: number) => {
    try {
        const response = await axios.post<''>(`${GET_TRIP}/submit`, id);
        return response.data;
    } catch (error) {
        console.error('Error fetching trip by id:', error);
        throw error;
    }
}
export const approveTrip = async (data: { id: number, comments: string }) => {
    try {
        const response = await axios.post<''>(APPROVE_TRIP, data);
        return response.data
    } catch (error) {
        console.error('Error approving trip:', error)
        throw (error);
    }
}
export const rejectTrip = async (data: { id: number, comments: string }) => {
    try {
        const response = await axios.post<''>(REJECT_TRIP, data);
        return response.data
    } catch (error) {
        console.error('Error rejecting trip:', error);
        throw error;
    }
}

export const fetchApprovalTrips = async ({
    pageIndex = 0,
    pageSize = 100,
    searchTerm = '',
    status = '',
    from = '',
    to = '',
    personIds = '',
}: LeaveOptions = {}) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,

        status,
        from,
        to,
        personIds,
    });
    try {
        const response = await axios.get<any>(
            `${GET_TRIP_APPROVALS}?${params}`,
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const addTraveler = async (data: { employeeId: number, tripId: number }) => {
    try {
        const response = await axios.post<''>(`${ADD_TRAVELER_TO_TRIP}`, data);
        return response.data;
    } catch (error) {
        console.error('Error adding traveler to trip', error)
        throw error;
    }
}


export const removeTraveler = async (data: { employeeId: number | undefined, tripId: number | undefined }) => {
    try {
        const response = await axios.post<''>(`${REMOVE_TRAVELER_FROM_TRIP}`, data);
        return response.data;
    } catch (error) {
        console.error('Error adding traveler to trip', error)
        throw error;
    }
}