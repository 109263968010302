import moment from 'moment';

export const dateTransformer = (date: unknown) => {
  if (date === undefined) {
    return '';
  }
  if (typeof date === 'string') {
    return moment(date).format('DD-MM-YYYY');
  }
  throw new Error('Invalid parameter type');
};

export const monthTransformer = (date: unknown): string => {
  if (typeof date === 'string' || date instanceof Date) {
    return moment(date).format('MMMM YYYY');
  }
  throw new Error('Invalid parameter type');
};

export const dateTimeTransformer = (date: unknown) => {
  if (date === undefined) {
    return '';
  }
  if (typeof date === 'string') {
    return moment(date).format('DD-MM-YYYY HH:mm');
  }
  throw new Error('Invalid parameter type');
};
