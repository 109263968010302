import { useEffect, useRef, useState } from 'react';
import { Modal, Button, Typography, Spin } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../auth';
import { fetchTripById, Trip } from '../../../api-requests/Meeting';
import TripForm from './components/TripForm';
import { EmployeeSimple } from '../../../api-requests/Employees';
import { initTrip } from '../../../utils/FormValidation/CreateAccountWizardHelper';
import Header from '../../../pages/layout/Header';
import { useMeetings } from '../meeting/provider/useMeetings';
import { Beneficiary, fetchBeneficiaries } from '../../../api-requests/Projects';
import { useTrip } from './provider/useTrip';
import { useNotifications } from '../../../permissions/components/timesheet/Notification-service';

const { Title } = Typography;
dayjs.extend(utc);
dayjs.extend(timezone);

type CreateMeetingProps = {
    data?: Trip;
};

const TripFormWrapper = ({ data }: CreateMeetingProps) => {
    const navigate = useNavigate();
    const p = useParams();
    const { id } = useAuth();
    const [init, setInit] = useState(initTrip);
    const [loading, setLoading] = useState(true);
    const [approve, setApprove] = useState(false);
    const [approveId, setApproveId] = useState(0);
    const [redirecting, setRedirecting] = useState(false);
    const location = useLocation();
    const { getMeeting, getMeetingById } = useMeetings();
    const initialTitle = useRef('Create Trip');
    const { getBeneficiaries, beneficiaries } = useTrip();
    const { openNotification } = useNotifications();
    useEffect(() => {
        const state = location.state as { data: Trip, approving: boolean, id: number, redirect: boolean, edit: boolean, view: boolean };
        if (state && state.data) {
            setInit(state.data);
            setApprove(state.approving);
            setRedirecting(state.view)
            setApproveId(state.id)
        }
    }, [location.state]);
    const updateTravellers = () => {
        if (init.Meeting && init.Meeting.AllDay) {
            init.EarliestDepartureTime = dayjs(new Date(init.EarliestDepartureTime)).utcOffset(init.TimezoneOffset, true).hour(18).toISOString()
            init.LatestArrivalTime = dayjs(new Date(init.LatestArrivalTime)).hour(9).utcOffset(init.TimezoneOffset, true).toISOString()

        }
        if (init.Travellers)
            init.Travellers.forEach((tra, i) => {
                // Determine the values to be set
                const departFromBase = tra.DepartFromBase || (init.Meeting.AllDay
                    ? dayjs(new Date(init.LatestArrivalTime)).hour(9).utcOffset(init.TimezoneOffset, true).toISOString()
                    : dayjs(new Date(init.LatestArrivalTime)).utcOffset(init.TimezoneOffset).toISOString());

                const returnToBase = tra.ReturnToBase || (init.Meeting.AllDay
                    ? dayjs(new Date(init.EarliestDepartureTime)).utcOffset(init.TimezoneOffset, true).hour(18).toISOString()
                    : dayjs(new Date(init.EarliestDepartureTime)).utcOffset(init.TimezoneOffset).toISOString());

                if (tra.DepartFromBase !== departFromBase) {
                    tra.DepartFromBase = departFromBase;
                }
                if (tra.ReturnToBase !== returnToBase) {
                    if (returnToBase)
                        tra.ReturnToBase = returnToBase;
                }
                if (!tra.Justification) {
                    tra.Justification = '';
                }
            });
    };

    useEffect(() => {
        const initializeData = async () => {
            let initialData = initTrip;
            if (data) {
                initialData = data;
                getBeneficiaries(data.Project.Id);
                initialData.Beneficiary = beneficiaries[0];
            } else if (p.id) {
                await fetchTripById(Number(p.id)).then(async (t) => {
                    initialData = t;
                }).catch((error) => {
                    openNotification('topRight', {
                        title: `Team`,
                        description: error?.response?.data,
                        type: 'Danger',
                    });
                    navigate(-1);
                });
            }
            setInit(initialData);
            if (initialData.Id !== 0) {
                initialTitle.current = id === initialData.CreatedBy?.Id ? 'Update Trip' : 'View Trip';
            }
            if (initialData.Meeting.Id !== 0) {
                const meeting = await getMeetingById(initialData.Meeting.Id)
                initialData.Meeting = meeting;
            }
            setLoading(false);
        };
        initializeData();
        getMeeting();
    }, [data, p.id, id]);
    useEffect(() => {
        updateTravellers();
    }, [init])
    if (loading) {
        return <div className='w-full h-full flex justify-center items-center'><Spin spinning={loading} /></div>
    }
    return (
        <>
            <Header title={initialTitle.current}>
                <TripForm data={init} approving={approve} approveId={approveId} redirecting={redirecting} edit={redirecting} />
            </Header>
        </>
    );
};

export default TripFormWrapper;
