import { FC } from 'react';
import { Navigate, Route, Routes, Outlet, useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import MeetingTableWrapper from '../features/project/meeting/MeetingTableWrapper';
import PageWrapper from '../components/wrapper/PageWrapper';
import CreateMeetingWrapper from '../features/project/meeting/components/CreateMeetingWrapper';
import TripFormWrapper from '../features/project/Trip/TripFormWrapper';
import TripTableWrapper from '../features/project/Trip/TripTableWrapper';
import Header from '../pages/layout/Header';
import TripTableApprovalWrapper from '../features/project/Trip/TripTableApprovalWrapper';
import UpcommingMeetingTableWrapper from '../features/project/meeting/UpcommingMeetingTableWrapper';

const { Title } = Typography;
// const leaveBreadcrumbs: Array<PageLink> = [
//   {
//     title: 'Leave list',
//     path: -1,
//     isSeparator: false,
//     isActive: true,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ];

const ProjectRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="meetings/"
          element={
            <>
              <Header title="Meetings">
                <MeetingTableWrapper />
              </Header>
            </>
          }
        />
        <Route
          path="meetings/upcoming/"
          element={
            <>
              <Header title="Upcoming Meetings">
                <UpcommingMeetingTableWrapper />
              </Header>
            </>
          }
        />
        <Route
          path="meetings/create"
          element={
            <>
              <CreateMeetingWrapper key={1} />
            </>
          }
        />
        <Route
          path="meetings/:id"
          element={
            <>
              <CreateMeetingWrapper key={0} />
            </>
          }
        />
        <Route
          path="meetings/trip/create"

          element={
            <>
              <TripFormWrapper key={2} />
            </>
          }
        />
        <Route
          key={1}
          path="/meetings/trip/:id"
          element={
            <TripFormWrapper key={1} />
          }
        />
        <Route
          key={1}
          path="/meetings/trip/approval/:id"
          element={
            <TripFormWrapper key={1} />
          }
        />
        <Route
          path="meetings/trip/list"
          element={
            <>
              <Header title="Trips">
                <PageWrapper>
                  <TripTableWrapper />
                </PageWrapper>
              </Header>
            </>
          }
        />
        <Route
          path="meetings/trip/approval"
          element={
            <>
              <Header title="Trip Approvals">
                <PageWrapper>
                  <TripTableApprovalWrapper />
                </PageWrapper>
              </Header>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/apps/user-management/users" />} />
    </Routes>
  );
};

export default ProjectRoutes;
