import { Dispatch, SetStateAction, createContext } from 'react';
import { LeaveData, LeaveRequest } from '../../../mock/Leave';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { BalanceData } from '../../../@types/Balance';
import { Employee } from '../../../api-requests/Employees';
import { CommonListResponse } from '../../../@types/Response';

export interface ILeaveContext {
  approvalLeaves: LeaveRequest[];
  calendarLeaves: LeaveData[];
  setCalendarLeaves: (val: LeaveData[]) => void;
  fetchAllLeaves: (
    params: LeaveOptions,
  ) => Promise<CommonListResponse<LeaveData>>;
  fetchApprovals: (params: LeaveOptions) => void;
  loading: boolean;
  leavesTotal: number;
  setApprovalLeaves: (d: LeaveRequest[]) => void;
  getSingleLeave: (id: number) => Promise<LeaveData> | any;
  getBalance: (year: number) => void;
  balance: BalanceData[];
  myLeaves: LeaveData[];
  approveLeave: (id: number, comments: string) => Promise<void>;
  rejectLeave: (id: number, comments: string) => Promise<void>;
  filterPeople: Employee[];
  setMyLeaves: Dispatch<SetStateAction<LeaveData[]>>;
  setFilterPeople: Dispatch<SetStateAction<Employee[]>>;
  loadingFetch: boolean;
  fetchPersonalLeaves: (params: LeaveOptions) => void;
  withdrawSingleLeave: (id: number) => void;
  withdrawApprovalLeave: (id: number) => void;
  leaveHistory?: LeaveData[];
  setLeaveHistory?: Dispatch<SetStateAction<LeaveData[]>>;
  fetchHistoryLeaves?: (params: LeaveOptions) => void;
}

const LeaveContext = createContext<ILeaveContext | undefined>(undefined);

LeaveContext.displayName = 'Leave';

export default LeaveContext;
