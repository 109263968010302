import { theme, Grid } from 'antd';
import { Content } from 'antd/es/layout/layout'
import React from 'react'


const { useBreakpoint } = Grid;
type Props = {
    children: JSX.Element | JSX.Element[];
    padding?: number;
    bg?: string;
    p?: number
}

const PageWrapper = ({ children, padding = 30, bg, p }: Props) => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { xs } = useBreakpoint();
    return (
        <div style={{ padding: xs ? 0 : 5 }}>
            <div
                style={{
                    background: bg ? bg : '',
                    minHeight: 280,
                    padding: padding,
                    borderRadius: borderRadiusLG,
                    position: 'relative',
                }}
                className={`bg-white shadow-md md:p-[${padding}px] py-[30px]`}
            >
                {children}
            </div>
        </div>

    )
}

export default PageWrapper