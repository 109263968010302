import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

type CircularProgressProps = {
  progress: number;
  divider?: number;
  size?: number;
};
const CircularProgressWithIcon = ({
  progress,
  divider,
  size,
}: CircularProgressProps) => {
  const options: ApexOptions = {
    // chart: {
    //   events: {
    //     mounted: (chart) => {
    //       chart.windowResizeHandler();
    //     },
    //   },
    // },
    labels: [`${progress} Days`],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
        },
        dataLabels: {
          name: {
            show: true,
            offsetY: 5,
          },
          value: {
            fontSize: '22px',
            show: false,
          },
        },
      },
    },
  };
  return (
    <>
      <div
        id="chart"
        className="justify-center items-center flex"
        style={{ padding: 2 }}
      >
        <ReactApexChart
          options={options}
          width={size ? size : 200}
          series={[
            divider
              ? Number(((progress / divider) * 100).toFixed(0))
              : progress,
          ]}
          type="radialBar"
        />
      </div>
    </>
  );
};

export default CircularProgressWithIcon;
