import React, { useMemo } from 'react';
import TeamContext from './TeamContext';

export const useTeam = () => {
    const context = React.useContext(TeamContext);

    if (context === undefined) {
        throw new Error('useTeam must be used within a TeamProvider');
    }
    // Memoize the context value to prevent unnecessary re-renders
    const memoizedContext = useMemo(() => context, [context]);
    return memoizedContext;
};
