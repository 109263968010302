export interface SortObject {
  key: string;
  value: 'asc' | 'desc' | '' | string;
}

export const parseSorting = (val: string): SortObject[] => {
  const pairs = val.split(';'); // Split the string by comma to separate individual key-value pairs
  // If there are multiple pairs, return an array of SortObjects

  if (pairs[0] !== '') {
    const tmp = pairs.map((pair) => {
      const [key, value] = pair.split(':').map((str) => str.trim()) as [
        string,
        'asc' | 'desc',
      ];
      return { key, value };
    });

    return tmp;
  } else {
    return [{ key: '', value: '' }];
  }
};

export const stringifySorting = (sortingObjects: SortObject[]): string => {
  if (sortingObjects.length > 1)

    return (
      sortingObjects.map((obj) => `${obj.key}:${obj.value}`).join(';')

    );
  else { const sort = sortingObjects.map((obj) => `${obj.key}:${obj.value}`).join(';'); if (sort !== undefined) { return sort } else { return '' } }
};
