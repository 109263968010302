import { ErrorMessage } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    Attendees,
    Meeting,
    addTraveler,
    removeTraveler,
} from '../../../../../api-requests/Meeting';
import { UserAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Select, Table, TableProps, Typography } from 'antd';
import { useAuth } from '../../../../auth';
import {
    Employee,
    EmployeeSimple,
    fetchEmployees,
    fetchRelatedPeople,
} from '../../../../../api-requests/Employees';
import WarningModal from '../../../../../components/modal/WarningModal';
import { LeaveColors } from '../../../../../../shared/colors';
import FormCard from '../../../../../utils/Forms/FormCard';
import LoomSelect from '../../../../../components/StyledComponents/LoomSelect';

const { Title } = Typography;
type Props = {
    values: Meeting;
    setFieldValue?: any;
};

const AttendeesMeeting = ({ values, setFieldValue }: Props) => {
    const { id } = useAuth();
    const tableRef = useRef(null);
    const [displayEmps, setDisplayEmps] = useState<Employee[]>([]);
    const [emps, setEmps] = useState<Employee[]>([]);
    const [targetEmp, setTargetEmp] = useState<Attendees | null>(null);
    const [targetAddEmp, setTargetAddEmp] = useState<number | null>(null);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const formatInitialData = async () => {
        const fetchedData = await fetchEmployees({
            includeInactive: false,
            searchTerm: searchTerm, // Ensure this matches your state
            projectId: values.Project.Id,
            fetchAllEmployees: true,
        });

        // If search term is empty, reset the state
        if (searchTerm.length === 0) {
            setDisplayEmps([]);
            setEmps(fetchedData.Results);
        } else {
            setDisplayEmps(fetchedData.Results);
        }
    };


    const handleAddAttendee = (val: number) => {
        if (values.Trips[0]?.Id) {
            setTargetAddEmp(val);
            setOpenAddModal(true);
        }
    };
    useEffect(() => {
        formatInitialData();
    }, [searchTerm]);
    useEffect(() => {
        // Scroll to the last row after the table renders
        if (tableRef.current) {
            const tableBody = window.document.querySelector('.ant-table-body');
            if (tableBody) {
                // Delay the scroll slightly to ensure table is fully rendered
                setTimeout(() => {
                    tableBody.scrollTop = tableBody.scrollHeight; // Scroll to the bottom
                }, 0); // You can adjust the delay if necessary
            }
        }
    }, [values.Attendees]);
    // Handle search input changes
    const handleSearchChange = (newTerm: string) => {
        setSearchTerm(newTerm);
    };
    const handleChangeSelect = (val: number) => {
        const selectedEmps = emps.filter((emp) => val === emp.EmployeeId);
        if (values.Trips[0]?.Id) {
            const modified = selectedEmps.map((sel) => {
                const mp = values.Attendees.filter(
                    (at) => at.Attendee.Id === sel.EmployeeId,
                )[0];
                return {
                    Id: sel.EmployeeId,
                    IsTraveller:
                        values.MeetingMode === 0
                            ? true
                            : mp
                                ? mp.IsTraveller
                                : false,
                    PhysicalAttendance: mp ? mp.PhysicalAttendance : false,
                    Attendee: {
                        Person: {
                            FullName: sel.FullName
                        },
                        BasedInCountry: sel.BasedInCountryName,
                        Id: sel.EmployeeId,
                        FirstName: sel.FirstName,
                        LastName: sel.LastName,
                        FullName: sel.FullName,
                        // FrequentFlyerNr: sel.FrequentFlyerNr,
                        // FrequentFlyerCo: sel.FrequentFlyerCo,
                    },
                };
            });
            setFieldValue('Attendees', [...values.Attendees, ...modified]);
            setSearchTerm('');
            if (values.MeetingMode !== 0) handleAddAttendee(val);

        } else {
            const modified = selectedEmps.map((sel) => {
                const mp = values.Attendees.filter(
                    (at) => at.Attendee.Id === sel.EmployeeId,
                )[0];
                return {
                    Id: sel.EmployeeId,
                    IsTraveller: mp ? mp.IsTraveller : values.MeetingMode === 0 ? true : false,
                    PhysicalAttendance: mp ? mp.PhysicalAttendance : values.MeetingMode === 0 ? true : false,
                    Attendee: {
                        Id: sel.EmployeeId,
                        Person: {
                            Id: sel.EmployeeId,
                            FirstName: sel.FirstName,
                            LastName: sel.LastName,
                            FullName: sel.FullName,
                        },
                        BasedInCountry: sel.BasedInCountryName,

                    },
                };
            });
            setFieldValue('Attendees', [...values.Attendees, ...modified]);
            handleAddAttendee(val)
        }
        setSearchTerm('');
    };
    useEffect(() => {
        if (values.Project && values.Project.Id !== 0)
            formatInitialData();
    }, [values.Project]);

    const columns: TableProps<any>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'Attendee',
            key: 'name',
            render: (cell) => cell.Person.FullName,
            align: 'center',
            width: 300,
        },

        values.MeetingMode !== 1 ? {
            title: 'Physical Attendance',
            key: 'onSite',
            width: 200,
            render: (cell) => (
                <>
                    <Select
                        style={{ width: '75px' }}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ]}
                        value={values.Trips.length === 0 ? false : cell.IsTraveller}
                        disabled={true}
                    />
                </>
            ),
            align: 'center',
        } : {},
        {
            render: (cell) => (
                <Button danger className='border-danger hover:border-dangerHover' onClick={() => {
                    if (cell.IsTraveller && values.Trips.length > 0) { setOpenRemoveModal(true); setTargetEmp(cell) }
                    else {
                        setFieldValue('Attendees', values.Attendees.filter((att) => att.Id !== cell.Id))
                    }
                }}>
                    <DeleteOutlined className='text-danger hover:text-dangerHover' />
                </Button>
            ),
            align: 'center'
        }
    ];
    return (
        <div data-testid="leaveTypeDropdown" className="flex gap-4 flex-col">
            <WarningModal
                title="Remove Attendee From Trip"
                isOpen={openRemoveModal}
                setIsOpen={setOpenRemoveModal}
                footerButtons={[
                    <Button onClick={() => setOpenRemoveModal(false)}>Close</Button>,
                    <Button
                        onClick={async () => {
                            if (targetEmp && targetEmp.IsTraveller) {
                                setFieldValue('Attendees', values.Attendees.filter((at) => at.Id !== targetEmp.Id))
                            }
                            setOpenRemoveModal(false);
                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Remove
                    </Button>,
                ]}
                mainText={`Do you want to remove ${targetEmp?.Attendee.Person.FullName} from trip as well?`}
            />
            <WarningModal
                title="Add Attendee to Trip"
                isOpen={openAddModal}
                setIsOpen={setOpenAddModal}
                footerButtons={[
                    <Button
                        onClick={() => {
                            if (
                                values.Attendees.filter((d) => d.Attendee.Id === targetAddEmp)
                                    .length === 0
                            ) {
                                const selectedEmp = emps.filter(
                                    (emp) => targetAddEmp === emp.EmployeeId,
                                )[0];
                                setFieldValue('Attendees', [
                                    ...values.Attendees,
                                    {
                                        Id: selectedEmp.EmployeeId,
                                        IsTraveller: false,
                                        PhysicalAttendance: false,
                                        Attendee: {
                                            BasedInCountry: selectedEmp.BasedInCountryName,
                                            Id: selectedEmp.EmployeeId,
                                            FirstName: selectedEmp.FirstName,
                                            LastName: selectedEmp.LastName,
                                            FullName: selectedEmp.FullName,
                                            // FrequentFlyerNr: sel.FrequentFlyerNr,
                                            // FrequentFlyerCo: sel.FrequentFlyerCo,
                                        },
                                    },
                                ]);
                            }
                            setOpenAddModal(false);
                        }}
                    >
                        No
                    </Button>,
                    <Button
                        onClick={async () => {
                            if (targetAddEmp && values.Trips[0])
                                values.Attendees.map((v) => {
                                    if (v.Id === targetAddEmp) {
                                        v.IsTraveller = true;
                                    }
                                });
                            setOpenAddModal(false);
                        }}
                        className='bg-primary hover:color-primary text-white'
                    >
                        Add
                    </Button>,
                ]}
                mainText={`Do you want to add this employee to the trip as well?`}
            />
            <FormCard title="Attendees" icon={<UserAddOutlined />}>
                <div
                    data-testid="leaveTypeDropdown"
                    className="w-full flex gap-2 flex-col"
                >
                    <LoomSelect<any>
                        required
                        label="Attendees"
                        name="Attendees"
                        placeholder='Search to add'
                        disabled={(values.CreatedBy && id !== values.CreatedBy?.Id) || values.Project?.Id === 0}
                        onSearch={handleSearchChange} // Updated to handle search change
                        valueFunc={searchTerm ? searchTerm : null}
                        onChange={handleChangeSelect}
                        options={displayEmps.length > 0 || searchTerm.length > 0
                            ? displayEmps.map((emp) => {
                                return {
                                    value: emp.EmployeeId,
                                    label: emp.FullName,
                                };
                            }).filter(emp => !values.Attendees.some(att => att.Attendee.Id === emp.value))
                            : emps.map((emp) => {
                                return {
                                    value: emp.EmployeeId,
                                    label: emp.FullName,
                                };
                            }).filter(emp => !values.Attendees.some(att => att.Attendee.Id === emp.value))
                        }
                    />


                    {values.Attendees.length > 0 && (
                        <Table
                            columns={columns}
                            dataSource={values.Attendees}
                            rowKey={'Id'}
                            pagination={false}
                            scroll={{ y: 200 }}
                            className='custom-antd-header-bg'
                            ref={tableRef}
                        />
                    )}
                </div>
            </FormCard>
        </div>
    );
};

export default AttendeesMeeting;
