import { Modal, Typography } from 'antd';
import React from 'react'

type Props = {
    isOpen: boolean;
    setIsOpen: (d: boolean | any) => void;
    title: string;
    footerButtons: JSX.Element | JSX.Element[];
    mainText: string | React.ReactElement;
}

const WarningModal = ({ isOpen, setIsOpen, title, footerButtons, mainText }: Props) => {
    return (
        <Modal
            width={700}
            open={isOpen}
            onCancel={() => {
                setIsOpen(false);
            }}
            title={
                title
            }
            footer={footerButtons}
        >
            <div className={`col-12 d-flex`} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Typography>{mainText}</Typography>
            </div>

        </Modal>
    )
}

export default WarningModal