import React from 'react'
import { IconBaseProps } from '@ant-design/icons/lib/components/Icon';
import { Divider } from 'antd';
type Props = {
    title: string;
    children: React.ReactNode,
    icon: React.ReactElement;
    headerButton?: React.ReactElement;
}

const FormCard = ({ title, children, icon, headerButton }: Props) => {
    return (
        <div className="bg-white w-full p-4 flex flex-col rounded-lg shadow-md mb-4">
            <div>
                <div className='flex justify-between'>
                    <p className="flex gap-titleIcon mt-2 text-title" style={{ marginBlockEnd: 0 }}>
                        {icon}
                        <span>{title}</span>
                    </p>
                    {headerButton}
                </div>
                <Divider className='mt-dividerTop mb-dividerBottom' />
            </div>
            <div className='gap-4 flex flex-col'>
                {children}
            </div>
        </div>
    )
}

export default FormCard