import { Button } from 'antd'
import React, { useState } from 'react'
import { TPermissionAction, useAuth } from '../../features/auth';
import WarningModal from '../modal/WarningModal';
import TextArea from 'antd/es/input/TextArea';

type Props = {
    handleAction: (comments?: string) => void;
    permission?: TPermissionAction;
    bgColor: string;
    modalTitle: string;
    btnLabel: string;
    type?: "primary" | "link" | "text" | "default" | "dashed" | undefined
    danger?: boolean;
    modalDescription: string | React.ReactElement;
    hasComments?: boolean;
    btnModalLabel: string;
}

const ActionBtn = ({ handleAction, permission, modalTitle, btnLabel, type = undefined, danger = false, modalDescription, hasComments = false, btnModalLabel }: Props) => {
    const { allowIf } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [comments, setComments] = useState('');
    const onHandleClick = () => {
        if (comments)
            handleAction(comments);  // Call the parent function with the comment
        else
            handleAction();
    };
    return (
        <>
            <WarningModal
                title={modalTitle}
                isOpen={openModal}
                setIsOpen={setOpenModal}
                footerButtons={[
                    <Button onClick={() => setOpenModal(false)}>Close</Button>,
                    <Button
                        danger={danger}
                        type={type}
                        onClick={onHandleClick}
                    >
                        {btnModalLabel}
                    </Button>,
                ]}
                mainText={
                    <div className='flex gap-2 flex-col'>
                        <span>{modalDescription}</span>
                        {hasComments && <TextArea value={comments} placeholder='Leave a comment' onChange={(e) => setComments(e.target.value)} />}
                    </div>}
            />
            {
                permission ? allowIf([permission]) && <Button
                    type={type}
                    danger={danger}
                    onClick={() => setOpenModal(true)}
                >
                    <span className="indicator-label">{btnLabel}</span>
                </Button> : <Button
                    type={type}
                    danger={danger}
                    onClick={() => setOpenModal(true)}
                >
                    <span className="indicator-label">{btnLabel}</span>
                </Button>
            }

        </>


    )
}

export default ActionBtn