import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import useTableContext from '../../../table/provider/TableContext'
import dayjs from 'dayjs'

type Props = {}

const DropDownMeetingSelection = (props: Props) => {
    const { from, handleFromChange } = useTableContext();
    const [value, setValue] = useState(0)
    useEffect(() => {
        handleFromChange(dayjs(new Date).set('hour', 0).toISOString())
    }, [])
    return (
        <Select size="small" options={[
            { value: 0, label: 'Upcoming' },
            { value: 1, label: 'All' },
        ]}
            value={from === dayjs(new Date).set('hour', 0).toISOString() ? 0 : value}
            onChange={(v) => {
                if (v === 0) {
                    handleFromChange(dayjs(new Date).set('hour', 0).utc().toISOString())
                    setValue(0)
                } else {
                    handleFromChange('')
                    setValue(1)
                }
            }}
            style={{ width: "100%", minWidth: '100px' }}
        />
    )
}

export default DropDownMeetingSelection