import React, { useEffect, useState } from 'react'
import WarningModal from '../../../../components/modal/WarningModal'
import { Button, Select } from 'antd';
import { LeaveColors } from '../../../../../shared/colors';
import { useNavigate } from 'react-router-dom';
import useNotification from 'antd/es/notification/useNotification';
import { createTripFromMeeting, Meeting } from '../../../../api-requests/Meeting';
import { AxiosError } from 'axios';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
const { Option } = Select;
type Props = {

    meetingId: number;
    data: Meeting[],
    handleCreate?: any;
    handleEdit?: any;
}

const MultipleOrganisationTripCreation = ({
    meetingId, data, handleCreate, handleEdit
}: Props) => {
    const [orgsSelected, setOrgsSelected] = useState<any>([]);
    const [singleOrg, setSingleOrg] = useState(0);
    const [createWarningOpen, setCreateWarningOpen] = useState(false);
    const [selectOrgModal, setSelectOrgModal] = useState(false);
    const navigate = useNavigate();
    const { openNotification } = useNotifications();
    const handleCreateTrip = async () => {
        try {
            await createTripFromMeeting({ meetingId: meetingId, orgId: singleOrg }).then((res) => {
                setSelectOrgModal(false);
                openNotification('topRight', {
                    title: 'Trip',
                    description: 'Trip created successfully for meeting:' + res.Meeting.Name + ' ' + res.Project.Acronym,
                    type: 'Success',
                });
                navigate('/apps/projects/meetings/trip/' + res.Id, {
                    state: { data: res, edit: true, view: true },
                });
            });
        } catch (error) {
            if (error instanceof AxiosError) {
                setSelectOrgModal(false);
                openNotification('topRight', {
                    title: 'Trip',
                    description: error?.response?.data.error,
                    type: 'Danger',
                });
                console.error('Error approving timesheet:', error);
            }
        }
    };
    const extractUniqueOrganisation = () => {
        const meeting = data.filter((d) => d.Id === meetingId)[0]
        // Extract organizations from attendees
        if (meeting) {
            const organizations = meeting.Attendees.map(item => item.Attendee.Organisation);

            // Get unique organizations by Id
            const uniqueOrganizations = Array.from(new Set(organizations.map(org => org.Id)))
                .map(id => {
                    return organizations.find(org => org.Id === id);
                });

            // Map over unique organizations and find the corresponding trip
            const organizationsWithTrips = uniqueOrganizations.map(org => {
                // Find a trip where the organization matches the Beneficiary's organisation

                const trip = meeting.Trips.find(trip => trip.Beneficiary?.Organisation.Id === org?.Id);

                // Return the organization with the attached trip (if found)
                return {
                    ...org,
                    trip: trip || null // Attach trip or null if not found
                };
            });
            setOrgsSelected(organizationsWithTrips);
        }
    };
    useEffect(() => {
        extractUniqueOrganisation();
    }, [meetingId]);
    useEffect(() => {
        // Set default value to the first organization if orgsSelected is not empty
        if (orgsSelected.length > 0) {
            setSingleOrg(orgsSelected[0].Id);
        }
        if (meetingId) {
            if (orgsSelected.length === 1) {
                setCreateWarningOpen(true);
            } else {
                setSelectOrgModal(true);
            }
        }
    }, [orgsSelected]);
    return (
        <>
            <WarningModal
                title="Create Trip"
                isOpen={createWarningOpen}
                setIsOpen={setCreateWarningOpen}
                footerButtons={[
                    <Button key={0} onClick={() => setCreateWarningOpen(false)}>Close</Button>,
                    <Button
                        key={1}
                        onClick={() => {
                            if (meetingId) {
                                if (handleCreate) {
                                    handleCreate()
                                } else {
                                    handleCreateTrip();
                                }
                            }
                        }}
                        style={{ background: LeaveColors[0].value, color: 'white' }}
                    >
                        Create
                    </Button>,
                ]}
                mainText="Are you sure you want to create trip for this meeting?"
            />
            <WarningModal
                title={'Multiple Beneficiaries Detected'}
                isOpen={selectOrgModal}
                setIsOpen={setSelectOrgModal}
                footerButtons={[
                    <Button key={'close'} onClick={() => setSelectOrgModal(false)}>
                        Close
                    </Button>,

                    <Button
                        key={'action'}
                        style={{ background: LeaveColors[0].value, color: 'white' }}
                        onClick={() => {
                            const tripExist = orgsSelected.filter((org: any) => org.Id === singleOrg)[0].trip
                            if (tripExist) {
                                if (handleEdit) {
                                    handleEdit()
                                } else {
                                    navigate('/apps/projects/meetings/trip/' + tripExist.Id, { state: { data: tripExist, redirect: true } })

                                }

                            } else {
                                if (handleCreate) {
                                    handleCreate();
                                } else {
                                    handleCreateTrip();
                                }

                            }
                        }}
                    >
                        {orgsSelected && singleOrg && orgsSelected.filter((org: any) => org.Id === singleOrg)[0]?.trip ? 'Edit' : 'Create'}

                    </Button>,
                ]}
                mainText={
                    <div>
                        <span>There are participants from different beneficiaries for this meeting. Please select the beneficiary for which you would like to create a trip.</span>
                        <Select
                            placeholder="Select an organization"
                            onChange={(value) => setSingleOrg(value)}
                            style={{ width: '100%' }}
                            value={singleOrg}
                            className='mt-4'
                        >
                            {orgsSelected.map((org: any) => (
                                <Option key={org.Id} value={org.Id}>
                                    {org.ShortName} {org.trip ? '(trip exists)' : ''}
                                </Option>
                            ))}
                        </Select>
                    </div>
                }
            />
        </>
    )
}

export default MultipleOrganisationTripCreation