import { FC, ReactNode, ReactPortal, useMemo, useState } from 'react';
import { LeaveOptions } from '../../../../@types/FetchOptions';
import { deleteTripById, fetchApprovalTrips, getTripList } from '../../../../api-requests/Meeting';
import TripContext from './tripContext';
import { Beneficiary, fetchBeneficiaries } from '../../../../api-requests/Projects';
import { fetchApprovalList } from '../../../../api-requests/Leave';


type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};

export const TripProvider: FC<TUserProviderProps> = ({ children }) => {
    const [trips, setTrips] = useState<any[]>([]);
    const [tripsTotal, setTripsTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
    const [tripApprovals, setTripApprovals] = useState<any[]>([]);
    const [tripApprovalTotal, setTripApprovalTotal] = useState(0);
    const getTrip = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const meetingResponse = await getTripList({ ...params });
            setTrips(meetingResponse.Results);
            setTripsTotal(meetingResponse.TotalResults)
            return trips;
        } catch (error) {
            console.error('Error fetching trips:', error);
        } finally {
            setLoading(false);
        }
    };
    const getApprovalTrips = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const approvalResponse = await fetchApprovalTrips({ ...params });
            setTripApprovals(approvalResponse.Results);
            setTripApprovalTotal(approvalResponse.TotalResults);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    }
    const getBeneficiaries = async (projectId: number | undefined) => {
        setLoading(true);
        try {
            const ben = await fetchBeneficiaries({ projectId: projectId });
            setBeneficiaries(ben);
            return ben;
        } catch (error) {
            console.error('Error fetching beneficiaries:', error);
        } finally {
            setLoading(false);
        }
    }
    const handleDeleteTripById = async (id: number) => {
        setLoading(true);
        try {
            await deleteTripById(id);
            setTrips(trips.filter((trip) => trip.Id !== id));
        } catch (error) {
            console.error('Error deleting trip:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    }
    const contextValue = useMemo(
        () => ({
            trips,
            getTrip,
            tripsTotal,
            loading,
            handleDeleteTripById,
            getBeneficiaries,
            beneficiaries,
            getApprovalTrips,
            tripApprovals,
            tripApprovalTotal,
        }),
        [trips, loading],
    );
    return (
        <TripContext.Provider value={contextValue} >
            {children}
        </TripContext.Provider>
    );
};
