import React, { useMemo } from 'react';
import MeetingContext from './meetingContext';

export const useMeetings = () => {
    const context = React.useContext(MeetingContext);

    if (context === undefined) {
        throw new Error('useMeetings must be used within a MeetingProvider');
    }
    // Memoize the context value to prevent unnecessary re-renders
    const memoizedContext = useMemo(() => context, [context]);
    return memoizedContext;
};
