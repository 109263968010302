import { ExportConfig } from '../../../table/components/TableExport';
import {
  dateTimeTransformer,
  dateTransformer,
} from '../../../../components/transformers/commonTransformers';
import { Meeting } from '../../../../api-requests/Meeting';

const locationTransformer = (row: unknown) => {
  const meetingRow = row as Meeting;

  if (meetingRow === undefined) {
    return '';
  } else {
    if (meetingRow.IsVirtual) {
      return 'Virtual Meeting';
    } else {
      const location = meetingRow.Location;
      if (location === undefined) {
        return '';
      } else {
        const name =
          location.Address.length > 0
            ? location.Address + ', ' + location.City
            : location.City;
        if (name) {
          return name;
        } else {
          return '';
        }
      }
    }
  }
  // throw new Error('Invalid parameter type');
};

const attendeesTransformer = (row: unknown) => {
  const meetingRow = row as Meeting;
  if (meetingRow === undefined) {
    return '';
  } else {
    const attendees = meetingRow.Attendees;
    if (attendees === undefined) {
      return '';
    }

    if (Array.isArray(attendees)) {
      if (attendees.length > 0) {
        // @ts-ignore
        return attendees.map((attendee) => attendee.Attendee.Person.FullName).join(', ');
      } else {
        return '';
      }
    }
  }

  throw new Error('Invalid parameter type');
};

export const meetingsConfig: ExportConfig<Meeting> = [
  // @ts-ignore
  { key: 'Name', label: 'Title' },
  {
    key: ['Attendees.FullName'],
    label: 'Participants',
    transformer: attendeesTransformer,
  },
  { key: 'Project.Acronym', label: 'Project' },
  { key: 'From', label: 'Start Date', transformer: dateTimeTransformer },
  { key: 'To', label: 'End Date', transformer: dateTimeTransformer },
  {
    key: ['IsVirtual', 'Location.Name'],
    label: 'Type',
    transformer: locationTransformer,
  },

];
