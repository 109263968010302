import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { CommonListResponse } from '../@types/Response';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const FETCH_PROJECTS = `${API_URL}/projects/list`;
export const FETCH_PROJECTS_PARTICIPATION = `${API_URL}/projects/my-project-participations`;

export const FETCH_BENEFICIARIES = `${API_URL}/projects/beneficiaries`;
export interface FetchProjectsOptions {
  searchTerm?: string;
  pageIndex?: number;
  pageSize?: number;
  sorting?: string;
  orgId?: number;
}

export const fetchProjects = async ({
  searchTerm = '',
  pageIndex = 0,
  pageSize = 10,
  sorting = '',
  orgId = 0,
}: FetchProjectsOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    orgId: orgId?.toString(),
    searchTerm,
    sorting,
  });

  try {
    const response = await axios.get<CommonListResponse<Project>>(
      `${FETCH_PROJECTS}?${params.toString()}`,
    );

    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchProjectsParticipation = async ({
  searchTerm = '',
  pageIndex = 0,
  pageSize = 10,
  sorting = '',
  orgId = 0,
}: FetchProjectsOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    orgId: orgId?.toString(),
    searchTerm,
    sorting,
  });

  try {
    const response = await axios.get<CommonListResponse<Project>>(
      `${FETCH_PROJECTS_PARTICIPATION}?${params.toString()}`,
    );

    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchBeneficiaries = async ({
  projectId
}: { projectId: any }) => {
  const params = new URLSearchParams({
    projectId: projectId.toString()
  });

  try {
    const response = await axios.get<Beneficiary[]>(
      `${FETCH_BENEFICIARIES}?${params.toString()}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export interface Beneficiary {
  Id: number;
  ParticipantNumber: string;
  ShortName: string;
  role: number;
  OtherDirectCosts_TravelCosts: number;
  Organisation: {
    Id: number;
    ShortName: string;
    LegalName: string;
    BusinessName: string;
    Country: {
      Id: number;
      Name: string;
      IsoCode2: string;
    }
  };
  Sponsor: string;
}
export interface Project {
  Id: number;
  Acronym: string;
  ProjectNumber?: string;
  Title?: string;
  StartDate?: string;
  Duration?: number;
  EndDate?: string;
  CurrentMonth: number;
  Progress: number;
}
