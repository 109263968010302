import React, { useEffect, useRef, useState } from 'react';
import CreateMeeting from './CreateMeeting';
import { Meeting, createTripFromMeeting, fetchMeetingById } from '../../../../api-requests/Meeting';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Spin, Typography } from 'antd';
import { useAuth } from '../../../auth';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { AxiosError } from 'axios';
import useTableContext from '../../../table/provider/TableContext';
import dayjs from 'dayjs';
import { LeaveColors } from '../../../../../shared/colors';
import { useTrip } from '../../Trip/provider/useTrip';
import WarningModal from '../../../../components/modal/WarningModal';
import Header from '../../../../pages/layout/Header';

type Props = {};

const { Title } = Typography;

const CreateMeetingWrapper = (props: Props) => {
    const [dataMeeting, setData] = useState<Meeting>();
    const [deleteTrip, setDeleteTrip] = useState(false);
    const [loading, setLoading] = useState(true);  // Initially set to true
    const { searchTerm } = useTableContext();
    const location = useLocation();
    const { id } = useAuth();
    const p = useParams();
    const navigate = useNavigate();
    const { openNotification } = useNotifications();
    const { handleDeleteTripById } = useTrip();
    const initialTitle = useRef('Create Meeting');

    useEffect(() => {
        const fetchMeetingData = async () => {
            let meetingData = null;
            if (p.id) {
                setLoading(true);
                meetingData = await fetchMeetingById(Number(p.id));
                setLoading(false);
            }
            setData(meetingData as Meeting);
            if (meetingData && meetingData.Id !== 0) {
                initialTitle.current = id === meetingData.CreatedBy?.Id ? 'Update Meeting' : 'View Meeting';
            }
            setLoading(false);  // Ensure loading is false after data fetch
        };

        const state = location.state as { data: Meeting };
        if (state && state.data) {
            setData(state.data);
            if (state.data.Id !== 0) {
                initialTitle.current = id === state.data.CreatedBy?.Id ? 'Update Meeting' : 'View Meeting';
            }
            setLoading(false);  // Ensure loading is false if state data is present
        } else {
            fetchMeetingData();
        }
    }, [location.state, p.id, id]);


    const handleTripDelete = async (id: number) => {
        try {
            await handleDeleteTripById(id);
            setDeleteTrip(false);
            openNotification('topRight', {
                title: 'Trip',
                description: 'Trip deleted successfully',
                type: 'Success',
            });

            navigate('/apps/projects/meetings');
        } catch (error) {
            if (error instanceof AxiosError) {
                openNotification('topRight', {
                    title: 'Trip',
                    description: error?.response?.data.error,
                    type: 'Danger',
                });

                console.error('Error deleting trip:', error);
            }
        }
    }
    if (loading) {
        return <div className='flex w-full h-full justify-center items-center'><Spin spinning={loading}></Spin></div>;
    }
    return (
        <>
            <WarningModal
                title="Withdraw Leave"
                isOpen={deleteTrip}
                setIsOpen={setDeleteTrip}
                footerButtons={[
                    <Button onClick={() => setDeleteTrip(false)}>Close</Button>,
                    <Button
                        onClick={() => {
                            if (dataMeeting && dataMeeting?.Trips[0]?.Id) handleTripDelete(dataMeeting.Trips[0]?.Id);
                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Delete
                    </Button>,
                ]}
                mainText="Are you sure you want to delete this project meeting? Your action will remove it permanently."
            />

            <Header title={initialTitle.current}>
                <CreateMeeting data={dataMeeting} />
            </Header>
        </>
    );
};

export default CreateMeetingWrapper;
