import useTableContext from '../../table/provider/TableContext';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/themes/airbnb.css';
import {
  Switch,
  DatePicker,
  Button,
  Space,
  Dropdown,
  Radio,
  MenuProps,
  Typography,
  Grid,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import { getCurrentWeekDates, getFirstAndLastDateOfMonth } from '../../../utils/checkDates';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FilterOutlined, RightOutlined, LeftOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import LoomButton from '../../../components/ui/LoomButton';
import { AppColors, LeaveColors } from '../../../../shared/colors';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import EmployeeSidebar from '../../../components/employeeSidebar/EmployeeSidebar';
import { EmployeeSimple, fetchRelatedPeople } from '../../../api-requests/Employees';
import { randomHexColor } from '../../../utils/colourFunctions';
import { useTeam } from '../../humanResources/teams/provider/useTeam';
import ParticipantsCell from '../../../components/cells/ParticipantsCell';
import AvatarGroupCell from '../../../components/cells/AvatarGroupCell';

export type CallendarViewTypes =
  | 'dayGridWeek'
  | 'dayGridDay'
  | 'dayGridMonth'
  | 'customFourWeeks';

const { useBreakpoint } = Grid;
type Props = {
  setCreateModalOpen: (d: boolean) => void;
  setview?: (d: CallendarViewTypes) => void;
  view?: CallendarViewTypes;
  setDisplayMonth: Dispatch<SetStateAction<Date>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayMonth: Date;
  title?: string;
};

type LeaveBubbleDate = {
  color: string;
  description: string;
  onClick: () => void;
  isActive: boolean;

};
const LeaveBubble = ({ color, description, onClick, isActive }: LeaveBubbleDate) => (
  <div className="flex justify-content-center align-items-center cursor-pointer" onClick={onClick}>
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        backgroundColor: isActive ? color : color + '40',
        display: 'inline-block',
        marginRight: '5px',
      }}
    ></div>
    <span style={{ fontSize: 12 }}>{description}</span>
  </div>
);

type AssigneesButtonProps = {
  title?: string
}
export const AssigneesButton = ({ title }: AssigneesButtonProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const { employeeIds, meMode } = useTableContext();
  const [employeeOptions, setEmployeeOptions] = useState<Array<EmployeeSimple>>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { teams, getTeams } = useTeam();
  useEffect(() => {
    const fetchFilteredEmps = async () => {
      try {
        const fetchedEmployees = await fetchRelatedPeople({ searchTerm: searchTerm, includeInactive: false });
        const enrichedEmployees: EmployeeSimple[] =
          fetchedEmployees.Results.map((result) => {
            return {
              ...result,
              avatarColour: randomHexColor(),
            };
          });
        setEmployeeOptions(enrichedEmployees);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchTeamsFun = async () => {
      if (teams.length === 0) {
        try {
          await getTeams({ searchTerm: searchTerm, includeInactive: false });
        } catch (error) {
          console.error('Fetching teams', error)
        }
      }

    }
    fetchFilteredEmps();
    fetchTeamsFun();
  }, [searchTerm]);
  return (
    <>
      <EmployeeSidebar title={title ? title : 'Assignees'} employeeOptions={employeeOptions} collapsed={collapsed} setCollapsed={setCollapsed} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Button
        size="small"
        className={`md:rounded-xl flex items-center transition-colors duration-200 
      }`}
        icon={<UsergroupAddOutlined />}
        onClick={() => setCollapsed(true)}
      >
        {employeeIds.length > 0 && !meMode ? (<div><AvatarGroupCell cell={employeeOptions.filter((emp) => employeeIds.includes(String(emp.Id)))} /></div>) : <span>{'People'}</span>}
      </Button>
    </>)
}
export const MeModeButton = () => {
  const { meMode, handleMeMode } = useTableContext();
  const { md } = useBreakpoint();

  return (
    <Button
      onClick={() => handleMeMode(!meMode)}
      size="small"
      className={`md:rounded-xl flex items-center transition-colors duration-200 ${meMode ? 'bg-primary text-white hover:bg-primary  ' : 'bg-white border text-gray-700 hover:bg-gray-100'
        }`}
      icon={<UserOutlined />}
    >
      {md && <span>Me mode</span>}
    </Button>
  );
};
const CalendarFilters = ({
  setCreateModalOpen,
  setDisplayMonth,
  displayMonth,
}: Props) => {
  const navigate = useNavigate();
  const { xs } = useBreakpoint();
  const { handleCheckboxChange, filterStates, to, handleDate, meMode, handleMeMode, view, handleViewChange, type, handleTypeChange, typefilter } =
    useTableContext();
  const [typeFilters, setTypeFilters] = useState([
    {
      label: 'Leave',
      value: true,
    },
    { label: 'Meeting', value: true },
  ]);

  const picker =
    view === 'dayGridDay'
      ? 'date'
      : view === 'dayGridMonth'
        ? 'month'
        : view === 'dayGridWeek'
          ? 'week'
          : 'month';
  const format =
    view === 'dayGridDay'
      ? 'DD MMM YYYY'
      : view === 'dayGridMonth'
        ? 'MMM YYYY'
        : view === 'dayGridWeek'
          ? 'DD MMM YYYY'
          : 'month';
  const handleToggleFilter = (index: number) => {
    setTypeFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index].value = !updatedFilters[index].value;
      return updatedFilters;
    });
  };
  const items: MenuProps['items'] = [
    {
      key: 'add_leave',
      icon: <CalendarOutlined />,
      label: (
        <Typography onClick={() => setCreateModalOpen(true)}>Leave</Typography>
      ),
    },
    {
      key: 'add_projectMeeting',
      icon: <ClockCircleOutlined />,
      label: (
        <Typography onClick={() => navigate('/apps/projects/meetings/create')}>
          Meeting
        </Typography>
      ),
    },
  ];

  return (
    <div
      className="flex w-full justify-between mb-6  "
      data-testid="customToolbar"
    >

      <div style={{ gap: 4, display: 'flex', alignItems: 'center' }}>
        <Space>
          <Button
            size="small"
            onClick={() => {
              if (view === 'dayGridMonth') {
                const d = moment(displayMonth).subtract(1, 'months').toDate();
                setDisplayMonth(d);
                handleDate(getFirstAndLastDateOfMonth(d));
              } else {
                const d = moment(displayMonth).subtract(1, 'week').toDate();
                setDisplayMonth(d);
                handleDate(getCurrentWeekDates(d));
              }

            }}
          >
            <LeftOutlined />
          </Button>

          <DatePicker
            size="small"
            data-testid="datePickerCalendar"
            allowClear={false}
            style={{ width: 125 }}
            picker={picker}
            onChange={(date) => {
              setDisplayMonth(date.toDate());
              if (view === 'dayGridMonth')
                handleDate(getFirstAndLastDateOfMonth(date.toDate()));
              else if (view === 'dayGridWeek')
                handleDate([date.day(0).toDate(), date.day(6).toDate()]);
            }}
            className=" cursor-pointer"
            format={format}
            value={
              view !== 'dayGridMonth'
                ? dayjs(displayMonth)
                : to
                  ? dayjs(to)
                  : dayjs(displayMonth)
            }
          />
          <Button
            size="small"
            onClick={() => {
              if (view === 'dayGridMonth') {
                const d = moment(displayMonth).add(1, 'months').toDate();
                setDisplayMonth(d);
                handleDate(getFirstAndLastDateOfMonth(d));
              } else {
                const d = moment(displayMonth).add(1, 'week').toDate();
                setDisplayMonth(d);
                handleDate(getCurrentWeekDates(d));
              }

            }}
          >
            <RightOutlined />
          </Button>
          <Button
            size="small"
            data-testid="dropdownFilters"
            onClick={() => {
              if (view === 'dayGridMonth') handleDate(getFirstAndLastDateOfMonth(new Date()))
              else {
                handleDate(getCurrentWeekDates(new Date()))
              }
            }
            }

          >Today</Button>

          {!xs && (
            <Select size="small" options={[
              { value: 'dayGridMonth', label: 'Month' },
              { value: 'dayGridWeek', label: 'Week' }
            ]}
              value={view}
              onChange={(e) => {
                handleDate(getFirstAndLastDateOfMonth(displayMonth), e)
              }}
            />
          )}
          {/* {!xs && (
            <Radio.Group
              onChange={(e) => {
                handleDate(getFirstAndLastDateOfMonth(displayMonth), e.target.value)
              }}

              buttonStyle="solid"
              data-testid="radioBtns"
              value={view}
            >
              <Radio.Button value="dayGridWeek">Week</Radio.Button>
              <Radio.Button value="dayGridMonth">Month</Radio.Button>
            </Radio.Group>
          )} */}
        </Space>
      </div>

      <Space>
        <Select size="small" options={[
          { value: '0,1', label: 'All' },
          { value: '0', label: 'Leaves' },
          { value: '1', label: 'Meetings' }
        ]}
          value={type}
          onChange={(val) => handleTypeChange(val.split(','))}
          style={{ width: "100%", minWidth: '100px' }}
        />
        <MeModeButton />
        <AssigneesButton title={'People'} />
        {typefilter[0].value && <div
          className="md:flex hidden items-center justify-center  gap-5"
          style={{
            padding: '6px 10px',
            borderRadius: '0.5em',
            border: '1px solid #f1f1f4',
          }}
        >
          {filterStates.map((checkbox, index: number) => {
            return (
              <LeaveBubble key={index} color={LeaveColors[index as 0 | 1 | 2 | 3].value} isActive={checkbox.value} description={checkbox.label} onClick={() => handleCheckboxChange(index)} />
            )
          })}
        </div>}




        < Dropdown menu={{ items }}>
          <Button
            size={"small"}
            className='bg-primary text-white'
            data-testid="addEntryBtn"
            icon={<PlusOutlined />}
            type={'primary'}
          />
        </Dropdown >
      </Space >
    </div >
  );
};

export default CalendarFilters;
