import { Button } from 'antd';
import { toAbsoluteUrl } from '../../../app/helpers';
import { isLightColour } from '../../../app/utils/colourFunctions';
import { hashCode, intToRGB } from '../../../app/utils/generateColors';

interface CustomAvatarProps {
  avatarProps: {
    initials: string;
    avatarColour?: string;
    image?: string;
    name?: string;
  };
  onClickFunc?: () => void;
}

const CustomAvatar = ({ avatarProps, onClickFunc }: CustomAvatarProps) => {
  return (
    <div className="symbol-circle">
      {avatarProps.image ? (
        <img src={toAbsoluteUrl(avatarProps.image)} alt="" />
      ) : (
        <Button
          className={` w-2 h-8 flex justify-center items-center rounded-full border-0 `}
          style={{
            color:
              avatarProps.name &&
                isLightColour('#' + intToRGB(hashCode(avatarProps?.name)))
                ? 'black'
                : 'white',
            backgroundColor: avatarProps.name
              ? '#' + intToRGB(hashCode(avatarProps.name))
              : '',
          }}
          onClick={onClickFunc}
        >
          {avatarProps.initials}
        </Button>
      )}
    </div>
  );
};

export default CustomAvatar;
