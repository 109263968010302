import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { Meeting } from '../../../../../api-requests/Meeting';
import { Input, Select, Typography } from 'antd';
import {
    FormOutlined,
    EnvironmentOutlined,
    InboxOutlined,
    EyeOutlined,
    DeleteOutlined,
    SnippetsOutlined,
    InfoCircleOutlined,
    EditOutlined,
    FileOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import tzData from 'moment-timezone/data/meta/latest.json';

import { getCountries } from '../../../../../api-requests/GeoData';
import { useAuth } from '../../../../auth';
import moment from 'moment';
import FormCard from '../../../../../utils/Forms/FormCard';
import LoomInput from '../../../../../components/StyledComponents/LoomInput';
import LoomSelect from '../../../../../components/StyledComponents/LoomSelect';

const { Title } = Typography;
type Props = {
    values: Meeting;
    setFieldValue: any;
}

const LocationMeeting = ({ values, setFieldValue }: Props) => {
    const [countries, setCountries] = useState<any[]>([]);
    const [defaultUTC, setDefaultUTC] = useState<any>();
    const [times, setTimes] = useState<any[]>([]);

    const { id } = useAuth();

    const formatSearchCountry = async (searchTerm = '') => {
        const fetchedData = await getCountries({
            searchTerm,
        });
        setCountries(fetchedData);
    };
    useEffect(() => {
        if (values.MeetingMode === 1)
            setFieldValue('Location.Country.Name', 'online');
    }, [values.MeetingMode])
    useEffect(() => {
        formatSearchCountry();
    }, [])
    return (
        <FormCard title="Location" icon={<EnvironmentOutlined />}>
            <div
                data-testid="leaveTypeDropdown"
                className="flex gap-4 flex-col"
            >
                <div>
                    <div className="col-span-10">
                        {(values.MeetingMode === 1 ||
                            values.MeetingMode === 2) && (
                                <div className="flex gap-2 flex-col">
                                    <LoomInput
                                        disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                                        prefix={<EnvironmentOutlined />}
                                        onChange={(val) => {
                                            setFieldValue(
                                                'VirtualMeetingLink',
                                                val.target.value,
                                            );
                                        }}
                                        value={values.VirtualMeetingLink}
                                        placeholder="Meeting Link"
                                        label={'Meeting Link'}
                                    />
                                </div>
                            )}
                        {(values.MeetingMode === 2 ||
                            values.MeetingMode === 0) && (
                                <>
                                    <div className="flex gap-2 flex-col w-full">
                                        <div className="flex w-full gap-4 flex-row">
                                            <div className="flex gap-2 flex-col w-full">

                                                <LoomInput
                                                    name="Location.Location"
                                                    label="Name"
                                                    disabled={
                                                        values.CreatedBy && id !== values.CreatedBy?.Id
                                                    }
                                                    onChange={(val) => {
                                                        setFieldValue(
                                                            'Location.Name',
                                                            val.target.value,
                                                        );
                                                    }}
                                                    value={values.Location?.Name}
                                                    placeholder="Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex w-full gap-2 flex-row">
                                            <div className="flex gap-2 flex-col w-full">
                                                <LoomInput
                                                    name="Location.Address"
                                                    label={"Address"}
                                                    onChange={(val) => {
                                                        setFieldValue(
                                                            'Location.Address',
                                                            val.target.value,
                                                        );
                                                    }}
                                                    value={values.Location?.Address}
                                                    placeholder="Address"
                                                    disabled={
                                                        values.CreatedBy && id !== values.CreatedBy?.Id
                                                    }
                                                />
                                            </div>
                                            <div className="flex gap-2 flex-col w-full">
                                                <LoomInput
                                                    name="Location.City"
                                                    label={'City'}
                                                    onChange={(val) => {
                                                        setFieldValue(
                                                            'Location.City',
                                                            val.target.value,
                                                        );
                                                    }}
                                                    value={values.Location?.City}
                                                    placeholder="City"
                                                    disabled={
                                                        values.CreatedBy && id !== values.CreatedBy?.Id
                                                    }
                                                />
                                            </div>
                                            <div className="flex gap-2 flex-col w-full">
                                                <LoomSelect<number | null>
                                                    required
                                                    name="Location.Country.Name"
                                                    label="Country"
                                                    placeholder="Countries..."
                                                    onSearch={(v) => formatSearchCountry(v)}
                                                    onChange={(val) => {
                                                        const t = countries.filter(
                                                            (country) => country.Id === val,
                                                        )[0];
                                                        setFieldValue('Location.Country', t);
                                                        // @ts-ignore
                                                        const countryTimezones = tzData.countries[t.ISOCode2];
                                                        if (
                                                            !countryTimezones ||
                                                            countryTimezones.zones.length === 0
                                                        ) {
                                                            return;
                                                        }

                                                        // Assume the first timezone in the list is the desired one
                                                        const timezone =
                                                            countryTimezones.zones[0];
                                                        const offset = moment
                                                            .tz(timezone)
                                                            .utcOffset();
                                                        if (offset) {
                                                            setDefaultUTC(
                                                                times.filter(
                                                                    (option) =>
                                                                        Number(option.value) ==
                                                                        offset / 60,
                                                                )[0],
                                                            );
                                                            setFieldValue(
                                                                'TimezoneOffset',
                                                                offset / 60,
                                                            );
                                                        }
                                                    }}
                                                    valueFunc={
                                                        values.Location?.Country &&
                                                            values?.Location?.Country.Id !== 0
                                                            ? values.Location?.Country?.Id
                                                            : null
                                                    }
                                                    options={countries.map((country) => {
                                                        return {
                                                            value: country.Id,
                                                            label: country.Name,
                                                        };
                                                    })}
                                                    disabled={
                                                        values.CreatedBy && id !== values.CreatedBy?.Id
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
                {/* <div>
                    <Title level={5} className="flex gap-1">
                        <EditOutlined />
                        Comments
                    </Title>
                    <div className="bg-gray-50 w-full p-4 flex flex-col gap-2 rounded-lg shadow-md">
                        <div
                            data-testid="leaveTypeDropdown"
                            className="w-full gap-2 grid grid-cols-12"
                        >
                            <div className="col-span-12 flex flex-col gap-2">
                                <TextArea
                                    rows={2}
                                    data-testid="desc"
                                    size="large"
                                    onChange={(e) =>
                                        setFieldValue('Description', e.target.value)
                                    }
                                    value={values.Description}
                                    placeholder="Comments"
                                    disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                                    style={{ color: 'black' }}
                                />
                            </div>
                        </div>

                    </div>

                </div> */}
            </div >
        </FormCard>
    )
}

export default LocationMeeting