import { FC } from 'react';
import { Timesheet } from '../../api-requests/Timesheet';

import StatisticsWrapper from '../../features/leave/components/StatisticsWrapper';
import HideComponent from '../../../shared/hideComponent/HideComponent';
import WidgetWrapper from './WidgetWrapper';
import { useFeature } from '../../features/feature/FeatureProvider';
import Header from '../layout/Header';
interface DashboardData {
  oneYearAgo: Timesheet[];
  oneMonthAgo: Timesheet[];
}

const DashboardPage: FC = () => {
  const { showFeature } = useFeature();
  return (
    <>
      {showFeature('TIMESHEET') && <WidgetWrapper />}
      <HideComponent keyVal={"LEAVES"}>
        <div>
          <h4>My Quotas</h4>
          <StatisticsWrapper />
        </div>
      </HideComponent>
    </>
  );
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <Header title="Dashboard">
        <DashboardPage />
      </Header>
    </>
  );
};

export { DashboardWrapper };
