import {
  Button,
  ConfigProvider,
  Select,
  Table,
  TableColumnsType,
  TableProps,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CarOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import '../../../../styles/ant-table.css'; // Your custom styles
import CreateMeetingModal from './CreateMeeting';
import moment from 'moment';

import { useMeetings } from '../provider/useMeetings';
import { initMeeting } from '../../../../utils/FormValidation/CreateAccountWizardHelper';
import useTableContext from '../../../table/provider/TableContext';
import {
  Attendees,
  Meeting,
  Trip,
  createTripFromMeeting,
  deleteMeetingById,
  fetchMeetings,
  getTripList,
} from '../../../../api-requests/Meeting';
import ParticipantsCell from '../../../../components/cells/ParticipantsCell';
import LocationCell from '../../../../components/cells/LocationCell';
import ActionCell from '../../../../components/cell/ActionCell';
import EmployeeSidebar from '../../../../components/employeeSidebar/EmployeeSidebar';
import { Link, useNavigate } from 'react-router-dom';

import TableExport from '../../../table/components/TableExport';
import { meetingsConfig } from './meetingsConfig';
import { SortObject, stringifySorting } from '../../../../utils/sortingParser';
import { useAuth } from '../../../auth';
import WarningModal from '../../../../components/modal/WarningModal';
import { AxiosError } from 'axios';
import useNotification from 'antd/es/notification/useNotification';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { LeaveColors } from '../../../../../shared/colors';
import dayjs from 'dayjs';
import TableFilters, {
  ExtendedColumnType,
} from '../../../../utils/TableFilters/TableFilters';
import { LeaveData } from '../../../../mock/Leave';
import PageWrapper from '../../../../components/wrapper/PageWrapper';
import Card from './Card';
import { ExtendedTableColumnType } from '../../../timesheet/components/TimesheetTable';
import MultipleBenefeciariesTable from '../../Trip/components/MultipleBenefeciariesTable';
import LoomSelect from '../../../../components/StyledComponents/LoomSelect';
import MultipleOrganisationTripCreation from './MultipleOrganisationTripCreation';
import DropDownMeetingSelection from './DropDownMeetingSelection';
const { Option } = Select;
const MeetingTable = () => {
  const { meetings, getMeeting, meetingsTotal, loading } = useMeetings();
  const { id } = useAuth();
  const { openNotification } = useNotifications();
  const [createModal, setCreateModal] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [meetingId, setMeetingId] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const [selectOrgModal, setSelectOrgModal] = useState(false);

  const [columns, setColumns] = useState<ExtendedTableColumnType<Meeting>[]>([
    {
      dataIndex: 'Name',
      default: true,
      key: 'Name',
      title: 'Title',
      sorter: { multiple: 1 },
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'Attendees',
      key: 'Participants',
      title: 'Participants',
      default: false,
      render: (cell) => <ParticipantsCell cell={cell} />,
      width: 130,
    },

    {
      dataIndex: 'Project',
      key: 'Project.Acronym',
      default: false,
      title: 'Project',
      sorter: { multiple: 2 },
      render: (cell) => <p className="text-sm font-semibold">{cell.Acronym}</p>,
      width: 120,
    },
    {
      key: 'From',
      title: 'Start Date',
      default: false,
      sorter: { multiple: 1 },
      width: 150,
      render: (cell) =>
        cell.AllDay ? (
          dayjs(cell.From).format('DD-MM-YYYY')
        ) : 'TimezoneOffset' in cell ? (
          <Tooltip
            title={
              cell.TimezoneOffset
                ? cell.TimezoneOffset > 0
                  ? `(+${cell.TimezoneOffset}:00 UTC)`
                  : `${cell.TimezoneOffset}:00 UTC`
                : dayjs().utcOffset() / 60 > 0
                  ? `+${dayjs().utcOffset() / 60}:00 UTC`
                  : `${dayjs().utcOffset() / 60}:00 UTC`
            }
          >
            {dayjs(cell.From)
              .utcOffset(Number(cell.TimezoneOffset))
              .format('DD-MM-YYYY HH:mm')}
          </Tooltip>
        ) : (
          dayjs(cell.From).local().format('DD-MM-YYYY HH:mm')
        ),
      defaultSortOrder: 'descend',
    },

    {
      key: 'To',
      title: 'End Date',
      default: false,
      sorter: { multiple: 1 },
      width: 150,
      render: (cell) =>
        cell.AllDay ? (
          dayjs(cell.To).format('DD-MM-YYYY')
        ) : 'TimezoneOffset' in cell ? (
          <Tooltip
            title={
              cell.TimezoneOffset
                ? cell.TimezoneOffset > 0
                  ? `(+${cell.TimezoneOffset}:00 UTC)`
                  : `${cell.TimezoneOffset}:00 UTC`
                : dayjs().utcOffset() / 60 > 0
                  ? `+${dayjs().utcOffset() / 60}:00 UTC`
                  : `${dayjs().utcOffset() / 60}:00 UTC`
            }
          >
            {dayjs(cell.To)
              .utcOffset(Number(cell.TimezoneOffset))
              .format('DD-MM-YYYY HH:mm')}
          </Tooltip>
        ) : (
          dayjs(cell.To).local().format('DD-MM-YYYY HH:mm')
        ),
    },
    {
      key: 'TimezoneOffset',
      title: 'Timezone Offset',
      width: 100,
      default: false,
      align: 'center',
      render: (cell) =>
        cell.TimezoneOffset && !cell.AllDay ?
          cell.TimezoneOffset >= 0
            ? `+${cell.TimezoneOffset}:00 UTC`
            : `${cell.TimezoneOffset}:00 UTC` : null
    },
    {
      width: 100,
      dataIndex: 'Type',
      default: false,
      title: 'Type',
      key: 'Type',
      render: (cell) => {
        switch (cell) {
          case 0: {
            return 'Review';
          }
          case 1: {
            return 'General Assembly';
          }
          case 2: {
            return 'Workshop';
          }
          case 3: {
            return 'Other';
          }
        }
      },
      align: 'center',
      sorter: { multiple: 1 },
    },
    {
      dataIndex: 'MeetingMode',
      title: 'Mode',
      key: 'MeetingMode',
      render: (cell) => {
        switch (cell) {
          case 0: {
            return 'Physical';
          }
          case 1: {
            return 'Online';
          }
          case 2: {
            return 'Hybrid';
          }
        }
      },
      width: 100,

      align: 'center',
      sorter: { multiple: 1 },
    },
    {
      dataIndex: 'Location',
      key: 'Location.Country',
      title: 'Country',
      render: (cell) => cell?.Country?.Name,
      sorter: { multiple: 1 },
      width: 100,
    },
    {
      dataIndex: 'Location',
      key: 'Location.City',
      title: 'City',
      render: (cell) => cell?.City,
      sorter: { multiple: 1 },
      width: 100,
    },

    {
      dataIndex: 'CreatedBy',
      key: 'Creator',
      title: 'Created By',
      default: false,
      render: (cell) => <p className="font-semibold">{cell.FullName}</p>,
      width: 150,
    },
    {
      key: 'actions',
      title: 'Actions',
      default: false,
      align: 'center',
      width: 75,
      render: (cell) => (
        <ActionCell
          data={cell}
          items={[
            cell.MeetingMode !== 1 && cell.CreatedBy.Id === id && extractUniqueOrganisation(cell).length !== cell.Trips.length
              ? {
                key: 'edit_trip',
                label: 'Create Trip',
                icon: <CarOutlined />,
                onClick: () => {
                  setMeetingId(cell.Id);
                  setSelectOrgModal(true);
                },
              }
              : null,
            {
              key: 'edit_meeting',
              label: cell.CreatedBy.Id === id ? 'Edit Meeting' : 'View Meeting',
              icon:
                cell.CreatedBy.Id === id ? <EditOutlined /> : <EyeOutlined />,
              onClick: () => {
                navigate('/apps/projects/meetings/' + cell.Id, {
                  state: { data: cell },
                });
              },
            },
            cell.Trips.length === 0 && id === cell.CreatedBy.Id
              ? {
                key: 'delete_meeting',
                label: 'Delete Meeting',
                icon: <DeleteOutlined />,
                onClick: () => {
                  setSelectedId(cell.Id);
                  setWarningOpen(true);
                },
              }
              : null,
          ]}

        />
      ),
      fixed: 'right',
    },
  ]);
  const navigate = useNavigate();
  const {
    pageSize,
    currentPage,
    handlePaginate,
    handleChangePageSize,
    handleSort,
    searchTerm,
    status,
    from,
    to,
    sort,
    employeeIds,
    handleTableChange,
  } = useTableContext();

  const extractUniqueOrganisation = (cell: any) => {
    // Extract organizations from attendees

    const organizations = cell.Attendees.map((item: any) => item.Attendee.Organisation);
    // Get unique organizations by Id
    const uniqueOrganizations = Array.from(new Set(organizations.map((org: any) => org.Id)))
      .map(id => {
        return organizations.find((org: any) => org.Id === id);
      });

    // Map over unique organizations and find the corresponding trip
    const organizationsWithTrips = uniqueOrganizations.map(org => {
      // Find a trip where the organization matches the Beneficiary's organisation
      const trip = cell.Trips.find((trip: any) => trip.Beneficiary && trip.Beneficiary.Organisation.Id === org?.Id);

      // Return the organization with the attached trip (if found)
      return {
        ...org,
        trip: trip || null // Attach trip or null if not found
      };
    }).filter(org => org !== null);

    return organizationsWithTrips;



  }

  const formatDate = (date: Date, timezoneOffset: number, allDay: boolean) => {
    if (allDay) return dayjs(date).format('DD-MM-YYYY');
    const formattedDate = dayjs(date).utcOffset(Number(timezoneOffset));
    return <Tooltip title={formatTimezoneOffset(timezoneOffset)}>{formattedDate.format('DD-MM-YYYY HH:mm')}</Tooltip>;
  };

  const formatTimezoneOffset = (offset: number) => (offset >= 0 ? `+${offset}:00 UTC` : `${offset}:00 UTC`);

  const formatMeetingType = (type: number) => {
    const types = ['Review', 'General Assembly', 'Workshop', 'Other'];
    return types[type] || 'Unknown';
  };

  const formatMeetingMode = (mode: number) => {
    const modes = ['Physical', 'Online', 'Hybrid'];
    return modes[mode] || 'Unknown';
  };

  const handleDeleteMeeting = async (id: number) => {
    try {
      await deleteMeetingById(id);
      setWarningOpen(false);
      getMeeting({
        pageSize: pageSize.value,
        pageIndex: currentPage - 1,
        handlePaginate,
        handleChangePageSize,
        handleSort,
        searchTerm,
        status,
        from,
        to,
        sort,
        employeeIds,
      });
      openNotification('topRight', {
        title: `Meeting`,
        description: `Meeting has been deleted successfully`,
        type: 'Success',
      });
    } catch (error) {
      console.error('Error on withdraw');
      if (error instanceof AxiosError) {
        setWarningOpen(false);
        openNotification('topRight', {
          title: `Leave`,
          description: error?.response?.data.error,
          type: 'Danger',
        });
      }
    }
  };



  return (
    <>
      <MultipleOrganisationTripCreation data={meetings} meetingId={meetingId} />
      <WarningModal
        title="Withdraw Meeting"
        isOpen={warningOpen}
        setIsOpen={setWarningOpen}
        footerButtons={[
          <Button key={0} onClick={() => setWarningOpen(false)}>Close</Button>,
          <Button
            key={1}
            onClick={() => {
              if (selectedId) handleDeleteMeeting(selectedId);
            }}
            style={{ background: LeaveColors[2].value, color: 'white' }}
          >
            Delete
          </Button>,
        ]}
        mainText="Are you sure you want to delete this project meeting? Your action will remove it permanently."
      />
      <PageWrapper>
        <TableFilters<Meeting>
          localStorageKey="MeetingTableColumns"
          addNavigate={() => navigate('/apps/projects/meetings/create')}
          dropdown={<DropDownMeetingSelection />}
          hasAdd={true}
          setIsCreateModal={setCreateModal}
          columns={columns}
          newColumns={columns}
          setNewColumns={setColumns}
          Export={
            <TableExport<Meeting>
              name="Meetings"
              data={meetings}
              config={meetingsConfig}
              fetchData={(pageSize: number) => {
                return fetchMeetings({
                  pageIndex: 0,
                  pageSize,
                  searchTerm,
                  status,
                  from,
                  to,
                  sorting: stringifySorting(sort),
                  personIds: employeeIds[0], //to be checked
                }).then((res) => res.Results);
              }}
            />
          }
          assigneesFilter={true}
        />

        <Table<Meeting>
          columns={columns}
          dataSource={meetings}
          size={'middle'}
          scroll={{ x: 1200 }}
          pagination={{
            size: 'default',
            position: ['bottomCenter'],
            current: currentPage,
            pageSize: pageSize?.value,
            total: meetingsTotal,
            pageSizeOptions: ['10', '25', '50'],
          }}
          sortDirections={['ascend', 'descend']}
          onChange={handleTableChange}
          loading={loading}
          rowKey={'Id'}
          className="custom-antd-header-bg"
        />
      </PageWrapper>
    </>
  );
};

export default MeetingTable;
