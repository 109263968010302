import { Tooltip, Typography } from 'antd';

type Props = {
    cell: any;
};

const LocationCell = ({ cell }: Props) => {
    return (
        <div style={{ fontSize: '12px' }}>
            <Typography>
                {cell.IsVirtual ? (
                    cell.VirtualMeetingLink.length > 30 ? (
                        <Tooltip
                            title={cell.VirtualMeetingLink}
                            style={{ cursor: 'pointer' }}
                        >
                            <a target="_blank" href={cell.VirtualMeetingLink}>
                                {cell.VirtualMeetingLink.slice(0, 30)}
                            </a>
                        </Tooltip>
                    ) : (
                        cell.VirtualMeetingLink
                    )
                ) : (
                    cell.Location?.Name && cell.Location.Name + "," + (cell.Location?.Address && cell.Location.Address) + "," + (cell.Location?.City && cell.Location.City) + "," + (cell.Location?.Country && cell.Location.Country.Name)
                )}
            </Typography>
        </div>
    );
};

export default LocationCell;
