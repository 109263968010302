import { FC, ReactNode, ReactPortal, useMemo, useState } from 'react';
import TimesheetContext from './timesheetContext';
import {
  Timesheet,
  fetchTimesheet,
  fetchTimesheetPerWP,
} from '../../../api-requests/Timesheet';
import { FetchTimesheetOptions } from '../../../@types/FetchOptions';
import { TimesheetPerWP } from '../../../@types/Timesheet';

type TUserProviderProps = {
  children: ReactNode | ReactPortal;
};

export const TimesheetProvider: FC<TUserProviderProps> = ({ children }) => {
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [timesheetsTotal, setTimesheetsTotal] = useState<number>(0);
  const [timesheetsPerWP, setTimesheetsPerWP] = useState<TimesheetPerWP[]>([]);
  const [timesheetWPTotal, setTimesheetsWPTotal] = useState<number>(0);
  const getTimesheets = async ({ ...reqProps }: FetchTimesheetOptions) => {
    setLoading(true);
    try {
      const timesheetResponse = await fetchTimesheet(reqProps);
      setTimesheets(timesheetResponse.Results);
      setTimesheetsTotal(timesheetResponse.TotalResults);
    } catch (error) {
      console.error('Error fetching timesheets:', error);
    } finally {
      setLoading(false);
    }
  };
  const getTimesheetsPerWP = async ({ ...reqProps }: FetchTimesheetOptions) => {
    setLoading(true);
    try {
      const timesheetResponse = await fetchTimesheetPerWP(reqProps);
      setTimesheetsPerWP(timesheetResponse.Results);
      setTimesheetsWPTotal(timesheetResponse.TotalResults);
    } catch (error) {
      console.error('Error fetching timesheets per wp:', error);
    } finally {
      setLoading(false);
    }
  };
  const contextValue = useMemo(
    () => ({
      timesheets,
      getTimesheets,
      timesheetsTotal,
      loading,
      timesheetsPerWP,
      getTimesheetsPerWP,
      timesheetWPTotal,
    }),
    [timesheets, timesheetsTotal, loading, timesheetWPTotal, timesheetsPerWP],
  );
  return (
    <TimesheetContext.Provider value={contextValue}>
      {children}
    </TimesheetContext.Provider>
  );
};
