import { FC, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { Timesheet } from '../../api-requests/Timesheet';
import Chart from 'react-apexcharts';
import useChartHeight from './useChartHeight';
import Loader from '../Loader';
import { Switch } from 'antd';

type Props = {
  className: string;
  data: Timesheet[];
  loading: boolean;
};

export interface TimesheetEntry {
  Id: number;
  HoursWorked: number;
  WPId: number;
  WPNumber: string;
  WPTitle: string;
  ProjectAcronym: string;
  FQN: string;
  WPStartDate?: string;
  WPEndDate?: string;
}
const ChartsWidget11: FC<Props> = ({ className, data = [], loading }) => {
  const { chartRef, height } = useChartHeight();

  const [isProjectOnly, setIsProjectOnly] = useState(true);
  const formatedData = {
    ...genPieOptions(data, isProjectOnly),
    responsive: [
      {
        breakpoint: 1400,
        options: {
          legend: {
            position: 'right',
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 1024,
        options: {
          legend: {
            position: 'right',
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5 p-4 flex flex-row justify-between items-center">
        <h3 className="card-title flex align-items-start flex-col">
          <span className="card-label fs-3 mb-1">Last Month</span>
          <span className="text-gray-400/[.5]  text-sm">
            Project hours / Month (144h / Month)
          </span>
        </h3>

        {/* begin::Toolbar */}
        <div className="card-toolbar" data-kt-buttons="true">
          <div className="form-check flex flex-row items-center form-switch form-check-custom form-check-solid gap-3">
            <label className="form-check-label">Projects only</label>
            <Switch
              className="w-2"
              checked={isProjectOnly}
              onChange={() => setIsProjectOnly(!isProjectOnly)}
            />
          </div>
        </div>
      </div>
      {/* end::Toolbar */}

      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body text-center">
        {/* begin::Chart */}
        {loading ? (
          <Loader />
        ) : (
          <div
            ref={chartRef}
            style={{ height }}
            id="kt_charts_widget_11_chart"
            className="card-rounded-bottom"
          >
            <Chart
              height={height}
              series={formatedData?.series}
              options={formatedData}
              type="donut"
            />
          </div>
        )}
      </div>
      {/* end::Body */}
    </div>
  );
};

export default ChartsWidget11;

type ProjectWP = TimesheetEntry & {
  name: string;
};

function genPieOptions(data: Timesheet[], isProjectOnly: boolean): ApexOptions {
  const monthHours = 144;

  const series: number[] = extractProjectWP(data, isProjectOnly).map(
    (projectWP) => formatData(projectWP, data, isProjectOnly),
  );
  const remainingHours = Math.max(
    monthHours - series.reduce((pre, cur) => pre + cur, 0),
    0,
  );
  const projectsSeries = [...series, remainingHours];
  const projectsLabel = extractProjectWP(data, isProjectOnly).map(
    (x) => x.name,
  );
  projectsLabel.push('Unclaimed Hours');

  return {
    series: projectsSeries || [],
    labels: projectsLabel,
  };
}

function extractProjectWP(
  data: Timesheet[],
  isProjectOnly: boolean,
): ProjectWP[] {
  const projectWPSet = new Set<string>();
  const uniqueProjectWPs: ProjectWP[] = [];

  data.forEach((timesheet) => {
    timesheet.TimesheetEntries.forEach(({ ...entry }) => {
      const projectName = isProjectOnly
        ? entry.ProjectAcronym
        : `${entry.ProjectAcronym} - ${entry.WPNumber}`;

      if (!projectWPSet.has(projectName)) {
        projectWPSet.add(projectName);
        uniqueProjectWPs.push({
          ...entry,
          name: projectName,
        } as unknown as ProjectWP);
      }
    });
  });

  return uniqueProjectWPs;
}

function formatData(
  projectWP: ProjectWP,
  data: Timesheet[],
  isProjectOnly: boolean,
): number {
  const totalHours = data.reduce((total, timesheet) => {
    const hoursWorked = timesheet.TimesheetEntries.reduce((acc, entry) => {
      const matchesProject = isProjectOnly
        ? entry.ProjectAcronym === projectWP.ProjectAcronym
        : entry.ProjectAcronym === projectWP.ProjectAcronym &&
          entry.WPId === projectWP.WPId;
      return acc + (matchesProject ? entry.HoursWorked : 0);
    }, 0);
    return total + hoursWorked;
  }, 0);
  return totalHours;
}
