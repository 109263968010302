import moment from 'moment-timezone';


export const fetchCitiesWithUTCOffset = (mainstreamCities: string[], minOffset: number, maxOffset: number) => {
    const offsetCitiesMap: string[][] = [];
    mainstreamCities.forEach(city => {
        const cityName = city.split('/').pop();
        const offsetInMinutes = moment.tz(city).utcOffset();
        const offsetInHours = offsetInMinutes / 60;

        if (offsetInHours >= minOffset && offsetInHours <= maxOffset) {
            if (!offsetCitiesMap[offsetInHours]) {
                offsetCitiesMap[offsetInHours] = []; // Initialize it as an array of strings
            }
            offsetCitiesMap[offsetInHours].push(cityName as string); // Ensure cityName is a string
        }
    });

    const citiesWithOffset: { offset: number, timezones: string[] }[] = Object.entries(offsetCitiesMap).map(([offset, timezones]) => ({
        offset: parseFloat(offset),
        timezones
    }));
    const arr: { value: string, label: string }[] = []
    for (let i = -12; i <= 12; i++) {
        citiesWithOffset.forEach((city) => {
            if (Number(city.offset) === i) {
                const offset = i >= 0 ? `${city.offset}` : i.toString();
                const label = `(UTC ${offset}:00) ${city?.timezones.join(',')}`;
                arr.push({ value: offset, label: label })
            }
        })
    }
    return arr;
};

export const utcLocal = (date: Date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() - timezoneOffset);
    const localISODate = adjustedDate.toISOString().split('T')[0] + 'T00:00:00.000Z';

    return localISODate;
}