import { useState } from 'react';
import { Modal, Radio, Select, Typography } from 'antd';
import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { useNotifications } from '../../../permissions/components/timesheet/Notification-service';

type Paths<T> = T extends object
  ? {
    [K in keyof T]-?: T[K] extends Array<infer U>
    ? `${Exclude<K, symbol>}.${Paths<U>}`
    : `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`;
  }[keyof T]
  : '';

export type ExportConfig<T> = {
  key: Paths<T> | Paths<T>[];
  label: string;
  transformer?: (params: unknown) => string;
}[];

type Props<T> = {
  name: string;
  config: ExportConfig<T>;
  fetchData: (pageSize: number) => Promise<T[]>;
  data: T[];
  testId?: string;
};

interface Option {
  key: string;
  value: number;
}
const OPTIONS = [
  { key: '1', value: 1 },
  { key: '10', value: 10 },
  { key: '50', value: 50 },
  { key: '100', value: 100 },
];
const TableExport = <T extends object>({
  name,
  config,
  fetchData,
  data,
  testId,
}: Props<T>) => {
  const { openNotification } = useNotifications();

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [customExport, setCustomExport] = useState(false);
  const [entries, setEntries] = useState<number>(OPTIONS[0].value);

  const handleClose = () => {
    setShow(false);
  };

  const getNestedValue = (row: T, segments: string[]): string => {
    // console.log('EXp nk: ', row, segments);
    if (segments.length === 1) {
      return row[segments[0] as keyof unknown];
    }

    const [currentSegment, ...remainingSegments] = segments;

    // console.log(
    //   'EXP nested: ',
    //   currentSegment,
    //   row[currentSegment as keyof unknown],
    //   remainingSegments,
    // );

    if (currentSegment in row) {
      return getNestedValue(
        row[currentSegment as keyof unknown],
        remainingSegments,
      );
    }

    return 'Unprocessable';
  };

  const parseAndExportEntries = (rows: T[]) => {
    const parsedEntries = rows.map((row) => {
      const result: { [key: string]: unknown } = {};
      let value = '';

      // console.log('Exp row', row);

      config.forEach((item) => {
        // console.log('Exp item', item);
        let keys: string[];

        if (Array.isArray(item.key)) {
          keys = item.key;

          // console.log('Exp keys', keys);

          if (item.transformer) {
            value = item.transformer(row);
            result[item.label] = value;
          } else {
            result[item.label] = 'Unprocessable';
          }
        } else {
          keys = [item.key];

          // console.log('Exp keys', keys);

          keys.forEach((key) => {
            // console.log('Exp key', key);

            const segments = key.split('.');
            // console.log('Exp segments', segments);

            value = getNestedValue(row, segments);

            if (item.transformer) {
              value = item.transformer(value);
            }
          });

          result[item.label] = value;
        }
      });

      return result;
    });

    console.log('Ex: ', parsedEntries);

    // CSV
    if (parsedEntries.length === 0) {
      handleClose();
      setShowError(true);
      return;
    }

    const headers = Object.keys(parsedEntries[0]).join(',');
    const csvRows = parsedEntries
      .map((obj: any) =>
        Object.values(obj)
          .map((val) => JSON.stringify(val))
          .join(','),
      )
      .join('\n');
    const csvData = `${headers}\n${csvRows}`;

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}_${(currentDate.getMonth() + 1).toString().padStart(2, '0')}_${currentDate.getFullYear()}`;

    a.download = name + '_' + formattedDate + '.csv';
    a.click();
    openNotification("topRight", {
      title: `Export`,
      description: `Export has been downloaded.`,
      type: 'Success',
    });
    setShow(false);
    window.URL.revokeObjectURL(url);
  };

  const handleExport = () => {
    // console.log('Export vars', keys, entries);

    customExport
      ? fetchData(entries).then((res) => parseAndExportEntries(res))
      : parseAndExportEntries(data);
  };

  return (
    <div data-testid="export">
      <Modal
        centered
        title={<span className="text-red-500 text-lg">CSV Error!</span>}
        open={showError}
        onCancel={() => setShowError(false)}
        onOk={() => setShowError(false)}
      >
        There are no entries to be exported!
      </Modal>
      <div data-testid="exportOptionsModal">
        <Modal
          centered
          title={<span className="text-lg">Export Options</span>}
          open={show}
          onCancel={handleClose}
          onOk={handleExport}
        >
          <div
            data-testid="exportTypeInput"
            className="flex flex-col gap-2 p-2 mt-4"
          >
            <Radio
              id="exportAll"
              value="all"
              checked={!customExport}
              onChange={() => setCustomExport(false)}
            >
              Export Current Page
            </Radio>
            <Radio
              id="exportCustom"
              value="custom"
              checked={customExport}
              onChange={() => setCustomExport(true)}
            >
              Export Custom Number of Rows
            </Radio>
            {customExport && (
              <div data-testid="numberOfRowsInput" className="mb-2">
                <Title level={5}>Number of rows to export:</Title>
                {/* <label className="form-label mb-4">
                        Number of Rows to Export
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg form-control-solid"
                        name="numberOfRows"
                        min="1"
                        value={entries}
                        onChange={(e) =>
                          setEntries(parseInt(e.target.value, 10))
                        }
                      /> */}
                {/* <StyledSelect<Option>
                      selectProps={{
                        onChange: (newValue) => {
                          const entry = newValue as Option;
                          setEntries(entry.value);
                        },
                        value: { key: entries.toString(), value: entries },
                        getOptionValue: (e) => e.key,
                        getOptionLabel: (e) => e.key,
                        options: OPTIONS,
                      }}
                    /> */}
                <Select
                  style={{
                    width: '100%',
                  }}
                  value={entries}
                  onChange={(newValue) => {
                    const entry = newValue;
                    setEntries(entry);
                  }}
                  options={OPTIONS}
                />
              </div>
            )}
          </div>
        </Modal>
      </div>
      <Button
        data-testid={testId}
        type="dashed"
        size={"small"}
        icon={<DownloadOutlined />}
        onClick={() => (data.length > 0 ? setShow(true) : setShowError(true))}
      />
    </div>
  );
};

export default TableExport;
