import axios from "axios";
import envConfig from "../../enviroment/enviroment";
import { FetchTimesheetOptions } from "../@types/FetchOptions";



const API_URL = envConfig.getEnvKey('APP_DEV_URL');
const GET_COUNTRIES = `${API_URL}/geodata/countries`;
export const getCountries = async ({
    searchTerm = '',
    from = '',
    to = '',
    pageSize = 250,
    pageIndex = 0,
    status = '0,1,2,3',
    sorting = '',
    orgId = '0',
    employeeId = 0,
}: FetchTimesheetOptions) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        from,
        to,
        status,
        sorting,
        orgId: String(orgId),
        employeeId: employeeId.toString(),
    });

    try {
        const response = await axios.get<any>(`${GET_COUNTRIES}?${params.toString()}`);
        return response.data.Results;
    } catch (error) {
        console.error('Error getting countries:', error);
        throw error;
    }
}