import { useState } from "react";
import { useNotifications } from "../../../../permissions/components/timesheet/Notification-service";
import { useAuth } from "../../../auth";
import { redirect, useNavigate } from "react-router-dom";
import useTableContext from "../../../table/provider/TableContext";
import { Button, Table, TableColumnsType, Tooltip } from "antd";
import EmployeeSidebar from "../../../../components/employeeSidebar/EmployeeSidebar";
import { useTrip } from "../provider/useTrip";
import DateFormatCell from "../../../../components/cells/DateFormatCell";
import { Meeting, Trip } from "../../../../api-requests/Meeting";
import LocationCell from "../../../../components/cells/LocationCell";
import ParticipantsCell from "../../../../components/cells/ParticipantsCell";
import { EditOutlined, DeleteOutlined, EyeOutlined, CarOutlined } from '@ant-design/icons';
import ActionCell from "../../../../components/cell/ActionCell";
import { AxiosError } from "axios";
import WarningModal from "../../../../components/modal/WarningModal";
import { LeaveColors } from "../../../../../shared/colors";
import dayjs from "dayjs";
import TravellersCell from "../../../../components/cells/TravellersCell";
import TableFilters, { ExtendedColumnType } from "../../../../utils/TableFilters/TableFilters";
import { ExtendedTableColumnType } from "../../../timesheet/components/TimesheetTable";
import LeaveStatusCell from "../../../../components/cells/LeaveStatusCell";
import StatusCell from "../../../../components/cells/StatusCell";


const TripTable = () => {
    const { id } = useAuth();
    const [warningOpen, setWarningOpen] = useState(false);
    const [activeTrip, setActiveTrip] = useState(0);
    const [columns, setColumns] = useState<ExtendedTableColumnType<Trip>[]>([
        {
            dataIndex: 'Title',
            key: 'Title',
            title: 'Title',
            default: true,
            sorter: { multiple: 2 },
            align: 'start',
            render: (cell) => <span>{cell}</span>,
            width: 300,
        },
        {
            dataIndex: 'Status',
            key: 'FinalStatus',
            title: 'Status',
            default: false,
            render: (cell) => <StatusCell status={cell} />,
            align: 'center',
            width: 150,
        },
        {
            dataIndex: 'Project',
            key: 'Project.Acronym',
            title: 'Project',
            default: true,
            sorter: { multiple: 2 },
            align: 'start',
            render: (cell) => <span className="font-semibold">{cell.Acronym}</span>,
            width: 150,
        },
        {
            key: 'LatestArrivalTime',
            title: 'Latest Arrival Time',
            align: 'center',
            default: false,
            sorter: { multiple: 1 },
            width: 200,
            defaultSortOrder: 'descend',
            render: (cell) => (
                "TimezoneOffset" in cell ? <Tooltip title={(cell.TimezoneOffset ? cell.TimezoneOffset > 0 ? `(+${cell.TimezoneOffset}:00 UTC)` : `${cell.TimezoneOffset}:00 UTC` : dayjs().utcOffset() / 60 > 0 ? `+${dayjs().utcOffset() / 60}:00 UTC` : `${dayjs().utcOffset() / 60}:00 UTC`)}>
                    {dayjs(cell.LatestArrivalTime).utcOffset(
                        Number(cell.TimezoneOffset)
                    ).format('DD-MM-YYYY HH:mm')}
                </Tooltip> : ((dayjs(cell.LatestArrivalTime).local().format('DD-MM-YYYY HH:mm'))
                ))
        },
        {
            key: 'EarliestDepartureTime',
            title: 'Earliest Departure Time',
            align: 'center',
            default: false,
            width: 200,
            sorter: { multiple: 1 },
            render: (cell) => (
                "TimezoneOffset" in cell ? <Tooltip title={(cell.TimezoneOffset ? cell.TimezoneOffset > 0 ? `(+${cell.TimezoneOffset}:00 UTC)` : `${cell.TimezoneOffset}:00 UTC` : dayjs().utcOffset() / 60 > 0 ? `+${dayjs().utcOffset() / 60}:00 UTC` : `${dayjs().utcOffset() / 60}:00 UTC`)}>
                    {dayjs(cell.EarliestDepartureTime).utcOffset(
                        Number(cell.TimezoneOffset)
                    ).format('DD-MM-YYYY HH:mm')}
                </Tooltip> : ((dayjs(cell.EarliestDepartureTime).local().format('DD-MM-YYYY HH:mm'))
                ))
        },
        {
            key: 'TimezoneOffset',
            title: 'Timezone Offset',
            align: 'center',
            default: false,
            sorter: { multiple: 1 },
            ellipsis: true,
            width: 150,
            render: (cell) => (cell.TimezoneOffset ? cell.TimezoneOffset > 0 ? `+${cell.TimezoneOffset}:00 UTC` : `${cell.TimezoneOffset}:00 UTC` : dayjs().utcOffset() / 60 > 0 ? `+${dayjs().utcOffset() / 60}:00 UTC` : `${dayjs().utcOffset() / 60}:00 UTC`)
        },
        {
            key: 'Destination',
            title: 'Destination',
            default: false,
            render: (cell) => <LocationCell cell={cell} />,
            width: 150,
        },
        {
            key: 'Beneficiary',
            title: 'Beneficiary',
            default: false,
            dataIndex: 'Beneficiary',
            render: (cell) => (cell?.Organisation.BusinessName),
            width: 150,
            ellipsis: true
        },
        {
            key: 'Purpose',
            title: 'Purpose',
            default: false,
            dataIndex: 'Purpose',
            width: 150,
            ellipsis: true
        },
        {
            dataIndex: 'Travellers',
            key: 'Participants',
            default: false,
            title: 'Travellers',
            render: (cell) => <TravellersCell cell={cell} />,
            width: 150,
        },
        {
            dataIndex: 'CreatedBy',
            key: 'CreatedBy',
            title: 'Created By',
            render: (cell) => <span className="font-semibold">{cell.FullName}</span>,
            width: 150,

        },
        {
            key: 'Sponsor',
            title: 'Sponsor',
            default: false,
            dataIndex: 'Beneficiary',
            render: (cell) => (cell?.Sponsor),
            width: 150,
            ellipsis: true
        },
        {
            dataIndex: 'CreatedOn',
            key: 'CreatedOn',
            title: 'Created On',
            render: (cell) => dayjs(cell).local().format('DD-MM-YYYY HH:mm'),
            width: 150,
        },
        // {
        //     dataIndex: 'Purpose',
        //     key: 'Creator',
        //     title: 'Purpose',
        //     ellipsis: true,
        //     width: 150,
        // },
        {
            width: 100,
            key: 'actions',
            default: false,
            title: 'Actions',
            render: (cell) => (
                <ActionCell
                    data={cell}
                    items={[
                        {
                            key: 'edit_trip',
                            label: cell.CreatedBy.Id === id ? 'Edit Trip' : 'View Trip',
                            icon: cell.CreatedBy.Id === id ? <EditOutlined /> : <EyeOutlined />,
                            onClick: () => {
                                navigate('/apps/projects/meetings/trip/' + cell.Id, { state: { data: cell, view: true, } })
                            }
                        },
                        {
                            key: 'delete_trip',
                            label: dayjs(cell.EarliestDepartureTime).isAfter(new Date()) ? cell.CreatedBy.Id === id ? cell.Status === 0 ? "Delete Trip" : 'Cancel Trip' : '' : "You cannot delete past trips",
                            icon: <DeleteOutlined />,
                            onClick: () => { setWarningOpen(true); setActiveTrip(cell.Id) },
                            disabled: dayjs(cell.EarliestDepartureTime).isBefore(new Date()) || cell.CreatedBy.Id !== id ? true : false
                        }
                    ]}
                // actions={[
                //     id === cell.CreatedBy?.Id ? <div className='flex gap-2' key={cell.Id}>
                //         <Tooltip title="Edit Trip" key={cell.Id + "_edit"}>
                //             <Button
                //                 onClick={() => {
                //                     navigate('/apps/projects/meetings/trip/' + cell.Id, { state: { data: cell } })
                //                 }}
                //                 size="small"
                //                 data-testid={`cloneTimesheetBtn-${cell.Id}`}
                //                 icon={<EditOutlined />}
                //             />

                //         </Tooltip>
                //         <Tooltip title={dayjs(cell.EarliestDepartureTime).isAfter(new Date()) ? "Delete Trip" : 'You cannot delete past trips'} key={cell.Id + '_delete'} className='gap-2 flex'>
                //             <Button
                //                 disabled={!dayjs(cell.EarliestDepartureTime).isAfter(new Date())}
                //                 onClick={() => { setWarningOpen(true); setActiveTrip(cell.Id) }}
                //                 size="small"
                //                 icon={<DeleteOutlined />}
                //             />
                //         </Tooltip >

                //     </div> :
                //         <Tooltip title="View Trip" key={cell.Id + '_delete'} className='gap-2 flex'>
                //             <Button
                //                 onClick={() => {
                //                     navigate('/apps/projects/meetings/trip/' + cell.Id, { state: { data: cell } })
                //                 }}
                //                 size="small"
                //                 icon={<EyeOutlined />}
                //             />
                //         </Tooltip >
                // ]
                // }
                />
            ),
            fixed: 'right',
            align: 'center',
        },
    ])
    const { trips, tripsTotal, loading, getTrip, handleDeleteTripById } = useTrip();
    const { openNotification } = useNotifications();
    const navigate = useNavigate();
    const {
        pageSize,
        currentPage,
        handleTableChange
    } = useTableContext();
    const handleDelete = async () => {
        try {
            await handleDeleteTripById(activeTrip);
            openNotification("topRight", {
                title: `Trip`,
                description: `Trip deleted successfully`,
                type: 'Success',
            })
            setWarningOpen(false)
        } catch (error) {
            if (error instanceof AxiosError) {
                const txtErr = error?.response?.data?.error;
                openNotification('topRight', {
                    title: `Trip`,
                    description: txtErr,
                    type: 'Danger',
                });
                setWarningOpen(false)
            }
        }
    }
    return (
        <>
            <WarningModal
                title="Delete Trip"
                isOpen={warningOpen}
                setIsOpen={setWarningOpen}
                footerButtons={[
                    <Button key={0} onClick={() => setWarningOpen(false)}>Close</Button>,
                    <Button
                        key={1}
                        onClick={() => handleDelete()}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Delete
                    </Button>,
                ]}
                mainText="Are you sure you wish to delete this trip? All information regarding this trip will be permanently removed."
            />
            <TableFilters<Trip>
                localStorageKey="TripTableColumns"
                addNavigate={() => navigate("/apps/projects/meetings/trip/create")}
                hasAdd={true}
                setIsCreateModal={() => { }}
                columns={columns}
                newColumns={columns}
                setNewColumns={setColumns}
                assigneesFilter={true}
                hasStatus={true}
            />
            <Table<Trip>
                columns={columns}
                dataSource={trips}
                size={'middle'}
                scroll={{ x: 1200 }}
                pagination={{
                    size: 'default',
                    position: ['bottomCenter'],
                    current: currentPage,
                    pageSize: pageSize?.value,
                    total: tripsTotal,
                    pageSizeOptions: ['10', '25', '50'],
                }}
                sortDirections={['ascend', 'descend']}
                onChange={handleTableChange}
                loading={loading}
                rowKey={'Id'}
                className='custom-antd-header-bg'
            />
        </>
    );
};

export default TripTable;
