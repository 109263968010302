import { Button, DatePicker, Drawer, Input, List, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { initMeeting } from '../../../../utils/FormValidation/CreateAccountWizardHelper';
import { Meeting, fetchMeetingById } from '../../../../api-requests/Meeting';
import { ErrorMessage } from 'formik';
import {
    FormOutlined,
    EnvironmentOutlined,
    FileAddOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    UploadOutlined,
    InboxOutlined,
    EyeOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import { getFile } from '../../../../api-requests/Upload';
import AttendeesMeeting from './FormComponents/AttendeesMeeting';
import AvatarCell from '../../../../components/cells/AvatarCell';
import TravellersCell from '../../../../components/cells/TravellersCell';
type Props = {
    setCollapsed: (d: boolean) => void,
    collapsed: boolean,
    data: Meeting,
}

const MeetingDrawer = ({ setCollapsed, collapsed, data }: Props) => {

    const truncateFileName = (name: string) => {
        if (name) {
            const splitName = name.split("_")[1];
            return splitName.length > 15 ? splitName.substring(0, 15) + '...' : splitName;
        }

    };
    const handlePreview = async (file: any) => {
        await getFile(file.Id).then((res) => {
            const fileBlob = new Blob([res.data]);
            const fileURL = URL.createObjectURL(fileBlob);
            // Infer file type from the extension
            const fileName = file.FileName.toLowerCase();
            const isPdf = fileName.endsWith('.pdf');
            const isImage = fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png') || fileName.endsWith('.gif');

            const previewWindow = window.open();
            if (previewWindow) {
                if (isPdf) {
                    const fileBlob = new Blob([res.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(fileBlob);
                    previewWindow.location.href = fileURL;
                } else if (isImage) {
                    previewWindow.document.write(`<img src="${fileURL}" style="width: 100%; height: 100%;" />`);
                } else {
                    previewWindow.document.write(`<p>Unsupported file type: ${fileName.split('.').pop()}</p>`);
                }
            }
        });
    };
    return (
        <Drawer
            width={400}

            title="Meeting Details"
            onClose={() => setCollapsed(false)}
            open={collapsed}
        >
            <div
                data-testid="drawerContent"
                id="example-collapse-text"
                style={{
                    gap: 4,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 5,
                    paddingRight: 5,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className="flex justify-center flex-col w-full gap-2">
                    <div
                        data-testid="leaveTypeDropdown"
                        className="w-full flex gap-2 flex-col"
                    >
                        <div>
                            <label className="form-label">Title</label>
                        </div>
                        <Input
                            disabled={true}
                            name="Name"
                            placeholder="Enter title"
                            prefix={<FormOutlined />}
                            value={data.Name}
                            style={{ color: 'black' }}
                        />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <div className="w-full flex gap-2 flex-col">
                            <div>
                                <label className="form-label">Project</label>
                            </div>
                            <Select
                                disabled={true}

                                style={{ color: 'black' }}
                                placeholder="Type project..."
                                showSearch
                                value={
                                    data.Project.Id !== 0 ? data.Project.Acronym : undefined
                                }
                                filterOption={false}
                            />

                        </div>
                        <div
                            data-testid="commentInput"
                            className="flex flex-row   col-span-4"
                            style={{ alignItems: 'center', gap: 12, flex: '1 1 0' }}
                        >
                            <div className="flex flex-col w-full gap-2">
                                <label>Start Date</label>
                                <DatePicker
                                    style={{ color: 'black', width: '100%' }}
                                    disabled={true}
                                    allowClear={false}

                                    className="cursor-pointer"

                                    format={
                                        data.AllDay ? 'DD MMM YYYY' : 'DD MMM YYYY HH:mm'
                                    }
                                    use12Hours={false}
                                    value={
                                        dayjs(data.From).utcOffset(data.TimezoneOffset)
                                    }

                                />
                            </div>

                        </div>
                        <div
                            data-testid="commentInput"
                            className="flex flex-row w-full col-span-4"
                            style={{ alignItems: 'center', gap: 12, flex: '1 1 0' }}
                        >
                            <div className="flex w-full flex-col gap-2">
                                <div>
                                    <label>End Date</label>
                                </div>
                                <DatePicker
                                    disabled={true}

                                    allowClear={false}
                                    use12Hours={false}
                                    minDate={dayjs(data.From).add(30, 'minutes')}

                                    style={{ color: 'black', width: '100%' }}
                                    format={
                                        data.AllDay ? 'DD MMM YYYY' : 'DD MMM YYYY HH:mm'
                                    }
                                    className="cursor-pointer"
                                    value={
                                        dayjs(data.To).utcOffset(data.TimezoneOffset)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex w-full flex-col gap-2">
                            <div>
                                <label>Timezone</label>
                            </div>
                            <Select
                                disabled={true}
                                style={{ color: 'black' }}
                                placeholder="Add timezone"
                                value={data.TimezoneOffset > 0 ? "+" + data.TimezoneOffset : "-" + data.TimezoneOffset}
                            />
                        </div>
                        <div
                            data-testid="leaveTypeDropdown"
                            className="w-full flex gap-2 flex-col"
                        >
                            <div>
                                <label className="form-label">Mode</label>
                            </div>
                            <Select
                                disabled={true}
                                optionFilterProp="children"
                                placeholder="Type..."
                                defaultValue={0}
                                value={
                                    data.MeetingMode !== 0
                                        ? data.MeetingMode
                                        : { id: 0, value: 'Physical' }
                                }

                                filterOption={false}
                                options={[
                                    { value: 0, label: 'Physical' },
                                    { value: 1, label: 'Online' },
                                    { value: 2, label: 'Hybrid' },
                                ]}
                            />
                        </div>
                        <div
                            data-testid="leaveTypeDropdown"
                            className="w-full flex gap-2 flex-col"
                        >
                            <div>
                                <label className="form-label">Type</label>
                            </div>
                            <Select
                                disabled={true}
                                optionFilterProp="children"
                                placeholder="Type..."
                                defaultValue={0}
                                value={
                                    data.Type !== 0
                                        ? data.Type
                                        : { id: 0, value: 'Review' }
                                }
                                filterOption={false}
                                options={[
                                    { value: 0, label: 'Review' },
                                    { value: 1, label: 'General Assembly' },
                                    { value: 2, label: 'Workshop' },
                                    { value: 3, label: 'Other' },
                                ]}
                            />
                        </div>
                        {data.Attendees.length > 0 && (<div className="col-span-12 flex flex-col gap-2">
                            <div>
                                <label className="form-label">Attendees</label>
                            </div>

                            <List
                                itemLayout="horizontal"
                                dataSource={data.Attendees}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<a href="https://ant.design">{item.Attendee.Person.FullName}</a>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>)}
                        <div className="col-span-12 flex flex-col gap-2">
                            <div>
                                <label className="form-label">Comments</label>
                            </div>
                            <TextArea
                                disabled={true}
                                rows={2}
                                data-testid="desc"
                                size="large"

                                value={data.Description}
                                placeholder="Comments"

                                style={{ color: 'black' }}
                            />
                        </div>
                        <div className="file-list col-span-6 flex flex-col gap-4 h-[200px]">
                            <div>
                                <label className="form-label">Attachments</label>
                            </div>
                            {data.Attachments.map((file: any) => (
                                <div key={file.Id} className="file-list-item flex flex-row items-center justify-between bg-white p-4 rounded-lg border border-gray-200 shadow-md">
                                    <div key={file.uid} className="file-list-item flex flex-row items-center justify-between">
                                        <span>{truncateFileName(file.FileName)}</span>
                                        <div className="file-list-actions flex flex-row">
                                            <Tooltip title="Preview">
                                                <Button
                                                    type="link"
                                                    icon={<EyeOutlined />}
                                                    onClick={() => handlePreview(file)}
                                                />
                                            </Tooltip>

                                        </div>
                                    </div>
                                    <div>
                                        <Select
                                            disabled={true}
                                            optionFilterProp="children"
                                            placeholder="Type..."
                                            className='w-[150px]'
                                            defaultValue={0}
                                            value={
                                                file.Description !== ''
                                                    ? file.Description
                                                    : { id: 0, value: 'Meeting Agenda' }
                                            }
                                            filterOption={false}

                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default MeetingDrawer