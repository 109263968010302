import { ReactElement } from 'react';
import {
  TimeSheetTypedStatus,

} from '../../../app/api-requests/Timesheet';
import { Badge } from 'antd';
import './scss/LeaveStatusCell.scss'
import { TimesheetStatus } from '../../@types/Timesheet';
import { TimesheetColors } from '../../../shared/colors';
const StatusCell = (props: { status: TimesheetStatus }): ReactElement => {
  return (

    <div className='text-right' style={{ textAlign: 'center' }}>
      <Badge color={TimesheetColors[props.status].value} text={TimeSheetTypedStatus[props.status]} />
    </div>
  );
};

export default StatusCell;
