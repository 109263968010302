import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {
  Layout,
  theme,
  Grid,
} from 'antd';

import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const { Content, Footer } = Layout;
const { useBreakpoint } = Grid;




const MasterLayout: React.FC = () => {
  const { xs } = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);


  const {
    token: {
      colorBgContainer,
    },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>

      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <div
          className="flex flex-col "
          style={{
            marginLeft: collapsed ? (xs ? 0 : 100) : (xs ? 0 : 250),
            transition: 'margin 0.3s ease',
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Content
            style={{
              overflow: 'initial',
              paddingLeft: xs ? 5 : 20,
              paddingRight: xs ? 5 : 20,

            }}
          >
            <Outlet />
          </Content>
        </div>
        <Footer
          style={{
            textAlign: 'start',
            background: colorBgContainer,
            marginLeft: collapsed ? (xs ? 0 : 100) : (xs ? 0 : 225),
            transition: 'margin 0.3s ease',
          }}
        >
          <div className="text-gray-900 order-2 order-md-1">
            <span className="text-muted fw-semibold me-1">
              Copyright 2019-{new Date().getFullYear().toString()} © <a
                href="https://konnecta.io/"
                target="_blank"
                className="text-gray-800 text-hover-primary"
              >
                Konnecta Systems
              </a>. All
              Rights Reserved.
              {/* Copyright 2019-{new Date().getFullYear().toString()} &copy; All
              Rights Reserved. */}
            </span>

          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export { MasterLayout };
