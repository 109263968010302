import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../helpers';
import { generateUrl } from '../../../../utils/generateUrl';
import envConfig from '../../../../../enviroment/enviroment';
import { Button, Layout, Typography } from 'antd';
import CommonAlert from '../../../../components/alert/CommonAlert';
import UsernameAndPassword from './UsernameAndPassword';

const APP_AUTH_CALLBACK = envConfig.getEnvKey('APP_AUTH_CALLBACK');
const APP_AUTH_CLIENT_ID = envConfig.getEnvKey('APP_AUTH_CLIENT_ID');
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
interface propState {
  showMessage: boolean;
}
export const Login = () => {

  const [showErrorModal, setShowErrorModal] = useState(false);
  const location = useLocation();
  const { url } = generateUrl(
    APP_AUTH_CLIENT_ID,
    'id_token',
    'openid%20profile',
    APP_AUTH_CALLBACK,
    'fragment',
  );
  const handleMicrosoft = () => {
    window.location.href = url;
  };

  useEffect(() => {
    if (location.state) {
      const { showMessage } = location.state as propState;
      setShowErrorModal(showMessage);
    }
  }, []);
  return (
    <>
      {/* begin::Heading */}
      <Layout style={{ background: 'transparent' }} className='flex justify-center w-[300px]'>
        <CommonAlert
          show={showErrorModal}
          message={'Something went wrong with your credentials, try again.'}
          title={'User not found'}
        />
        <div className="mb-4 text-center">
          <Link to="/">
            <img
              alt="Logo"
              src={toAbsoluteUrl('media/logos/konnecta_logo_dark.png')}
              className="h-[75px]"
            />
          </Link>
        </div>
        <div className="text-center mb-4" data-testid="loginTest">
          <Typography className="text-gray-900 font-semibold mb-3 text-lg">Sign In</Typography>
          <div className="spinner"></div>
        </div>
        <div className="flex flex-col  gap-12  mb-9">
          {/* <UsernameAndPassword /> */}
          <div className=" flex justify-center w-full">
            <Button
              onClick={() => handleMicrosoft()}
              data-testid="microsoftBtn"
              type="text"
              className="border border-gray-400 text-gray-700 p-6 hover:bg-primary hover:text-white flex items-center justify-center whitespace-nowrap"            >
              <img
                alt="Logo"
                src={toAbsoluteUrl('media/loom/microsoft-5.svg')}
                className="h-[15px] me-3"
              />
              Sign in with Microsoft
            </Button>
          </div>
        </div>
      </Layout >
    </>
  );
};
