import axios from "axios";
import envConfig from "../../enviroment/enviroment";
import { Attachements } from "./Meeting";


const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const UPLOAD_FILE = `${API_URL}/files`;

export const uploadFile = async (data: any) => {
    try {
        const response = await axios.post<Attachements>(UPLOAD_FILE, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
};

export const getFile = async (id: string) => {
    try {
        const response = await axios.get<any>(UPLOAD_FILE + '?id=' + id, { responseType: 'blob' });
        return response;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
}