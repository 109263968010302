import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { Meeting, Trip } from '../../../../api-requests/Meeting';
import { useAuth } from '../../../auth';
import { getCountries } from '../../../../api-requests/GeoData';
import moment from 'moment';
import tzData from 'moment-timezone/data/meta/latest.json';
import dayjs from 'dayjs';
import LoomInput from '../../../../components/StyledComponents/LoomInput';
import LoomSelect from '../../../../components/StyledComponents/LoomSelect';

type Props = {
    values: Trip;
    setFieldValue: any;
    data: Meeting | undefined;
    approving?: boolean;
}

const Location = ({ data, setFieldValue, values, approving }: Props) => {
    const [countries, setCountries] = useState<any[]>([]);
    const { id } = useAuth();
    const formatSearchCountry = async (searchTerm = '') => {
        const fetchedData = await getCountries({
            searchTerm,
        });
        setCountries(fetchedData);
    };
    useEffect(() => {
        formatSearchCountry();
    }, []);

    return (
        <div className={`flex flex-col justify-center gap-4`}><div className="flex w-full gap-4 flex-row">
            <div className="flex gap-2 flex-col w-full">

                <LoomInput
                    name="Location.Name"
                    label="Name"
                    disabled={approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)}
                    onChange={(val) => {
                        setFieldValue('Destination.Name', val.target.value);
                    }}
                    value={values.Destination?.Name}
                    placeholder="Name"
                />
            </div>

        </div>
            <div className="flex w-full gap-2 flex-row">
                <div className="flex gap-2 flex-col w-full">

                    <LoomInput
                        label="Address"
                        name="Address"
                        onChange={(val) => {
                            setFieldValue(
                                'Destination.Address',
                                val.target.value,
                            );
                        }}
                        value={values.Destination?.Address}
                        placeholder="Address"
                        disabled={approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)}
                    />
                </div>
                <div className="flex gap-2 flex-col w-full">

                    <LoomInput
                        label="City"
                        name="City"
                        onChange={(val) => {
                            setFieldValue('Destination.City', val.target.value);
                        }}
                        value={values.Destination?.City}
                        placeholder="City"
                        disabled={approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)}
                    />
                </div>
                <div className="flex gap-2 flex-col w-full">

                    <LoomSelect<string | null>
                        label="Country"
                        name="County"
                        placeholder="Countries..."
                        onSearch={(v) => formatSearchCountry(v)}
                        onChange={(val) => {
                            const t = countries.filter(
                                (country) => country.Id === val,
                            )[0]
                            setFieldValue(
                                'Destination.Country',
                                t,
                            );
                            if (t) {
                                // @ts-ignore
                                const countryTimezones = tzData.countries[t.ISOCode2];
                                if (!countryTimezones || countryTimezones.zones.length === 0) {
                                    return;
                                }

                                // Assume the first timezone in the list is the desired one
                                const timezone = countryTimezones.zones[0];
                                const offset = moment.tz(timezone).utcOffset();
                                setFieldValue('TimezoneOffset', offset / 60)
                            } else {
                                const offset = dayjs().utcOffset() / 60;
                                setFieldValue('TimezoneOffset', offset)
                            }

                        }}
                        valueFunc={
                            values.Destination?.Country &&
                                values?.Destination?.Country?.Id !== 0
                                ? values.Destination?.Country?.Name
                                : null
                        }
                        options={countries.map((country) => {
                            return { value: country.Id, label: country.Name };
                        })}
                        disabled={approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)}
                    />
                </div>
            </div></div>
    )
}

export default Location