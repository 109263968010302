import { Input } from 'antd';
import React, { ChangeEvent } from 'react';
import './css/LoomInput.css';
import { ErrorMessage, useField } from 'formik';
type Props<T> = {
    prefix?: React.ReactElement;
    value: any;
    placeholder: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean | undefined;
    defaultValue?: any;
    label: string;
    required?: boolean;
    name?: string,
    color?: string,
    type?: string,
    step?: number,
    min?: number,
    max?: number,
};

const LoomInput = <T extends unknown>({
    prefix,
    value,
    placeholder,
    onChange,
    disabled,
    label,
    required = false,
    name = '',
    color,
    type,
    step,
    min,
    max,
    defaultValue,
}: Props<T>) => {
    return (
        <div className="flex gap-2 flex-col w-full">
            {label && (
                <div>
                    <label className={`form-label ${required ? 'required' : ''}`}>
                        {label}
                    </label>
                </div>
            )}
            <Input
                type={type}
                step={step}
                placeholder={placeholder}
                prefix={prefix}
                value={value}
                defaultValue={defaultValue}
                onChange={(e) => onChange(e)}
                disabled={disabled}
                className={`h-input`}
                min={min}

                style={{ color: color ? color : disabled ? 'black' : '' }}
            />
            {name.length > 0 && <ErrorMessage name={name} className="text-red-500">
                {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>}

        </div>
    );
};

export default LoomInput;
