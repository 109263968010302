import { FC } from 'react';

type Props = {
  text?: string;
};

const Loader: FC<Props> = ({ text }) => {
  return (
    <div className="text-center">
      {/* <Spinner animation="border" role="status"></Spinner> */}
      {text && <div className="text-center small mt-4">{text}</div>}


    </div>
  );
};

export default Loader;
