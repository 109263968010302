import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ErrorMessage } from 'formik';
import React, { ChangeEvent } from 'react'
import './css/LoomInput.css';

type Props = {
    label: string | React.ReactElement,
    onChange: ((date: dayjs.Dayjs, dateString: string | string[]) => void);
    minDate?: dayjs.Dayjs,
    disabled?: boolean,
    disabledTime?: ((date: dayjs.Dayjs) => any),
    showTime?: boolean | dayjs.Dayjs | undefined | any,
    format: string,
    value: dayjs.Dayjs,
    name?: string,
    required?: boolean,
    maxDate?: dayjs.Dayjs
}

const LoomDatePicker = ({ label, onChange, minDate, disabled, disabledTime, showTime, format, value, name = "", maxDate, required = false }: Props) => {
    return (
        <div className="flex w-full flex-col gap-2">
            <div>
                <label className={`form-label ${required ? 'required' : ''}`}>
                    {label}</label>
            </div>
            <DatePicker
                maxDate={maxDate}
                allowClear={false}
                use12Hours={false}
                minDate={minDate}
                onChange={onChange}
                disabled={disabled}
                style={{ color: 'black', width: '100%' }}
                disabledTime={disabledTime}
                showTime={showTime}
                format={format}
                className="cursor-pointer h-input"
                value={
                    value
                }

            />
            {name.length > 0 && <ErrorMessage name={name} className="text-red-500">
                {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>}
        </div>
    )
}

export default LoomDatePicker