import { useCallback, useEffect } from 'react';
import { useAuth } from '..';
import { microsoftLogin } from '../core/_requests';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const { saveAuth, setCurrentUser } = useAuth();
  const state = localStorage.getItem('nonce');
  const navigate = useNavigate();
  const redirect = useCallback(
    () => navigate('/auth', { state: { showMessage: true }, replace: true }),
    [navigate],
  );
  useEffect(() => {
    // console.log(window.location.href.split('#')[1].split('=')[1].split('&')[0]);
    const Login = async () => {
      try {
        if (state) {
          const { data } = await microsoftLogin(
            state,
            window.location.href.split('#')[1].split('=')[1].split('&')[0],
          );
          saveAuth(data.Token);
          setCurrentUser(data.Username);
          navigate('/dashboard')
        }
      } catch (error) {
        redirect();
        console.error(error);
        saveAuth(undefined);
      }
    };
    Login();
  }, []);
  return <div></div>;
};

export default Callback;
