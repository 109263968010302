import { Outlet } from 'react-router-dom';
import { MasterInit } from './pages/layout/MasterInit';
import { AuthInit } from './features/auth';
import { NotificationProvider } from './permissions/components/timesheet/Notification-service';
import { TimesheetProvider } from './features/timesheet/provider/timesheetProvider';
import { LeaveProvider } from './features/leave/provider/leaveProvider';
import { MeetingProvider } from './features/project/meeting/provider/meetingProvider';
import { Suspense } from 'react';
import { FeatureProvider } from './features/feature/FeatureProvider';
import { TripProvider } from './features/project/Trip/provider/tripProvider';
import { TeamProvider } from './features/humanResources/teams/provider/TeamProvider';

const App = () => {
  return (
    <Suspense>
      <FeatureProvider>
        <NotificationProvider>
          <AuthInit>
            <TeamProvider>
              <TimesheetProvider>
                <LeaveProvider>
                  <MeetingProvider>
                    <TripProvider>
                      <Outlet />
                      <MasterInit />
                    </TripProvider>
                  </MeetingProvider>
                </LeaveProvider>
              </TimesheetProvider>
            </TeamProvider>
          </AuthInit>
        </NotificationProvider>
      </FeatureProvider>
    </Suspense>
  );
};

export { App };
