import { generateRandomString } from './generateRandomString';

export function generateUrl(
  client_id: string,
  response_type: string,
  scope: string,
  redirect_uri: string,
  response_mode: string,
) {
  const nonce = generateRandomString(32);
  const url = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${client_id}&response_type=${response_type}&scope=${scope}%20email&nonce=${nonce}&state=${nonce}&redirect_uri=${redirect_uri}&response_mode=${response_mode}`;

  return { url, nonce };
}

type UrlParams = {
  _searchTerm?: string | number;
  _pageIndex?: number;
  _pageSize?: number;
  _status?: string;
  _from?: string;
  _to?: string;
  _sort?: string;
  _orgId?: number;
  _personIds?: string[];
  _meMode?: string;
};

export const buildQueryString = (params: UrlParams): string => {
  const queryString = Object.entries(params)
    .filter(([value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${value.join(',')}`;
      }
      return `${key}=${encodeURIComponent(String(value))}`;
    })
    .join('&');
  return queryString ? `?${queryString}` : '';
};
