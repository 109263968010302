import { createContext } from 'react';
import { Team } from '../../../../api-requests/Employees';

export interface ITeamContext {
    teams: Team[];
    teamsTotal: number;
    loading: boolean;
    getTeams: any;
    createTeams: any;
    deleteTeam: any;
}

const TeamContext = createContext<ITeamContext | undefined>(
    undefined,
);

TeamContext.displayName = 'Team';

export default TeamContext;
