import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { BalanceData } from '../@types/Balance';
import { CommonListResponse } from '../@types/Response';
import { BalanceValidationArraySchema } from '../utils/FormValidation/LeaveValidation';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const FETCH_BALANCES = `${API_URL}/leaves/balances`;
export interface FetchBalanceOptions {
  employeeId?: number;
  year?: number;
  pageSize?: number;
  pageIndex?: number;
  searchTerm?: string;
  sorting?: string;
  personId?: number;
}

export const fetchBalance = async ({
  employeeId = 0,
  year = 0,
  pageSize = 100,
  pageIndex = 0,
  searchTerm = '',
  sorting = '',
  personId = 0,
}: FetchBalanceOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    employeeId: employeeId?.toString(),
    year: year?.toString(),
    sorting,
    personId: personId?.toString(),
  });
  try {
    const response = await axios.get<CommonListResponse<BalanceData>>(
      `${FETCH_BALANCES}?${params.toString()}`,
    );

    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Error fetching timesheet:', error);
    throw error;
  }
};
