import { useEffect, useRef, useState } from 'react';

const useChartHeight = () => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(350);

  useEffect(() => {
    chartRef.current;
  }, [chartRef]);

  return { chartRef, height };
};

export default useChartHeight;
