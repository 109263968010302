import moment from 'moment';
import { TimesheetEntry } from '../components/charts/ChartsWidget11';
import dayjs from 'dayjs';

export function checkDates(
  monthActive: Date,
  list: TimesheetEntry[],
): TimesheetEntry[] {
  const removed = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i].ProjectAcronym === '') {
      continue;
    }
    if (
      !moment(new Date(monthActive)).isBetween(
        moment(new Date(list[i].WPStartDate as string)),
        moment(new Date(list[i].WPEndDate as string)),
      )
    ) {
      removed.push(list[i]);
    }
  }
  return removed;
}

export function getFirstAndLastDateOfMonth(date: Date): [Date, Date] {
  const year = date.getFullYear();
  const month = date.getMonth();

  // First day of the month
  const firstDate = new Date(year, month, 1);
  firstDate.setDate(-5);
  // Last day of the month
  const lastDate = new Date(year, month + 1, 0);


  return [firstDate, lastDate];
}
export function getCurrentWeekDates(currentDate: Date) {
  // Clone the current date to avoid modifying the original date
  const date = new Date(currentDate.getTime());

  // Calculate the first day (Sunday) and last day (Saturday) of the current week
  const firstDay = new Date(date.setDate(date.getDate() - date.getDay()));
  const lastDay = new Date(firstDay.getTime());
  lastDay.setDate(firstDay.getDate() + 6);

  // Reset the time to the start of the day for both dates
  firstDay.setHours(0, 0, 0, 0);
  lastDay.setHours(23, 59, 59, 999);

  return [firstDay, lastDay];
}

export function getFirstWeekOfMonth(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();

  // Get the first day of the month
  const firstDayOfMonth = new Date(year, month, 1);

  // Get the day of the week of the first day of the month
  const firstDayOfWeek = firstDayOfMonth.getDay();

  // Calculate the first Sunday
  const firstSunday = new Date(firstDayOfMonth);
  firstSunday.setDate(firstDayOfMonth.getDate() - firstDayOfWeek);

  // Calculate the first Saturday
  const firstSaturday = new Date(firstSunday);
  firstSaturday.setDate(firstSunday.getDate() + 6);

  return [firstSunday, firstSaturday];
}
export function isInCurrentMonth(date: Date) {
  const currentDate = new Date();

  const givenYear = date.getFullYear();
  const givenMonth = date.getMonth();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return givenYear === currentYear && givenMonth === currentMonth;
}
export const getNextAvailableTime = () => {
  const now = dayjs();
  const minutes = now.minute();
  const hour = now.hour();

  // If the current time is closer to 30 or end of the hour, adjust the time
  if (minutes > 30) {
    return now.minute(0).hour(hour + 1).second(0).millisecond(0).toDate();
  } else if (minutes > 0) {
    return now.minute(30).second(0).millisecond(0).toDate();
  }
  return now.second(0).millisecond(0).toDate();
};
