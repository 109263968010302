import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal, Button, DatePicker, Select, Input, Grid } from 'antd';
import dayjs from 'dayjs';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/themes/airbnb.css';

import moment from 'moment';

import { AxiosError } from 'axios';

import { c } from 'vitest/dist/reporters-5f784f42.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeaveRequestPost } from '../../../../@types/Request';
import { LeaveOptions } from '../../../../@types/FetchOptions';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { useLeaves } from '../../../leave/provider/useLeaves';
import useTableContext from '../../../table/provider/TableContext';
import WarningModal from '../../../../components/modal/WarningModal';
import { createLeaveSchema, initLeaveRequest, initTeam } from '../../../../utils/FormValidation/CreateAccountWizardHelper';
import { LeaveColors } from '../../../../../shared/colors';
import { EmployeeSimple, Team, fetchRelatedPeople } from '../../../../api-requests/Employees';
import { useTeam } from '../provider/useTeam';
import { stringifySorting } from '../../../../utils/sortingParser';
import { useAuth } from '../../../auth';

const { TextArea } = Input;
const { useBreakpoint } = Grid;
type CalendarModalFormProps = {
    isOpen?: boolean;
    onClose: () => void;
    data: Team;
    setData?: Dispatch<SetStateAction<Team>>
    setIsOpen: (value: boolean) => void;
    fetchData: (value: LeaveOptions) => void;
};

const TeamCreateModal = ({
    isOpen,
    onClose,
    data,
    setData,
    setIsOpen,
    fetchData,
}: CalendarModalFormProps) => {
    const { id, allowIf } = useAuth();
    const [showNavBlock, setShowNavBlock] = useState(false);
    const [creating, setCreating] = useState(false);
    const formRef = useRef<FormikProps<Team>>();
    const navigate = useNavigate();
    const [displayEmps, setDisplayEmps] = useState<EmployeeSimple[]>([]);
    const [emps, setEmps] = useState<EmployeeSimple[]>([]);
    const { openNotification } = useNotifications();
    const { createTeams, getTeams } = useTeam();
    const formatInitialData = async (searchTerm = '') => {
        const fetchedData = await fetchRelatedPeople({
            includeInactive: true,
            searchTerm,
        });
        if (searchTerm.length === 0) {
            setDisplayEmps([]);
            setEmps(fetchedData.Results);
        } else setDisplayEmps(fetchedData.Results);
    };
    useEffect(() => {
        formatInitialData()
    }, []);
    const { sort, pageSize, from, to, status } = useTableContext();

    const submitStep = async (values: Team) => {
        try {
            await createTeams({ ...values });
            formRef.current?.resetForm();
            if (data) {
                openNotification('topRight', {
                    title: `Team`,
                    description: 'Team updated successfully.',
                    type: 'Success',
                });
                setIsOpen(false);
                navigate('/apps/human/teams');
            } else {
                openNotification('topRight', {
                    title: `Team`,
                    description: `Team created successfully.`,
                    type: 'Success',
                });
                setIsOpen(false)
                navigate('/apps/human/teams');
            }
            getTeams({
                sorting: stringifySorting(sort),
                pageIndex: 0,
                pageSize: pageSize?.value,
                status: status,
                from: from,
                to: to,
            });
        } catch (err) {
            if (err instanceof AxiosError) {
                openNotification('topRight', {
                    title: `Team`,
                    description: err?.response?.data.error,
                    type: 'Danger',
                });
            }
        }
    };
    return (
        <div data-testid="calendarModal">
            <WarningModal
                title="Unsaved Changes"
                isOpen={showNavBlock}
                setIsOpen={setShowNavBlock}
                footerButtons={[
                    <Button onClick={() => {
                        setShowNavBlock(false);

                        formRef.current?.resetForm();
                        onClose();
                    }}>Leave</Button>,
                    <Button
                        onClick={() => { setShowNavBlock(false); }}
                        style={{ background: LeaveColors[0].value, color: 'white' }}
                    >
                        Return to form
                    </Button>,

                ]}
                mainText="Are you sure you want to leave the page without submitting the form? Your changes will not be saved."
            />
            <Modal
                width={800}
                open={isOpen}
                onCancel={() => {
                    if (formRef.current?.dirty) {
                        setShowNavBlock(true)
                    } else {
                        formRef.current?.resetForm();
                        onClose();
                    }

                }}
                title={
                    data.Id === 0 ? 'Create Team' : data.Leader.Id !== id ? 'View Team' : 'Edit Team'
                }
                footer={[
                    data.Id === 0 ? <Button
                        data-testid="submitLeave"
                        type="primary"
                        key={'submit'}
                        disabled={creating}
                        htmlType="submit"
                        form="FormLeave"

                    >
                        <span className="indicator-label">Create</span>
                    </Button> : data.Leader.Id === id || allowIf(['Teams_EditAll']) ? <Button
                        data-testid="submitLeave"
                        type="primary"
                        key={'submit'}
                        disabled={creating}
                        htmlType="submit"
                        form="FormLeave"

                    >
                        <span className="indicator-label">Update</span>
                    </Button> : null

                ]}
            >
                <div className={`container`}>
                    <Formik
                        innerRef={(formikProps) => {
                            if (formikProps) {
                                formRef.current = formikProps;
                            }
                        }}
                        initialValues={data ? data : initTeam}
                        enableReinitialize
                        onSubmit={async (values, { resetForm }) => {
                            submitStep(values);
                            resetForm();
                        }}
                    >
                        {({ values, setFieldValue, handleSubmit }) => {

                            return (
                                < form onSubmit={handleSubmit} id="FormLeave" >
                                    <div className="flex flex-col gap-4 p-4">
                                        <div data-testid="leaveTypeDropdown" className="">
                                            <div className="mb-3">
                                                <label className="mb-3">Name</label>
                                            </div>
                                            <Input value={values.Name}
                                                disabled={values.Id !== 0 && data.Leader.Id !== id && !allowIf(['Teams_EditAll'])}
                                                placeholder='Name'
                                                onChange={(e) => { setFieldValue('Name', e.target.value) }} />
                                        </div>
                                        <div data-testid="leaveTypeDropdown" className="">
                                            <div className="mb-3">
                                                <label className="mb-3">Leader</label>
                                            </div>
                                            <Select
                                                disabled={values.Id !== 0 && data.Leader.Id !== id && !allowIf(['Teams_EditAll'])}

                                                style={{ color: 'black', width: '100%' }}
                                                optionFilterProp="children"
                                                placeholder="Leader..."
                                                showSearch
                                                onSearch={(v) => formatInitialData(v)}
                                                value={
                                                    values.Leader.FullName ? values.Leader.FullName : null
                                                }
                                                onChange={(val) => {
                                                    const selectedEmps = emps.filter((emp) =>
                                                        // @ts-ignore
                                                        val === emp.Id,
                                                    );

                                                    setFieldValue('Leader', selectedEmps[0]);
                                                }}
                                                filterOption={false}
                                                options={
                                                    displayEmps.length > 0
                                                        ? displayEmps.map((emp) => {
                                                            return {
                                                                value: emp.Id,
                                                                label: emp.FullName,
                                                                key: emp.Id,
                                                            };
                                                        })
                                                        : emps.map((emp) => {
                                                            return {
                                                                value: emp.Id,
                                                                label: emp.FullName,
                                                                key: emp.Id,
                                                            };
                                                        })
                                                }
                                            />
                                        </div>
                                        <div data-testid="leaveTypeDropdown" className="">
                                            <div className="mb-3">
                                                <label className="mb-3">Members</label>
                                            </div>
                                            <Select
                                                disabled={values.Id !== 0 && data.Leader.Id !== id && !allowIf(['Teams_EditAll'])}
                                                style={{ color: 'black', width: '100%' }}
                                                optionFilterProp="children"
                                                placeholder="Employees..."
                                                mode="multiple"
                                                onSearch={(v) => formatInitialData(v)}
                                                value={
                                                    values.Members.length > 0 ?
                                                        values.Members.map((em: any) => {
                                                            return {
                                                                value: em.Id,
                                                                label: em.FullName,
                                                            };
                                                        }) as any : null
                                                }
                                                onChange={(val) => {
                                                    const selectedEmps = emps.filter((emp) =>
                                                        val.includes(emp.Id),
                                                    );
                                                    setFieldValue('Members', selectedEmps);
                                                }}
                                                filterOption={false}
                                                options={
                                                    displayEmps.length > 0
                                                        ? displayEmps.map((emp) => {
                                                            return {
                                                                value: emp.Id,
                                                                label: emp.FullName,
                                                                key: emp.Id,
                                                            };
                                                        })
                                                        : emps.map((emp) => {
                                                            return {
                                                                value: emp.Id,
                                                                label: emp.FullName,
                                                                key: emp.Id,
                                                            };
                                                        })
                                                }
                                            />
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </div>
            </Modal >
        </div >
    );
};

export default TeamCreateModal;
