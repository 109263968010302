import React, { useMemo } from 'react';
import LeaveContext from './leaveContext';

export const useLeaves = () => {
  const context = React.useContext(LeaveContext);

  if (context === undefined) {
    throw new Error('useLeave must be used within a Leave');
  }
  // Memoize the context value to prevent unnecessary re-renders
  const memoizedContext = useMemo(() => context, [context]);
  return memoizedContext;
};
