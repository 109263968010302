import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Modal,
    Button,
    DatePicker,
    Select,
    Input,
    Form,
    Checkbox,
    Tooltip,
    Spin,
    Typography,
    Table,
    TableProps,
} from 'antd';
import dayjs from 'dayjs';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/themes/airbnb.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
    FormOutlined,
    EnvironmentOutlined,
    InboxOutlined,
    EyeOutlined,
    DeleteOutlined,
    SnippetsOutlined,
    InfoCircleOutlined,
    EditOutlined,
    FileOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import {
    createMeetingSchema,
    initMeeting,
} from '../../../../utils/FormValidation/CreateAccountWizardHelper';
import { AxiosError } from 'axios';

import {
    Employee,
    EmployeeSimple,
    fetchRelatedPeople,
} from '../../../../api-requests/Employees';
import { Project, fetchProjects } from '../../../../api-requests/Projects';
import {
    Attachements,
    Meeting,
    addTraveler,
    createMeeting,
    createTripFromMeeting,
    deleteMeetingById,
    removeTraveler,
} from '../../../../api-requests/Meeting';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth';
import { LeaveColors, TimesheetColors } from '../../../../../shared/colors';
import WarningModal from '../../../../components/modal/WarningModal';
import './index.css';
import Dragger from 'antd/es/upload/Dragger';
import PageWrapper from '../../../../components/wrapper/PageWrapper';
import BasicInformationMeeting from './FormComponents/BasicInformationMeeting';
import AttendeesMeeting from './FormComponents/AttendeesMeeting';
import LocationMeeting from './FormComponents/LocationMeeting';
import AttachmentsMeeting from './FormComponents/AttachmentsMeeting';
import Prompt from '../../../../utils/useNavBlocker/useNavBlocker';
import DateTime from './FormComponents/DateTime';
import MultipleOrganisationTripCreation from './MultipleOrganisationTripCreation';
const { Title } = Typography;
dayjs.extend(utc);
dayjs.extend(timezone);


type CreateMeetingProps = {
    data?: Meeting;
};
const formatAttachments = (attachments: Attachements[]) => {
    return attachments.map((file) => ({
        uid: file.Id,
        name: file.FileName,
        FileName: file.FileName,
        Id: file.Id,
        status: 'done',
        url: `path_to_your_file_storage/${file.FileName}`, // Adjust the URL to point to your actual file storage location
        uploadedBy: file.UploadedBy,
        uploadDateTime: file.UploadedDateTime,
        Description: file.Description === '' ? 'Meeting Agenda' : file.Description,
    }));
};
const CreateMeeting = ({ data }: CreateMeetingProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { openNotification } = useNotifications();
    const [form] = Form.useForm();
    const [init, setInit] = useState(initMeeting);
    const [formDirty, setFormDirty] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [attachments, setAttachments] = useState<Attachements[]>(
        data ? data.Attachments : [],
    );
    const [removeTravellerWarning, setRemoveTravellerWarning] = useState(false);
    const [removedTraveller, setRemovedTraveller] =
        useState<EmployeeSimple | null>(null);
    const [fileList, setFileList] = useState<any>([]);
    const [warningOpen, setWarningOpen] = useState(false);
    const [countries, setCountries] = useState<any[]>([]);
    const navigate = useNavigate();

    const { id } = useAuth();


    const formRef = useRef<FormikProps<Meeting> | null | undefined>();
    useEffect(() => {
        if (data) {
            setInit(data);
            setFileList(formatAttachments(data.Attachments));
            setAttachments(data.Attachments);
        }
    }, [data]);

    const submitStep = async (values: Meeting) => {
        try {
            setIsSubmitting(true);
            values.Attachments = fileList;
            if (values.AllDay) {
                values.From = moment(values.From).format('yyyy-MM-DD');
                values.To = moment(values.To).format('yyyy-MM-DD');
            }
            await createMeeting({ ...values });
            await handleAddTraveller(values);
            await handleDeleteTraveller(values);
            formRef.current?.resetForm();
            if (data) {
                openNotification('topRight', {
                    title: `Meeting`,
                    description: `Meeting updated successfully.`,
                    type: 'Success',
                });
                navigate('/apps/projects/meetings');
            } else {
                openNotification('topRight', {
                    title: `Meeting`,
                    description: `Meeting created successfully.`,
                    type: 'Success',
                });
                navigate('/apps/projects/meetings');
            }
            setFileList([]);
            setIsSubmitting(false)
        } catch (err) {
            if (err instanceof AxiosError) {
                openNotification('topRight', {
                    title: `Trip`,
                    description: err?.response?.data.error,
                    type: 'Danger',
                });
                setIsSubmitting(false);
            }
        }
    };

    const handleDeleteTraveller = async (values: Meeting) => {
        if (values && data) {
            const removedTravellers = data.Attendees.filter((initialTraveller: any) => {
                return !values.Attendees.some(traveller => traveller.Attendee.Id === initialTraveller.Attendee.Id);
            });
            for (const removedTraveller of removedTravellers) {
                if (removedTraveller.IsTraveller) {
                    try {
                        await removeTraveler({
                            employeeId: removedTraveller.Attendee.Id,
                            tripId: values.Trips[0]?.Id
                        });
                    } catch (error) {

                        console.error(`Failed to remove traveler with ID ${removedTraveller.Attendee.Id}`, error);
                        throw new Error(`Failed to remove traveler with ID ${removedTraveller.Attendee.Id}`);
                    }
                }

            }
        }
    };

    const handleDeleteMeeting = async (id: number) => {
        try {
            await deleteMeetingById(id);
            setWarningOpen(false);
            openNotification('topRight', {
                title: `Meeting`,
                description: `Meeting has been deleted successfully`,
                type: 'Success',
            });
            navigate('/apps/projects/meetings');
        } catch (error) {
            console.error('Error on withdraw');
            if (error instanceof AxiosError) {
                setWarningOpen(false);
                openNotification('topRight', {
                    title: `Leave`,
                    description: error?.response?.data.error,
                    type: 'Danger',
                });

            }
        }
    };

    const handleAddTraveller = async (values: Meeting) => {
        if (values) {
            const newTravellers = values.Attendees.filter(traveller => {
                // Check if the traveller is not in the initial data
                return !init?.Attendees.some((initialTraveller: any) => initialTraveller.Attendee.Id === traveller.Attendee.Id);
            });
            for (const newTraveller of newTravellers) {
                try {
                    // Perform the addTraveler operation for new travelers
                    if (values.Trips[0]?.Id && newTraveller.IsTraveller) {
                        await addTraveler({
                            employeeId: newTraveller.Attendee.Id,
                            tripId: values.Trips[0].Id
                        });
                    }
                } catch (error) {
                    console.error(`Failed to add traveler with ID ${newTraveller.Attendee.Id}`, error);
                    throw new Error(`Failed to add traveler with ID ${newTraveller.Attendee.Id}`);
                }
            }
        }
    };

    return (
        <div data-testid="calendarModal" className="flex justify-center">
            <Prompt formRef={formRef} isSubmitting={isSubmitting} />
            <WarningModal
                title="Withdraw Leave"
                isOpen={warningOpen}
                setIsOpen={setWarningOpen}
                footerButtons={[
                    <Button onClick={() => setWarningOpen(false)}>Close</Button>,
                    <Button
                        onClick={() => {
                            if (data && data.Id) handleDeleteMeeting(data?.Id);
                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Delete
                    </Button>,
                ]}
                mainText="Are you sure you want to delete this project meeting? Your action will remove it permanently."
            />

            <WarningModal
                title="Remove Traveller"
                isOpen={removeTravellerWarning}
                setIsOpen={setRemoveTravellerWarning}
                footerButtons={[
                    <Button
                        onClick={() => {
                            setRemoveTravellerWarning(false);

                            formRef.current?.setFieldValue('Attendees', [
                                ...formRef.current.values.Attendees,
                                {
                                    Id: removedTraveller?.Id,
                                    PhysicalAttendance: true,
                                    Attendee: {
                                        BasedInCountry: removedTraveller?.BasedInCountry,
                                        Id: removedTraveller?.Id,
                                        FirstName: removedTraveller?.FirstName,
                                        LastName: removedTraveller?.LastName,
                                        FullName: removedTraveller?.FullName,
                                    },
                                },
                            ]);
                        }}
                    >
                        Close
                    </Button>,
                    <Button
                        onClick={async () => {
                            const data = {
                                employeeId: removedTraveller?.Id,
                                tripId: formRef.current?.values?.Trips[0]?.Id,
                            };
                            if (data.tripId && data.employeeId)
                                await removeTraveler(data)
                                    .then(() => {
                                        setRemovedTraveller(null);
                                        setRemoveTravellerWarning(false);
                                    })
                                    .catch((err) => {
                                        setRemoveTravellerWarning(false);
                                    });
                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Remove
                    </Button>,
                ]}
                mainText={`Are you sure you want to remove ${removedTraveller?.FullName} from the meeting? This action will remove ${removedTraveller?.FullName} from trip as well.`}
            />


            <div>
                <Formik
                    initialValues={init}
                    enableReinitialize
                    onSubmit={async (values, { resetForm }) => {
                        await submitStep(values);
                    }}
                    validationSchema={createMeetingSchema}
                    innerRef={(formikProps) => {
                        if (formikProps) {
                            formRef.current = formikProps;
                        }
                    }}
                >
                    {({ values, setFieldValue, handleSubmit, resetForm }) => {
                        return (
                            <>
                                <div className='gap-1 flex justify-end mr-4'>
                                    <Button className='border-b-0  rounded-b-none' onClick={() => navigate('/apps/projects/meetings')}>Back to List</Button>

                                    {/* {values.Trips.length === 0 && (
                                        <Button className='border-b-0  rounded-b-none' onClick={() => handleCreateTrip(values)} disabled={dayjs(values.From).isBefore(new Date())}>Create Trip</Button>
                                    )} */}
                                    {!(values.Id !== 0) && <Button onClick={() => {
                                        setFileList([]);
                                        resetForm()
                                    }} className='bg-primary hover:text-primary text-white  border-b-0  rounded-b-none'>Clear Form</Button>}
                                </div>
                                <form onSubmit={handleSubmit} className="pb-4" style={{ minWidth: 970 }}>
                                    <div className={`flex flex-col justify-center gap-4`}>
                                        <div>
                                            <BasicInformationMeeting
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                countries={countries}
                                                data={data}
                                            />
                                        </div>
                                        <div>
                                            <AttendeesMeeting
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>
                                        <div>
                                            <DateTime values={values} setFieldValue={setFieldValue} countries={countries} />
                                        </div>
                                        <div>
                                            <LocationMeeting
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>
                                        <div>
                                            <AttachmentsMeeting
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                fileList={fileList}
                                                setFileList={setFileList}
                                            />
                                        </div>
                                        <div className="flex justify-end mt-6">
                                            {!data ? (
                                                <Button
                                                    size={"large"}
                                                    data-testid="submitLeave"
                                                    type="primary"
                                                    htmlType="submit"
                                                    className='bg-primary'
                                                >
                                                    <span className="indicator-label">Create</span>
                                                </Button>
                                            ) : (
                                                id === data.CreatedBy?.Id && (
                                                    <div className="flex gap-4">
                                                        {data.Trips.length === 0 && (
                                                            <Button
                                                                data-testid="rejectBtn"
                                                                danger
                                                                style={{
                                                                }}
                                                                onClick={() => setWarningOpen(true)}
                                                                className='hover:bg-danger hover:text-danger '
                                                            >
                                                                Delete
                                                            </Button>
                                                        )}

                                                        <Button
                                                            data-testid="submitLeave"
                                                            type="primary"
                                                            htmlType="submit"
                                                            className='bg-primary'
                                                        >
                                                            <span className="indicator-label">Update</span>
                                                        </Button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </>
                        )
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateMeeting;
