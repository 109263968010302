import { useEffect, useState } from 'react';

export function MasterInit() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  return <></>;
}
