import axios from 'axios';
import envConfig from '../../enviroment/enviroment';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const FETCH_ORGANISATION = `${API_URL}/organisations/list`;

export interface FetchOrganisationType {
  Id: number;
  ShortName: string;
  LegalName: string;
  BusinessName: string;
}

export const fetchOrganisation = async () => {
  try {
    const response = await axios.get<FetchOrganisationType[]>(
      `${FETCH_ORGANISATION}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching filtered Organisation list:', error);
    throw error; // Re-throw the error to be caught by the caller if necessary
  }
};
