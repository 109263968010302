export function randomHexColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export function isLightColour(hexColor: string) {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  const threshold = 200;

  return luminance > threshold;
}
