import { useLeaves } from '../provider/useLeaves';
import SingleCardLeaveType from './SingleCardLeaveType';

const StatisticsWrapper = () => {
  const { balance } = useLeaves();
  const year = new Date().getFullYear();

  const currentYearbalances = balance && balance
    .filter((bal) => bal.Year === year)


  return (
    <div className="w-full">
      <div className={`grid md:grid-cols-12 grid-cols-4 gap-6`}>
        {currentYearbalances?.map((bal, index) => (
          <SingleCardLeaveType
            balanceData={bal}
            key={`${bal.Id}-${index}`}
            title={`Remaining ${bal.Policy?.TypeOfLeave?.Name}`}
          />
        ))}
      </div>
    </div>
  );
};

export default StatisticsWrapper;
