import { useEffect, useRef, useState } from 'react';
import { Modal, Button, DatePicker, Select, Input, Grid } from 'antd';
import dayjs from 'dayjs';
import { useNotifications } from '../../../permissions/components/timesheet/Notification-service';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/themes/airbnb.css';
import { createLeave, getWorkableDays } from '../../../api-requests/Leave';
import { useLeaves } from '../provider/useLeaves';
import moment from 'moment';
import LeaveFormStats from './LeaveFormStats';
import { createLeaveSchemas } from './validationSchema';
import useTableContext from '../../table/provider/TableContext';
import { stringifySorting } from '../../../utils/sortingParser';
import {
  createLeaveSchema,
  initLeaveRequest,
} from '../../../utils/FormValidation/CreateAccountWizardHelper';
import { AxiosError } from 'axios';
import { LeaveRequestPost } from '../../../@types/Request';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { useAuth } from '../../auth';
import { c } from 'vitest/dist/reporters-5f784f42.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Prompt from '../../../utils/useNavBlocker/useNavBlocker';
import WarningModal from '../../../components/modal/WarningModal';
import { LeaveColors } from '../../../../shared/colors';
import LoomSelect from '../../../components/StyledComponents/LoomSelect';
import LoomDatePicker from '../../../components/StyledComponents/LoomDatePicker';

const { TextArea } = Input;
type CalendarModalFormProps = {
  isOpen?: boolean;
  onClose: () => void;
  data: LeaveRequestPost | any;
  setData: (value: LeaveRequestPost) => void;
  setIsOpen: (value: boolean) => void;
  fetchData: (value: LeaveOptions) => void;
};

const CalendarModalForm = ({
  isOpen,
  onClose,
  data,
  setData,
  setIsOpen,
  fetchData,
}: CalendarModalFormProps) => {
  const { openNotification } = useNotifications();
  const { balance, getBalance } = useLeaves();
  const { sort, pageSize, from, to, status } = useTableContext();
  const [workableDays, setWorkableDays] = useState<number>(1);
  const [showNavBlock, setShowNavBlock] = useState(false);
  const [creating, setCreating] = useState(false);
  const formRef = useRef<FormikProps<LeaveRequestPost> | null>();
  const navigate = useNavigate();


  const submitStep = async (values: LeaveRequestPost) => {
    try {
      values['leaveBalanceId'] = values.LeaveType && values.LeaveType.Id;
      (values.Start = moment(values.Start).format('YYYY-MM-DDTHH:mm:ss'));
      (values.End = moment(values.End).format('YYYY-MM-DDTHH:mm:ss'));
      if (workableDays > 0) {
        setCreating(true);
        await createLeave(values);
        openNotification('topRight', {
          title: `Leave`,
          description: `Leave created successfully. An operator will approve your leave.`,
          type: 'Success',
        });
        fetchData({
          sorting: stringifySorting(sort),
          pageIndex: 0,
          pageSize: pageSize?.value,
          status: status,
          from: from,
          to: to,
        });
        getBalance(new Date().getFullYear());
        setCreating(false);
        onClose();
        setIsOpen(!isOpen);
        navigate('/apps/leave/list')
      } else {
        openNotification('topRight', {
          title: `Leave`,
          description: `Working days have to be greater than zero.`,
          type: 'Danger',

        });
      }
    } catch (err) {
      fetchData({
        sorting: stringifySorting(sort),
        pageIndex: 0,
        pageSize: pageSize?.value,
        status: status,
        from: from,
        to: to,
      });
      if (err instanceof AxiosError) {

        formRef.current?.resetForm();
        openNotification('topRight', {
          title: `Leave`,
          description: err?.response?.data.error,
          type: 'Danger',
        });
        setCreating(false)
      }
    }
    formRef.current?.resetForm();
  };
  useEffect(() => {
    if (balance && isOpen)
      setData({ ...data, LeaveType: balance.filter((bal) => bal.Policy.TypeOfLeave.Id === 1)[0] });
  }, [balance, isOpen]);
  return (
    <div data-testid="calendarModal">
      <WarningModal
        title="Unsaved Changes"
        isOpen={showNavBlock}
        setIsOpen={setShowNavBlock}
        footerButtons={[
          <Button key={2} onClick={() => {
            setShowNavBlock(false);
            setData(initLeaveRequest);
            formRef.current?.resetForm();
            onClose();
          }}>Leave</Button>,
          <Button
            key={3}
            onClick={() => { setShowNavBlock(false); }}
            style={{ background: LeaveColors[0].value, color: 'white' }}
          >
            Return to form
          </Button>,

        ]}
        mainText="Are you sure you want to leave the page without submitting the form? Your changes will not be saved."
      />
      <Modal
        width={800}
        open={isOpen}
        onCancel={() => {
          if (formRef.current?.dirty) {
            setShowNavBlock(true)
          } else {
            setData(initLeaveRequest);
            formRef.current?.resetForm();
            onClose();
          }

        }}
        title={
          'Add new Leave'
        }

        footer={[
          <Button
            data-testid="submitLeave"
            type="primary"
            key={'submit'}
            size={'large'}
            disabled={creating}
            htmlType="submit"
            form="FormLeave"
          >
            <span className="indicator-label">Submit</span>
          </Button>
        ]}
      >
        <div >
          <Formik
            innerRef={(formikProps) => {
              if (formikProps) {
                formRef.current = formikProps;
              }
            }}
            validationSchema={createLeaveSchema}
            initialValues={data}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              await submitStep(values);
              resetForm();
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit} id="FormLeave" className='w-full'>
                <div className="flex flex-col gap-6 md:p-4">
                  <div data-testid="leaveTypeDropdown" className="">

                    <LoomSelect<number | string>
                      label={"Leave Type"}
                      name="LeaveType"
                      placeholder="Balance"
                      valueFunc={
                        values.LeaveType ? values.LeaveType.Id : balance && balance.filter((bal) => bal.Policy.TypeOfLeave.Id === 1)[0]
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          'LeaveType',
                          balance.filter((bal) => bal.Id === newValue)[0],
                        )
                      }
                      options={balance && balance.map((d) => {
                        return {
                          label:
                            d.Policy.TypeOfLeave.Name +
                            ' (Remaining days: ' +
                            d.RemainingDays +
                            ')',
                          value: d.Id,
                        };
                      })}
                    />
                  </div>
                  <div className="grid grid-cols-12 gap-4 w-full">


                    <div data-testid="startDateInput" className="col-span-6">

                      <LoomDatePicker
                        name="Start"
                        label="Start Date"
                        minDate={dayjs(new Date())}
                        onChange={(date) => {
                          setFieldValue('Start', date.toDate());
                          if (dayjs(new Date()).isAfter(new Date(values.End))) {
                            setFieldValue('End', values.Start);
                          }
                        }}
                        format="DD MMM YYYY"
                        value={
                          values.Start ? dayjs(values.Start) : dayjs(new Date())
                        }
                      />

                    </div>
                    <div data-testid="endDateInput" className="col-span-6">


                      <LoomDatePicker
                        label="End Date"
                        name="To"
                        minDate={
                          values.Start ? dayjs(values.Start) : dayjs(new Date())
                        }
                        onChange={(date) => {
                          setFieldValue('End', date.toDate());
                        }}
                        format="DD MMM YYYY"
                        value={
                          values.End ? dayjs(values.End) : dayjs(new Date())
                        }
                      />
                      <div className="text-danger">
                        <ErrorMessage name="End" className="text-red-500">
                          {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {workableDays === 0 && <div style={{ color: 'red' }}>Working days must be more than zero.</div>}

                  <div data-testid="commentInput">
                    <div className="mb-3">
                      <label className="form-label mb-3">Comments</label>
                    </div>
                    <TextArea
                      rows={2}
                      data-testid="desc"
                      size="large"
                      value={values.Description}
                      placeholder="Comments"
                      onChange={(e) =>
                        setFieldValue('Description', e.target.value)
                      }
                    />
                  </div>
                  <LeaveFormStats
                    workableDays={workableDays}
                    setWorkableDays={setWorkableDays}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default CalendarModalForm;
