import * as Yup from 'yup';
import { Timesheet } from '../../api-requests/Timesheet';
import { Meeting, Trip } from '../../api-requests/Meeting';
import dayjs from 'dayjs';
import { LeaveRequestPost } from '../../@types/Request';
import { getNextAvailableTime } from '../checkDates';
import { Team } from '../../api-requests/Employees';

export interface ICreateAccount {
  accountType: string;
  accountTeamSize: string;
  accountName: string;
  accountPlan: string;
  businessName: string;
  businessDescriptor: string;
  businessType: string;
  businessDescription: string;
  businessEmail: string;
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

const createTimesheetSchemas = Yup.object().shape({
  Employee: Yup.object().shape({
    PersonId: Yup.number().min(0.5, 'Employee is required.'),
  }),
  Month: Yup.string(),
  status: Yup.string(),
  TimesheetEntries: Yup.array().of(
    Yup.object().shape({
      HoursWorked: Yup.number()
        .min(0.5, 'Hours must be greater than 0.')
        .required('Hours are required.'),
      ProjectAcronym: Yup.string().required('Work Package is required.'),
    }),
  ),
});
const createReportSchema = Yup.object().shape({
  title: Yup.string().required().label('Title'),
  description: Yup.string().required().label('Description'),
});
const createLeaveSchema = Yup.object().shape({
  Start: Yup.date(),
  End: Yup.date().default(new Date())
    .when("Start",
      (Start, yup) => Start && yup.min(Start, "End date cannot be before start date")),
  Description: Yup.string(),
  WorkableDays: Yup.number().min(1),
});
const createMeetingSchema = Yup.object().shape({
  Name: Yup.string().required().label('Title'),
  To: Yup.date().required().label('End date'),
  From: Yup.date().label('Starting date is required'),
  Attendees: Yup.array().min(1, "You need at least one attendee").required("required"),
  Project: Yup.object().shape({
    Acronym: Yup.string().required('Project is a required field'),
  }),
  TimezoneOffset: Yup.number().integer('Timezone offset is required.').required(),
  Location: Yup.object().shape({
    Country: Yup.object().shape({
      Name: Yup.string().required('Country is a required field'),
    }),

  }),

})
const printTimesheetSchemas = Yup.object().shape({});

const createTripSchema = Yup.object().shape({
  // Meeting: Yup.object().shape({
  //   Name: Yup.string().required('Meeting is a required field'),
  // }),
  Purpose: Yup.string().required('Purpose is a required field'),
  Title: Yup.string().required('Title is a required field'),
  // Travellers: Yup.array().min(1, "You need at least one traveller").required("required"),

})


const createAccountSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    accountName: Yup.string().required().label('Account Name'),
  }),
  Yup.object({
    businessName: Yup.string().required().label('Business Name'),
    businessDescriptor: Yup.string().required().label('Shortened Descriptor'),
    businessType: Yup.string().required().label('Corporation Type'),
    businessEmail: Yup.string().required().label('Contact Email'),
  }),

  Yup.object({
    nameOnCard: Yup.string().required().label('Name On Card'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
];

const inits: ICreateAccount = {
  accountType: 'personal',
  accountTeamSize: '50+',
  accountName: '',
  accountPlan: '1',
  businessName: 'Keenthemes Inc.',
  businessDescriptor: 'KEENTHEMES',
  businessType: '1',
  businessDescription: '',
  businessEmail: 'corp@support.com',
  nameOnCard: 'Max Doe',
  cardNumber: '4111 1111 1111 1111',
  cardExpiryMonth: '1',
  cardExpiryYear: '2025',
  cardCvv: '123',
  saveCard: '1',
};

const initLeaveRequest: LeaveRequestPost = {
  LeaveType: undefined,
  Start: new Date(),
  End: new Date(),
  Description: '',
  Id: 0,
  leaveBalanceId: 0,
};

const inits2: Timesheet = {
  WPs: '',
  Id: null,
  reSub: false,
  Organization: 0,
  Employee: {
    PersonId: 0,
    FirstName: '',
    LastName: '',
    FullName: '',
    CallName: '',
    EmployeeId: 0,
    OrgId: 0,
    OrgShortName: '',
    OrgName: '',
    BasedInCountryName: '',
    BasedInCountryCode: '',
    Email: '',
  },
  Notes: '',
  DateSubmitted: '',
  DateApproved: '',
  TotalHoursReported: 0,
  Month: '',
  Status: 0,
  TimesheetEntries: [
    {
      HoursWorked: 0,
      ProjectAcronym: '',
      WPId: 0,
      WPNumber: '',
      WPTitle: '',
      Id: 0,
      FQN: '',
    },
  ],
};



const initTeam: Team = {
  Id: 0,
  Name: '',
  Members: [],
  Leader: {
    Id: 0,
    FirstName: '',
    LastName: '',
    CallName: '',
    FullName: '',
  },
  Organisation: null,
}
const initMeeting: Meeting = {
  Id: 0,
  From: getNextAvailableTime(),
  To: dayjs(getNextAvailableTime()).add(30, 'minutes').format(),
  UTC: null,
  Name: '',
  Description: '',
  Status: "",
  MeetingMode: 0,
  AllDay: false,
  Type: 0,
  IsVirtual: false,
  VirtualMeetingLink: '',
  TimezoneOffset: 0,
  Location: {
    Id: 0,
    Name: '',
    Address: '',
    City: '',
    Latitude: 0,
    Longitude: 0,
    GooglePlusCode: "",
    GoogleMapsLink: "",
    Country: {
      Id: 0,
      Name: '',
      IsoCode2: '',
    }
  },
  Attendees: [],
  Project: { Id: 0, Acronym: '' },
  Attachments: [],
  Trips: [],
};
const initTrip: Trip = {
  Id: 0,
  Purpose: '',
  TimezoneOffset: dayjs().utcOffset() / 60,
  Title: '',
  LatestArrivalTime: getNextAvailableTime(),
  EarliestDepartureTime: dayjs(getNextAvailableTime()).add(30, 'minutes').format(),
  Status: 0,
  CreatedOn: '',
  Project: {
    Id: 0,
    Acronym: '',
  },
  Beneficiary: {
    Id: 0,
    ParticipantNumber: '',
    ShortName: '',
    role: 0,
    OtherDirectCosts_TravelCosts: 0,
    Organisation: {
      Id: 0,
      ShortName: '',
      LegalName: '',
      BusinessName: '',
      Country: {
        Id: 0,
        Name: '',
        IsoCode2: '',
      }
    },
    Sponsor: '',
  },
  Travellers: [],
  Meeting: initMeeting,
  Destination: {
    Id: 0,
    Name: '',
    Address: '',
    City: '',
    Latitude: 0,
    Longitude: 0,
    GooglePlusCode: '',
    GoogleMapsLink: '',
    Country: {
      Name: '',
      Id: 0,
    },
  },
  CreatedBy: {
    Id: 0,
    FirstName: '',
    LastName: '',
    FullName: '',
    CallName: '',
  }
}
export {
  createAccountSchemas,
  inits,
  createTimesheetSchemas,
  inits2,
  printTimesheetSchemas,
  createLeaveSchema,
  initLeaveRequest,
  initMeeting,
  createMeetingSchema,
  createReportSchema,
  initTrip,
  createTripSchema,
  initTeam
};
