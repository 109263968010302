import {
  FC,
  ReactNode,
  ReactPortal,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LeaveContext from './leaveContext';
import { LeaveData, LeaveRequest } from '../../../mock/Leave';
import { Employee } from '../../../api-requests/Employees';
import { fetchBalance } from '../../../api-requests/Balance';
import { BalanceData } from '../../../@types/Balance';
import {
  approveSingleLeave,
  fetchApprovalList,
  fetchLeaveById,
  fetchLeaves,
  fetchMyLeaves,
  rejectSignleLeave,
  withdrawLeave,
} from '../../../api-requests/Leave';
import { useAuth } from '../../auth';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { CommonListResponse } from '../../../@types/Response';
import { useNavigate } from 'react-router-dom';

type TUserProviderProps = {
  children: ReactNode | ReactPortal;
};
export type FilterData = {
  id: number;
  label: string;
  value: boolean;
};
export const LeaveProvider: FC<TUserProviderProps> = ({ children }) => {
  const { id } = useAuth();
  const [approvalLeaves, setApprovalLeaves] = useState<LeaveRequest[]>([]);
  const [calendarLeaves, setCalendarLeaves] = useState<LeaveData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [leavesTotal, setLeavesTotal] = useState<number>(0);
  const [balance, setBalance] = useState<BalanceData[]>([]);
  const [myLeaves, setMyLeaves] = useState<LeaveData[]>([]);
  const [filterPeople, setFilterPeople] = useState<Employee[]>([]);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
  const [leaveHistory, setLeaveHistory] = useState<LeaveData[]>([]);
  const navigate = useNavigate();


  const getBalance = async (year: number) => {
    setLoading(true);
    try {
      fetchBalance({ personId: id, year: year }).then((res) => {
        setBalance(res.Results);
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };
  useEffect(() => {
    if (id) {
      getBalance(new Date().getFullYear());
    }
  }, [id]);
  const fetchAllLeaves = async (params: LeaveOptions): Promise<CommonListResponse<LeaveData>> => {
    setLoadingFetch(true);
    const result = await fetchLeaves({ ...params })
    setCalendarLeaves(result.Results);
    setLeavesTotal(result.TotalResults);
    setLoadingFetch(false);
    return result;
  };
  const fetchHistoryLeaves = async (params: LeaveOptions): Promise<CommonListResponse<LeaveData>> => {
    setLoadingFetch(true);
    const result = await fetchLeaves({ ...params })
    setLeaveHistory(result.Results);
    setLeavesTotal(result.TotalResults);
    setLoadingFetch(false);
    return result;
  };
  const fetchApprovals = async (params: LeaveOptions) => {
    setLoadingFetch(true);
    try {
      const approvalListData = await fetchApprovalList({ ...params });
      setApprovalLeaves(approvalListData.Results);
      setLeavesTotal(approvalListData.TotalResults);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingFetch(false);
    }
  };

  const fetchPersonalLeaves = async (params: LeaveOptions) => {
    setLoadingFetch(true);
    try {
      const myLeaves = await fetchMyLeaves({ ...params });
      setMyLeaves([...myLeaves.Results]);
      setLeavesTotal(myLeaves.TotalResults);
    } catch (err) {
      console.warn('error fetching personal leaves', err);
    } finally {
      setLoadingFetch(false);
    }
  };
  const getSingleLeave = async (id: number) => {
    const leave = await fetchLeaveById(id);
    return leave;
  };
  const approveLeave = async (id: number, comments: string) => {
    try {
      await approveSingleLeave({ id: id, comments: comments });
      approvalLeaves.map((app) => {
        if (app.Id === id) {
          app.Status = 1;
          return app;
        } else {
          return app;
        }
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const withdrawSingleLeave = async (id: number) => {
    try {
      await withdrawLeave(id);
      myLeaves.map((leave) => {
        if (leave.Id === id) {
          leave.FinalStatus = 3;
          return leave;
        } else {
          return leave;
        }
      });
    } catch (err) {
      console.log(err)
    }
  };

  const withdrawApprovalLeave = async (id: number) => {
    calendarLeaves.map((leave) => {
      if (leave.Id === id) {
        leave.FinalStatus = 3;
        return leave;
      } else {
        return leave;
      }
    });
  };
  const rejectLeave = async (id: number, comments: string) => {
    try {
      await rejectSignleLeave({ id: id, comments: comments });
      approvalLeaves.map((app) => {
        if (app.Id === id) {
          app.Status = 2;
          return app;
        } else {
          return app;
        }
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const contextValue = useMemo(
    () => ({
      calendarLeaves,
      setCalendarLeaves,
      fetchAllLeaves,
      approvalLeaves,
      fetchApprovals,
      fetchPersonalLeaves,
      loading,
      leavesTotal,
      balance,
      getBalance,
      setApprovalLeaves,
      myLeaves,
      getSingleLeave,
      approveLeave,
      rejectLeave,
      filterPeople,
      setFilterPeople,
      setMyLeaves,
      loadingFetch,
      approveSingleLeave,
      withdrawSingleLeave,
      withdrawApprovalLeave,
      leaveHistory,
      fetchHistoryLeaves,
      setLeaveHistory
    }),
    [
      myLeaves,
      leavesTotal,
      loading,
      balance,
      approvalLeaves,
      calendarLeaves,
      loadingFetch,
      leaveHistory
    ],
  );
  return (
    <LeaveContext.Provider value={contextValue}>
      {children}
    </LeaveContext.Provider>
  );
};
