import React, { useEffect } from 'react'
import { Trip, getTripList } from '../../../api-requests/Meeting'
import { TableProvider } from '../../table/provider/TableContext'
import { useTrip } from './provider/useTrip'
import { pageSelectOptions } from '../../../mock/PageSelection'
import TripTable from './components/TripTable'

type Props = {}

const TripTableWrapper = (props: Props) => {
    const { trips, getTrip, tripsTotal } = useTrip()

    return (
        <TableProvider<Trip>
            data={trips}
            defaultSort={[{ value: 'desc', key: 'LatestArrivalTime' }]}
            page={pageSelectOptions[0]}
            hasStatus={true}
            filters={[
                { Id: 0, label: 'Draft', value: true },
                { Id: 1, label: 'Submitted', value: true },
                { Id: 2, label: 'Approved', value: true },
                { Id: 7, label: 'Rejected', value: true },
                { Id: 6, label: 'Cancelled', value: false }

            ]}
            actions={[getTrip]}
            totalSizeData={tripsTotal}
            fetchData={getTrip}
        >
            <TripTable />
        </TableProvider>
    )
}

export default TripTableWrapper