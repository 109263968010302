import { FC } from 'react';
import { Navigate, Route, Routes, Outlet, useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import PageWrapper from '../components/wrapper/PageWrapper';
import TeamTableWrapper from '../features/humanResources/teams/teamsTableWrapper';
import Header from '../pages/layout/Header';

const { Title } = Typography;
// const leaveBreadcrumbs: Array<PageLink> = [
//   {
//     title: 'Leave list',
//     path: -1,
//     isSeparator: false,
//     isActive: true,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ];

const HumanResourcesRoutes: FC = () => {
    const navigate = useNavigate();
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path="teams/"
                    element={
                        <>
                            <Header title="Teams">
                                <PageWrapper>
                                    <TeamTableWrapper />
                                </PageWrapper>
                            </Header>
                        </>
                    }
                />

            </Route>
            <Route index element={<Navigate to="/apps/user-management/users" />} />
        </Routes>
    );
};

export default HumanResourcesRoutes;
