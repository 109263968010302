import dayjs from "dayjs";
import { Trip } from "../api-requests/Meeting";

export const checkTripConditions = (tripData: Trip, cell: any): boolean => {
    const { Travellers, Meeting, LatestArrivalTime, EarliestDepartureTime } = tripData;

    const { Extended, Justification, DepartFromBase, ReturnToBase } = cell;

    const departFromBaseDate = dayjs(DepartFromBase);
    const returnToBaseDate = dayjs(ReturnToBase);
    const meetingFromDate = dayjs(Meeting.From);
    const meetingToDate = dayjs(Meeting.To);
    const latestArrivalDate = dayjs(LatestArrivalTime);
    const earliestDepartureDate = dayjs(EarliestDepartureTime);
    if (Justification) {
        return true
    }
    // Rule 1: Extended flag is ON and Justification is empty
    if (Extended && (!Justification || Justification.trim() === '')) {
        console.log('Validation failed: Extended flag is ON but Justification is empty');
        return false;
    }

    // Rule 2: Meeting.From - Traveller.DepartFromBase > 1 day
    if (meetingFromDate.diff(departFromBaseDate, 'day') > 1) {
        console.log('Validation failed: Meeting.From - DepartFromBase is more than 1 day apart');
        return false;
    }

    // Rule 3: Meeting.To - Traveller.ReturnToBase > 1 day
    if (returnToBaseDate.diff(meetingToDate, 'day') > 1) {
        console.log('Validation failed: Meeting.To - ReturnToBase is more than 1 day apart');
        return false;
    }

    // Rule 4: LatestArrivalTime - Traveller.ReturnToBase > 1 day
    if (latestArrivalDate.diff(returnToBaseDate, 'day') > 1) {
        console.log('Validation failed: LatestArrivalTime - ReturnToBase is more than 1 day apart');
        return false;
    }

    // Rule 5: EarliestDepartureTime - Traveller.DepartFromBase > 1 day
    if (earliestDepartureDate.diff(returnToBaseDate, 'day') > 1) {
        console.log('Validation failed: EarliestDepartureTime - DepartFromBase is more than 1 day apart');
        return false;
    }


    // If all checks pass
    return true;
};