import { Button } from 'antd';
import { FC } from 'react';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';

type Props = {
  click: () => void;
  icon?: 'add' | 'export';
  text?: string;
  testId?: string;
};

const LoomButton: FC<Props> = ({ click, icon, testId }) => {
  const iconType = () => {
    switch (icon) {
      case 'add':
        return <PlusOutlined />;
      case 'export':
        return <DownloadOutlined />;
      default:
        return <></>;
    }
  };

  return (
    <Button
      data-testid={testId}
      size={"small"}
      icon={iconType()}
      style={{
        border: 'none',
      }}
      className='bg-primary text-white'
      onClick={click}
    />
  );
};

export default LoomButton;
