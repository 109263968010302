export const LeaveColors = {
    0: { key: 'Submitted', value: '#54A0FF' },
    1: { key: 'Approved', value: '#30CB83' },
    2: { key: 'Rejected', value: '#B33771' },
    3: { key: 'Withdrawn', value: '#F1C40F' },
    4: { key: 'Unapproved', value: '#F1C40F' },
} as const;

export type LeaveColorKeys = keyof typeof LeaveColors;
export const TimesheetColors = {
    0: { key: 'Draft', value: '#34495E' },
    1: { key: 'Submitted', value: '#54A0FF' },
    2: { key: 'Approved', value: '#30CB83' },
    4: { key: 'Rejected', value: '#F1C40F' },
    3: { key: 'Rejected', value: '#B33771' },
    7: { key: 'Rejected', value: '#B33771' },
    6: { key: 'Cancelled', value: '#d4db04' }
}
export const AppColors = {
    primary: '#54A0FF',
    secondary: '#EC2A5F',
    black: '#001429',
    darkGrey: '#EFF3F5',
    bgGrey: '#34495E'
}