import { useState } from "react";
import { useNotifications } from "../../../../permissions/components/timesheet/Notification-service";
import { useAuth } from "../../../auth";
import { useNavigate } from "react-router-dom";
import useTableContext from "../../../table/provider/TableContext";
import { Button, Table, TableColumnsType, Tooltip } from "antd";
import { useTrip } from "../provider/useTrip";
import DateFormatCell from "../../../../components/cells/DateFormatCell";
import { approveTrip, Meeting, rejectTrip, Trip } from "../../../../api-requests/Meeting";
import LocationCell from "../../../../components/cells/LocationCell";
import ParticipantsCell from "../../../../components/cells/ParticipantsCell";
import { EditOutlined, DeleteOutlined, EyeOutlined, CarOutlined, CheckOutlined, RightOutlined } from '@ant-design/icons';
import ActionCell from "../../../../components/cell/ActionCell";
import { AxiosError } from "axios";
import WarningModal from "../../../../components/modal/WarningModal";
import { LeaveColors } from "../../../../../shared/colors";
import dayjs from "dayjs";
import TravellersCell from "../../../../components/cells/TravellersCell";
import TableFilters, { ExtendedColumnType } from "../../../../utils/TableFilters/TableFilters";
import { ExtendedTableColumnType } from "../../../timesheet/components/TimesheetTable";
import LeaveStatusCell from "../../../../components/cells/LeaveStatusCell";
import StatusCell from "../../../../components/cells/StatusCell";


const TripApprovalTable = () => {
    const { id } = useAuth();
    const [columns, setColumns] = useState<ExtendedTableColumnType<Trip>[]>([
        {
            dataIndex: 'Trip',
            key: 'Trip.Title',
            title: 'Title',
            default: true,
            sorter: { multiple: 1 },
            align: 'start',
            render: (cell) => <span>{cell.Title}</span>,
            width: 300,
        },
        {
            dataIndex: 'Trip',
            key: 'Project.Acronym',
            title: 'Project',
            default: false,

            align: 'start',
            render: (cell) => <span className="font-semibold">{cell.Project.Acronym}</span>,
            width: 150,
        },
        {
            dataIndex: 'Trip',
            key: 'Beneficiary.Organisation.BusinessName',
            title: 'Beneficiary',
            default: false,
            align: 'start',
            render: (cell) => <span className="">{cell.Beneficiary.Organisation.BusinessName}</span>,
            width: 175,
        },
        {
            dataIndex: 'Trip',
            key: 'Meeting.Name',
            title: 'Meeting',
            default: false,

            align: 'start',
            render: (cell) => <span className="">{cell.Meeting.Name}</span>,
            width: 175,
        },
        {
            dataIndex: 'Trip',
            key: 'Trip.LatestArrivalTime',
            title: 'Latest Arrival Time',
            default: false,
            sorter: { multiple: 1 },
            align: 'start',
            render: (cell) => <DateFormatCell date={cell.LatestArrivalTime} time={true} />,
            width: 300,
        },

        {
            dataIndex: 'Trip',
            key: 'Trip.EarliestDepartureTime',
            title: 'Earliest Departure Time',
            sorter: { multiple: 1 },

            default: false,
            align: 'start',
            render: (cell) => <DateFormatCell date={cell.EarliestDepartureTime} time={true} />,
            width: 300,
        },
        {
            dataIndex: 'Trip',
            key: 'Trip.Travellers',
            title: 'Travellers',
            default: false,
            align: 'start',
            render: (cell) => <TravellersCell cell={cell.Travellers} />,
            width: 200,
        },
        {
            dataIndex: 'Trip',
            key: 'Destination.Country.Name',
            title: 'Destination',
            default: false,
            align: 'start',
            render: (cell) => <span className="">{cell.Destination.Country ? cell.Destination.Country.Name : ''}</span>,
            width: 175,
        },
        {
            dataIndex: 'Approver',
            key: 'Title',
            title: 'Approver',
            default: false,
            align: 'start',
            render: (cell) => <span>{cell.FullName}</span>,
            width: 150,
        },
        {
            dataIndex: 'Status',
            key: 'Status',
            title: 'Status',
            default: false,
            align: 'start',
            render: (cell) => <StatusCell status={cell} />,
            width: 150,
        },
        {
            dataIndex: 'Trip',
            key: 'Purpose',
            title: 'Purpose',
            default: false,
            align: 'start',
            render: (cell) => <span className="">{cell.Purpose}</span>,
            width: 175,
        },
        {
            width: 100,
            key: 'actions',
            default: false,
            title: 'Actions',
            render: (cell) => (
                <ActionCell
                    data={cell}
                    items={[
                        cell.Status === 1 ? {
                            key: 'view_trip',
                            label: 'View Trip Details',
                            icon: <RightOutlined />,
                            onClick: () => {
                                navigate('/apps/projects/meetings/trip/approval/' + cell.Trip.Id, { state: { data: cell.Trip, approving: true, id: cell.Id } })
                            }
                        } : {
                            key: 'view_trip',
                            label: 'View Trip Details',
                            icon: <RightOutlined />,
                            onClick: () => {
                                navigate('/apps/projects/meetings/trip/approval/' + cell.Trip.Id, { state: { data: cell.Trip, approving: true, id: cell.Id } })
                            }
                        }
                    ]}
                // actions={[
                //     id === cell.CreatedBy?.Id ? <div className='flex gap-2' key={cell.Id}>
                //         <Tooltip title="Edit Trip" key={cell.Id + "_edit"}>
                //             <Button
                //                 onClick={() => {
                //                     navigate('/apps/projects/meetings/trip/' + cell.Id, { state: { data: cell } })
                //                 }}
                //                 size="small"
                //                 data-testid={`cloneTimesheetBtn-${cell.Id}`}
                //                 icon={<EditOutlined />}
                //             />

                //         </Tooltip>
                //         <Tooltip title={dayjs(cell.EarliestDepartureTime).isAfter(new Date()) ? "Delete Trip" : 'You cannot delete past trips'} key={cell.Id + '_delete'} className='gap-2 flex'>
                //             <Button
                //                 disabled={!dayjs(cell.EarliestDepartureTime).isAfter(new Date())}
                //                 onClick={() => { setWarningOpen(true); setActiveTrip(cell.Id) }}
                //                 size="small"
                //                 icon={<DeleteOutlined />}
                //             />
                //         </Tooltip >

                //     </div> :
                //         <Tooltip title="View Trip" key={cell.Id + '_delete'} className='gap-2 flex'>
                //             <Button
                //                 onClick={() => {
                //                     navigate('/apps/projects/meetings/trip/' + cell.Id, { state: { data: cell } })
                //                 }}
                //                 size="small"
                //                 icon={<EyeOutlined />}
                //             />
                //         </Tooltip >
                // ]
                // }
                />
            ),
            fixed: 'right',
            align: 'center',
        },
    ])
    const { trips, tripsTotal, loading, getTrip, handleDeleteTripById, tripApprovals, getApprovalTrips, tripApprovalTotal } = useTrip();
    const { openNotification } = useNotifications();
    const navigate = useNavigate();
    const {
        pageSize,
        currentPage,
        handleTableChange
    } = useTableContext();

    return (
        <>

            <TableFilters<Trip>
                hasStatus
                localStorageKey="TripApprovalTableColumns"
                addNavigate={() => navigate("/apps/projects/meetings/trip/create")}
                hasAdd={false}
                setIsCreateModal={() => { }}
                columns={columns}
                newColumns={columns}
                setNewColumns={setColumns}
                assigneesFilter={true}
            />
            <Table<Trip>
                columns={columns}
                dataSource={tripApprovals}
                size={'middle'}
                scroll={{ x: 1200 }}
                pagination={{
                    size: 'default',
                    position: ['bottomCenter'],
                    current: currentPage,
                    pageSize: pageSize?.value,
                    total: tripApprovalTotal,
                    pageSizeOptions: ['10', '25', '50'],
                }}
                sortDirections={['ascend', 'descend']}
                onChange={handleTableChange}
                loading={loading}
                rowKey={'Id'}
                className='custom-antd-header-bg'
            />
        </>
    );
};

export default TripApprovalTable;
