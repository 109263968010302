
import { Typography } from 'antd';
import { useMeetings } from './provider/useMeetings';
import { TableProvider } from '../../table/provider/TableContext';
import { Meeting } from '../../../api-requests/Meeting';
import { pageSelectOptions } from '../../../mock/PageSelection';
import MeetingTable from './components/MeetingTable';


const MeetingTableWrapper = () => {
    const { meetings, meetingsTotal, getMeeting } = useMeetings()
    return (
        <TableProvider<Meeting>
            data={meetings}
            hasStatus={false}
            defaultSort={[{ value: 'desc', key: 'From' }]}
            page={pageSelectOptions[0]}
            filters={[
                { Id: 0, label: 'Submitted', value: true },
                { Id: 1, label: 'Approved', value: true },
                { Id: 2, label: 'Rejected', value: true },
            ]}
            actions={[getMeeting]}
            totalSizeData={meetingsTotal}
            fetchData={getMeeting}
            start={new Date().toISOString()}
        >
            <MeetingTable />
        </TableProvider>
    );
};

export default MeetingTableWrapper;
