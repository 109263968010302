import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { mockedEmployees } from '../mock/Employees';
import { CommonListResponse } from '../@types/Response';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const FETCH_EMPLOYEES = `${API_URL}/employees`;
export const FETCH_RELATED_EMPS = `${API_URL}/employees/people`;
export const FETCH_TEAMS = `${API_URL}/teams/all`
export const CREATE_TEAM = `${API_URL}/teams`;
export interface FetchEmployeesOptions {
  searchTerm?: string;
  pageIndex?: number;
  pageSize?: number;
  sorting?: string;
  includeInactive: boolean;
  orgId?: number;
  projectId?: number;
  fetchAllEmployees?: boolean;
}

export const createTeamApi = async (values: Team) => {
  try {
    const response = await axios.post<''>(CREATE_TEAM, values);
    return response.data;
  } catch (error) {
    console.error('Error creating team:', error);
    throw error;
  }
}
export const fetchEmployees = async (
  { includeInactive, orgId, projectId, fetchAllEmployees, searchTerm }: FetchEmployeesOptions = { includeInactive: false, projectId: 0, fetchAllEmployees: false, searchTerm: '' },
) => {
  try {
    const params = new URLSearchParams({
      includeInactive: includeInactive!.toString(),
      orgId: orgId ? orgId.toString() : '0',
      projectId: projectId ? projectId?.toString() : '0',
      fetchAllEmployees: fetchAllEmployees ? String(fetchAllEmployees) : "false",
      searchTerm: searchTerm ? searchTerm : '',
    });

    const response = await axios.get<CommonListResponse<Employee>>(
      `${FETCH_EMPLOYEES}?${params.toString()}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchRelatedPeople = async ({ includeInactive, searchTerm, pageSize, projectId }: FetchEmployeesOptions = { includeInactive: false, pageSize: 10, projectId: 0 }) => {
  try {
    const params = new URLSearchParams({
      searchTerm: searchTerm ? searchTerm : '',
      includeInactive: includeInactive!.toString(),
      pageSize: "500",
    });
    const response = await axios.get<CommonListResponse<EmployeeSimple>>(
      `${FETCH_RELATED_EMPS}?${params.toString()}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching related emps');
    throw error;
  }
};
export const fetchEmployeesPresence = async (
  date: string | Date,
  searchTerm: string,
) => {
  try {
    return mockedEmployees.filter((emp) => emp.FullName.includes(searchTerm));
  } catch (error) {
    console.error('Error fetching employees for ', date, error);
    throw error;
  }
};

export const deleteTeamId = async (id: Number) => {
  try {
    const response = axios.delete(`${CREATE_TEAM}?id=${id}`);
    return response;
  } catch (err) {
    throw err;
  }
}
export const fetchTeams = async ({
  pageIndex = 0,
  pageSize = 10,
  searchTerm = '',
  status = '',
  personIds = '',
  includeInactive = false,
}) => {
  try {
    const params = new URLSearchParams({
      searchTerm: searchTerm ? searchTerm : '',
      includeInactive: includeInactive!.toString(),
      status,
      pageIndex: pageIndex.toString(),
      pageSize: pageSize.toString(),
      personIds
    });
    const response = await axios.get<CommonListResponse<Team>>(
      `${FETCH_TEAMS}?${params.toString()}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching teams');
    throw error;
  }
}
export interface EmployeeSimple {
  BasedInCountry?: {
    ISOCode2: string;
    Id: number;
    Name: string;
    WorkingHoursOfDay: number;
  };
  Id: number;
  CallName: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  Gender?: number;
  avatarColour?: string;
}
export interface Employee {
  PersonId: number;
  FirstName: string;
  LastName: string;
  FullName: string;
  CallName: string;
  EmployeeId: number;
  OrgId: number;
  OrgShortName: string;
  OrgName: string;
  BasedInCountryName: string;
  BasedInCountryCode: string;
  Email: string;
}
export interface RelatedEmployee {
  id: 0;
  firstName: 'string';
  lastName: 'string';
  fullName: 'string';
  callName: 'string';
  gender: 0;
  basedInCountry: {
    id: 0;
    name: 'string';
    isoCode2: 'string';
    workingHoursOfDay: 0;
  };
}
export interface EmployeeWithAvatar extends Employee {
  avatarColour: string;
}
export type TeamType = {
  Id: number,
  Name: string,
  Leader: EmployeeSimple,
  Members: EmployeeSimple[],
}
export interface Team {
  Id: number;
  Name: string;
  Leader: EmployeeSimple;
  Members: EmployeeSimple[];
  active?: boolean;
  Organisation: {
    Id: number;
    ShortName: string;
    LegalName: string;
    BusinessName: string;
  } | null
  CreatedBy?: {
    Id: number
  }
}