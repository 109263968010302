import axios from 'axios';
import envConfig from '../../../../enviroment/enviroment';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');

export const MICROSOFT_LOGIN_URL = `${API_URL}/oauth/token`;

export const microsoftLogin = (nonce: string, token: string) => {
  return axios.post<LoginResponse>(MICROSOFT_LOGIN_URL, {
    id_token: token,
    session_state: nonce,
  });
};

export type LoginResponse = {
  Expiration: string;
  Token: string;
  Username: string;
};
