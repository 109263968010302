import React, { useEffect } from 'react'
import { Trip, getTripList } from '../../../api-requests/Meeting'
import { TableProvider } from '../../table/provider/TableContext'
import { useTrip } from './provider/useTrip'
import { pageSelectOptions } from '../../../mock/PageSelection'
import TripTable from './components/TripTable'
import TripApprovalTable from './components/TripApprovalTable'

type Props = {}

const TripTableApprovalWrapper = (props: Props) => {
    const { tripApprovals, getApprovalTrips } = useTrip()

    return (
        <TableProvider<Trip>
            data={tripApprovals}
            defaultSort={[]}
            page={pageSelectOptions[0]}
            hasStatus
            filters={[
                { Id: 0, label: 'Draft', value: false },
                { Id: 1, label: 'Submitted', value: true },
                { Id: 2, label: 'Approved', value: false },
                { Id: 7, label: 'Rejected', value: false },
            ]}
            actions={[getApprovalTrips]}
            totalSizeData={100}
            fetchData={getApprovalTrips}
        >
            <TripApprovalTable />
        </TableProvider>
    )
}

export default TripTableApprovalWrapper