/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { Logout, useAuth } from '../features/auth';
import { App } from '../App';
import Callback from '../features/auth/components/Callback';
import AuthRoutes from './AuthRoutes';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  return (
    <Routes>
      <Route element={<App />}>
        {/* <Route path="error/*" element={<ErrorsPage />} /> */}
        <Route path="logout" element={<Logout />} />
        {currentUser ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="auth/callback/*" element={<Callback />} />
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
