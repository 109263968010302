import { Button, Checkbox, DatePicker, Drawer, Dropdown, Grid, Input, Select, Space, Switch, TableColumnType, Typography } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { ReactElement, useEffect, useState } from 'react'
import moment from 'moment';

import { SettingOutlined } from '@ant-design/icons';
import useTableContext from '../../features/table/provider/TableContext';
import { DebouncedInput } from '../../components/DebouncedInput/DebouncedInput';
import { LeaveOptions } from '../../@types/FetchOptions';
import { AssigneesButton, MeModeButton } from '../../features/leave/components/CalendarFilters';
import LoomButton from '../../components/ui/LoomButton';
import { fetchOrganisation, FetchOrganisationType } from '../../api-requests/Organisation';
import { ExtendedTableColumnType } from '../../features/timesheet/components/TimesheetTable';
import { ColumnTitle, Key } from 'antd/es/table/interface';



const { useBreakpoint } = Grid;

export type ExtendedColumnType<T> = {
    key: string;
    title: string;
    hidden: boolean;
    dataIndex: string;
    defaultSortOrder?: 'ascend' | 'descend';
} & TableColumnType<T>;


type Props<T> = {
    extendedCalendar?: boolean;
    Export?: ReactElement;
    hasAdd?: boolean;
    setIsCreateModal?: (b: boolean) => void;
    meModeFilter?: boolean;
    addNavigate: (() => void);
    columns: ExtendedTableColumnType<T>[];
    setNewColumns: React.Dispatch<React.SetStateAction<TableColumnType<T>[]>>;
    newColumns: TableColumnType<T>[];
    localStorageKey: string;
    hasStatus?: boolean;
    hasFilters?: boolean;
    hasOrg?: boolean;
    assigneesFilter?: boolean;
    dropdown?: React.ReactElement;
}


const { RangePicker } = DatePicker;
const DefaultOrg: FetchOrganisationType = {
    Id: 0,
    BusinessName: 'All',
    ShortName: 'All',
    LegalName: 'All',
};
const TableFilters = <T,>({ Export, dropdown, assigneesFilter = false, hasOrg = false, hasFilters = true, meModeFilter = true, hasAdd = true, addNavigate, columns, newColumns, setNewColumns, localStorageKey, hasStatus = false }: Props<T>) => {
    const { md } = useBreakpoint();
    const {
        handleSearch,
        handleCheckboxChange,
        filterStates,
        searchTerm,
        handleRangeDate,
        handleOrgFilter,
        orgId,
    } = useTableContext();
    const [openCustomisation, setOpenCustomisation] = useState<boolean>(false)
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [organisations, setOrganisations] = useState<FetchOrganisationType[]>(
        [],
    );
    const [options, setOptions] = useState<{ label: ColumnTitle<T>, value: Key | undefined }[]>(columns.map(({ key, title }) => ({
        label: title,
        value: key,
    })));
    useEffect(() => {
        const storedData = window.localStorage.getItem(localStorageKey);
        if (storedData) {
            const data = JSON.parse(storedData) as ExtendedTableColumnType<T>[];
            if (data)
                setCheckedList(data.filter((t) => !t.hidden).map((t) => t.key as string))
            if (data.filter((d) => !d.hidden).length === 0) {
                setCheckedList(data.filter((t) => t.default).map((t) => t.key as string))
            }
        } else {
            setCheckedList(columns.map((t) => t.key as string))
        }
        if (hasOrg) {
            fetchOrganisation()
                .then((res) => setOrganisations([DefaultOrg, ...res]))
                .catch((err) => console.log(err));
        }
    }, []);
    useEffect(() => {
        const updatedColumns = columns.map(item => ({
            ...item,
            hidden: !checkedList.includes(item.key as string),
        }));

        setNewColumns(prevColumns => {
            if (JSON.stringify(prevColumns) !== JSON.stringify(updatedColumns)) {
                return updatedColumns;
            }

            return prevColumns;
        });

    }, [columns, checkedList]);

    useEffect(() => {
        window.localStorage.setItem(localStorageKey, JSON.stringify(newColumns));
    }, [newColumns]);

    const handleSearchOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value.toLowerCase();
        if (searchText) {
            setOptions(options.filter((opt) => {
                if (opt.label && opt.value) {
                    const valueString = typeof opt.value === 'string' ? opt.value : String(opt.value);
                    const labelString = typeof opt.label === 'string' ? opt.label : String(opt.label);
                    const lowercasedSearchText = searchText.toLowerCase();

                    return valueString.toLowerCase().includes(lowercasedSearchText) || labelString.toLowerCase().includes(lowercasedSearchText);
                }
            }
            ));
        } else {
            setOptions(columns.map(({ key, title }) => ({
                label: title,
                value: key,
            })))
        }

    };
    return (
        <div className="flex w-full justify-between mb-6">
            <Drawer
                width={300}
                title="Fields"
                onClose={() => setOpenCustomisation(false)}
                open={openCustomisation}
            >
                <div className='flex gap-4 flex-col'>
                    <div>
                        <Input placeholder='Search...' onChange={(text) => handleSearchOptions(text)} allowClear />
                    </div>
                    <Checkbox.Group
                        value={checkedList}
                        onChange={(value) => {
                            setCheckedList(value as string[]);
                        }}
                        key={'12'}
                        className='flex justify-center  flex-col gap-4'
                    >
                        <div className='flex flex-row justify-between'>
                            <Typography style={{ color: '#6e6e6e', fontSize: 12 }}>Shown</Typography>
                            {checkedList.length > 0 && (
                                <Typography
                                    className='cursor-pointer'
                                    style={{ fontSize: 12 }}
                                    onClick={() => setCheckedList(columns.filter((opt) => opt.default).map((t) => t.key as string))}
                                >
                                    Hide all
                                </Typography>
                            )}
                        </div>
                        {options.map((opt) => {
                            const valueString = typeof opt.value === 'string' ? opt.value : String(opt.value);
                            const labelString = typeof opt.label === 'string' ? opt.label : String(opt.label);
                            if (checkedList.includes(valueString))
                                return (
                                    <div className='flex flex-row justify-between' key={opt.value}>
                                        <div>
                                            <Typography>{labelString}</Typography>
                                        </div>
                                        <Switch
                                            style={{ background: '#7f77f1' }}
                                            size={"small"}
                                            checked={checkedList.includes(valueString)}
                                            onChange={(value) => {

                                                if (value) {
                                                    setCheckedList([...checkedList, valueString]);
                                                } else {
                                                    setCheckedList(checkedList.filter((item) => item !== opt.value));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                        })}

                        <div className='flex justify-between'>
                            <Typography style={{ color: '#6e6e6e', fontSize: 12 }}>Hidden</Typography>
                            {checkedList.length !== columns.length && (
                                <Typography
                                    className='cursor-pointer'
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        const storedData = window.localStorage.getItem(localStorageKey);
                                        if (storedData) {
                                            const data = JSON.parse(storedData) as ExtendedColumnType<T>[];
                                            if (data) {
                                                setCheckedList(data.map((t) => t.key as string));
                                            }
                                        }
                                    }}
                                >
                                    Show all
                                </Typography>
                            )}
                        </div>
                        {options.map((opt) => {
                            const valueString = typeof opt.value === 'string' ? opt.value : String(opt.value);
                            const labelString = typeof opt.label === 'string' ? opt.label : String(opt.label);
                            // Convert searchText to lowercase
                            if (!checkedList.includes(valueString))
                                return (
                                    <div className='flex flex-row justify-between' key={opt.value}>
                                        <Typography>{labelString}</Typography>
                                        <Switch
                                            size={"small"}
                                            checked={checkedList.includes(valueString)}
                                            onChange={(value) => {
                                                if (value) {
                                                    setCheckedList([...checkedList, valueString]);
                                                } else {
                                                    setCheckedList(checkedList.filter((item) => item !== opt.value));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                        })}
                    </Checkbox.Group>

                </div>
            </Drawer>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="example-header pe-3 flex flex-row gap-2">
                    <DebouncedInput
                        onChange={handleSearch}
                        initValue={searchTerm}
                        className="form-control form-control-solid w-250px ps-14 mb-2"
                        placeholder="Search..."
                        type="search"
                    />
                    {hasOrg && (
                        <div data-testid="selectOrg" className='md:min-w-[200px] min-w-[100px]'>
                            <Select<number>
                                style={{ width: '100%' }}
                                placeholder="Organisation"
                                value={organisations.filter((org) => org.Id === orgId)[0]?.Id}
                                onChange={(newValue) => {
                                    handleOrgFilter(newValue);
                                }}
                                options={organisations.map((d) => {
                                    return {
                                        label: d.BusinessName,
                                        value: d.Id,
                                    };
                                })}
                            />
                        </div>
                    )}

                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Space>
                    {dropdown && dropdown}
                    {meModeFilter && <MeModeButton />}
                    {assigneesFilter && <AssigneesButton />}
                    <Button icon={<SettingOutlined />} className="md:rounded-xl " size={"small"} onClick={() => { setOpenCustomisation(!openCustomisation) }}>{md && 'Customise'}</Button>
                    {hasFilters && (<Dropdown
                        placement="topLeft"
                        dropdownRender={() => (
                            <div
                                style={{
                                    padding: 10,
                                    background: 'white',
                                    border: '1px solid #ede8e8',
                                    borderRadius: '0.75rem',
                                    boxShadow: '1px 1px 8px 2px #f5f4f4',
                                }}
                            >
                                <div
                                    className="flex flex-col"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {hasStatus && <div className="lg:px-5 px-5 lg:w-[400px] w-[200px]" >
                                        <h4 className="text-center mb-2">Filter by status</h4>
                                        <div className='grid grid-cols-12 mb-0'>
                                            {filterStates.map((checkbox, index: number) => (
                                                <div className="lg:col-span-6 col-span-12 my-2 mb-2" key={index}>
                                                    <div className="flex lg:gap-2 gap-4" key={checkbox.label}>
                                                        <Switch
                                                            id={checkbox.label}
                                                            checked={checkbox.value}
                                                            onChange={() => {
                                                                handleCheckboxChange(checkbox.Id);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={checkbox.label}
                                                        >
                                                            {checkbox.label}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>}
                                    <div
                                        className="fv-row"
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        {/* begin::Form group Lastname */}
                                        <h4 className="text-center">Date range</h4>
                                        <RangePicker
                                            onChange={(date) => {
                                                if (date !== null) {
                                                    handleRangeDate([
                                                        moment(date[0]?.toDate()).format(
                                                            'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
                                                        ),
                                                        moment(date[1]?.toDate()).format(
                                                            'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
                                                        )]
                                                    );
                                                }
                                                else {
                                                    handleRangeDate([]);
                                                }
                                            }}
                                            size={'large'}
                                            className=" cursor-pointer"
                                            format="DD-MM-YYYY"
                                            style={{
                                                display: 'flex',
                                            }}
                                        />
                                    </div>
                                    {/* <div className='p-5'>
                                        <h4 className="text-center">Columns</h4>
                                        <Checkbox.Group
                                            value={checkedList}
                                            onChange={(value) => {
                                                console.log(value);
                                                setCheckedList(value as string[]);
                                            }}
                                            className='flex justify-center'
                                        >
                                            <Row>
                                                {options.map((opt:any, i:number) => (
                                                    <Col span={6} key={i}>
                                                        <Checkbox value={opt.value}>{opt?.label}</Checkbox>
                                                    </Col>
                                                ))}

                                            </Row>
                                        </Checkbox.Group>
                                    </div> */}
                                </div>
                            </div>
                        )}

                    >
                        <Button
                            icon={<FilterOutlined />}
                            color={'white'}
                            size="small"
                            className='md:rounded-xl'
                        >{md && 'Filters'}</Button>
                    </Dropdown>)}
                    {Export}
                    {hasAdd && <LoomButton icon={'add'} click={() => addNavigate()} />}
                </Space>
            </div>
        </div>
    )
}

export default TableFilters