import React, { useEffect, useRef, useState } from 'react';
import WarningModal from '../../../../components/modal/WarningModal';
import {
    Button,
    Collapse,
    CollapseProps,
    Select,
    Steps,
    Tooltip,
    Typography,
} from 'antd';
import {
    AppColors,
    LeaveColors,
    TimesheetColors,
} from '../../../../../shared/colors';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import {
    Meeting,
    Travellers,
    Trip,
    addTraveler,
    approveTrip,
    deleteTripById,
    fetchMeetingById,
    fetchMeetings,
    rejectTrip,
    removeTraveler,
    saveTrip,
    submitTrip,
} from '../../../../api-requests/Meeting';
import {
    createMeetingSchema,
    createTripSchema,
    initMeeting,
    initTrip,
} from '../../../../utils/FormValidation/CreateAccountWizardHelper';
import TripBasicInformation from './TripBasicInformation';
import Location from './Location';
import { useAuth } from '../../../auth';
import {
    InfoCircleOutlined,
    CheckOutlined,
    EnvironmentOutlined,
    UserOutlined,
    ProductOutlined,
    CloseOutlined,
    SaveOutlined,
    SendOutlined
} from '@ant-design/icons';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import MeetingDrawer from '../../meeting/components/MeetingDrawer';
import Prompt from '../../../../utils/useNavBlocker/useNavBlocker';
import AttendeesTransfer from './AttendeesTransfer';
import { useTrip } from '../provider/useTrip';
import FormCard from '../../../../utils/Forms/FormCard';
import BenefeciariesTable from './BeneficiariesTable';
import { fetchBeneficiaries } from '../../../../api-requests/Projects';
import TripContext from './TripContext';
import ActionBtn from '../../../../components/buttons/ApproverRejectActionBtn';
type Props = {
    data: any;
    approving?: boolean,
    approveId?: number;
    redirecting?: boolean;
    edit?: boolean;
};

const TripForm = ({ data, approving, approveId, redirecting, edit }: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { beneficiaries } = useTrip();
    const [warningOpen, setWarningOpen] = useState(false);
    const [warningOpenReject, setWarningOpenReject] = useState(false);
    const formRef = useRef<FormikProps<Trip> | null | undefined>();
    const { openNotification } = useNotifications();
    const [dataMeeting, setDataMeeting] = useState<Meeting | undefined>();
    const [duplicateTrip, setDuplicateTrip] = useState(false);
    const [navTrip, setNavTrip] = useState<Trip[]>([]);
    const [collapseDrawer, setCollapseDrawer] = useState(false);
    const [single, setSingle] = useState(0);

    const { id, } = useAuth();
    const navigate = useNavigate();

    const steps = [
        {
            id: 0,
            title: 'Draft',
            content: 'First-content',
            icon: <SaveOutlined />,
            background: TimesheetColors[0].value
        },
        {
            id: 1,
            title: 'Submitted',
            content: 'Second-content',
            icon: <SendOutlined />,
            background: TimesheetColors[1].value

        },
        {
            id: 2,
            title: 'Approved',
            content: 'Last-content',
            icon: <CheckOutlined />,
            background: TimesheetColors[2].value
        },
        {
            id: 7,
            title: 'Rejected',
            content: 'Last-content',
            icon: <CloseOutlined />,
            background: TimesheetColors[3].value

        },
    ];

    useEffect(() => {
        const fetch = async () => {
            console.log(data);
            if (formRef.current?.values.Meeting.Id !== 0 && data.Id === 0 && !approving && !edit && !redirecting) {
                const test = await fetchBeneficiaries({ projectId: formRef.current?.values.Meeting.Project.Id });
                if (test.length !== 1) {
                    const duplciate = await fetchMeetingById(Number(formRef.current?.values.Meeting.Id));
                    if (duplciate.Trips.length > 0) {
                        setDuplicateTrip(true);
                    }
                }
            }
        };
        fetch();
    }, [formRef.current?.values.Meeting?.Id]);

    const handleDelete = async () => {
        try {
            await deleteTripById(data.Id);
            if (formRef.current)
                openNotification('topRight', {
                    title: `Trip`,
                    description: 'Trip deleted successfully for meeting:' + formRef.current.values.Meeting.Name + ' ' + formRef.current.values.Project.Acronym,
                    type: 'Success',
                });

            navigate('/apps/projects/meetings/trip/list');
        } catch (error) {
            if (error instanceof AxiosError) {
                const txtErr = error?.response?.data?.error;
                openNotification('topRight', {
                    title: `Trip`,
                    description: txtErr,
                    type: 'Danger',
                });
            }
        }
    };

    const submitStep = async (values: Trip) => {
        try {
            setIsSubmitting(true);
            values.Title =
                values.Destination?.Country?.Name || values.Project.Id !== 0
                    ? 'Trip to ' +
                    values.Destination.Country?.Name +
                    ', for Project: ' +
                    values.Project.Acronym
                    : values.Title;
            const test = await saveTrip({ ...values });
            await submitTrip(Number(test));
            if (data) {
                if (formRef.current)
                    openNotification('topRight', {
                        title: `Trip`,
                        description: 'Trip updated successfully for meeting:' + formRef.current.values.Meeting.Name + ' ' + formRef.current.values.Project.Acronym + '. Approver:' + formRef.current.values.Beneficiary.Sponsor,
                        type: 'Success',
                    });
                navigate('/apps/projects/meetings/trip/list');
            } else {
                if (formRef.current)
                    openNotification('topRight', {
                        title: `Trip`,
                        description: `Trip created successfully.` + '. Approver:' + formRef.current.values.Beneficiary.Sponsor,
                        type: 'Success',
                    });
                navigate(-1);
            }
            formRef.current?.resetForm();
            setIsSubmitting(false);

        } catch (err) {
            if (err instanceof AxiosError) {
                setIsSubmitting(false);
                openNotification('topRight', {
                    title: `Trip`,
                    description: err?.response?.data.error,
                    type: 'Danger',
                });
            }
        }
    };
    const saveStep = async (values: Trip) => {
        try {
            setIsSubmitting(true);
            values.Title =
                values.Destination?.Country?.Name || values.Project.Id !== 0
                    ? 'Trip to ' +
                    values.Destination.Country?.Name +
                    ', for Project: ' +
                    values.Project.Acronym
                    : values.Title;
            const test = await saveTrip({ ...values });

            openNotification('topRight', {
                title: `Trip`,
                description: `Trip saved successfully.`,
                type: 'Success',
            });
            navigate(-1);

            formRef.current?.resetForm();
            setIsSubmitting(false);

        } catch (err) {
            if (err instanceof AxiosError) {
                setIsSubmitting(false);
                openNotification('topRight', {
                    title: `Trip`,
                    description: err?.response?.data.error,
                    type: 'Danger',
                });
            }
        }
    };
    const handleReject = async (comments?: string) => {
        if (approveId)
            try {
                await rejectTrip({ id: approveId, comments: comments ? comments : '' });
                if (formRef.current)
                    openNotification("topRight", {
                        title: `Trip`,
                        description: 'Trip rejected successfully for meeting:' + formRef.current.values.Meeting.Name + ' ' + formRef.current.values.Project.Acronym,
                        type: 'Success',
                    })
                navigate(-1);
            } catch (error) {
                if (error instanceof AxiosError) {
                    const txtErr = error?.response?.data?.error;
                    openNotification('topRight', {
                        title: `Trip`,
                        description: txtErr,
                        type: 'Danger',
                    });
                    setWarningOpenReject(false)
                }
            }
    }
    const handleApprove = async (comments?: string) => {
        if (approveId)
            try {
                await approveTrip({ id: approveId, comments: comments ? comments : '' });
                if (formRef.current)
                    openNotification('topRight', {
                        title: 'Trip',
                        description: 'Trip approved successfully for meeting:' + formRef.current.values.Meeting.Name + ' ' + formRef.current.values.Project.Acronym,
                        type: 'Success',
                    });
                navigate(-1);
            } catch (error) {
                if (error instanceof AxiosError) {
                    const txtErr = error?.response?.data?.error;
                    openNotification('topRight', {
                        title: `Trip`,
                        description: txtErr,
                        type: 'Danger',
                    });
                }
            }
    }
    return (
        <>
            <div className='flex flex-row gap-4 items-center mb-4 mt-8'>
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className='flex flex-row items-center gap-1' style={{ opacity: (step.id === data.Status) ? 1 : 0.3 }}>
                            <span className='rounded-full p-1 flex justify-center items-center' style={{ border: step.id === data.Status ? '1px solid' + step.background : '1px solid gray', background: step.id === data.Status ? step.background : '', color: step.id === data.Status ? 'white' : 'gray' }}>{step.icon}</span>
                            <span >{step.title}</span>
                        </div>
                        {index < steps.length - 1 && <hr className='w-full border-gray-50' />}
                    </React.Fragment>
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', }}>

                <div data-testid="calendarModal">
                    <Prompt formRef={formRef} isSubmitting={isSubmitting} />
                    <Formik
                        innerRef={(formikProps) => {
                            if (formikProps) {
                                formRef.current = formikProps;
                            }
                        }}
                        validationSchema={createTripSchema}
                        initialValues={data}
                        enableReinitialize
                        onSubmit={async (values, { resetForm }) => {
                            await submitStep(values);
                        }}
                    >
                        {({
                            values,
                            setFieldValue,
                            handleSubmit,
                            resetForm,
                        }) => {
                            return (
                                <>
                                    <div className="flex justify-end px-2">
                                        <WarningModal
                                            title="Multiple Beneficiaries Detected"
                                            isOpen={duplicateTrip}
                                            setIsOpen={() => { resetForm(); setDuplicateTrip(false); }}
                                            footerButtons={[
                                                <Button key={'close'} onClick={() => { resetForm(); setDuplicateTrip(false) }}>
                                                    Close
                                                </Button>,

                                                <Button
                                                    key={'action'}
                                                    style={{ background: LeaveColors[0].value, color: 'white' }}
                                                    onClick={async () => {
                                                        const tripExist = values.Meeting.Trips.filter((trip: Trip) => trip.Beneficiary.Id && trip.Beneficiary.Id === single)[0];
                                                        if (tripExist) {
                                                            await resetForm();
                                                            navigate('/apps/projects/meetings/trip/' + tripExist.Id, { state: { data: tripExist, edit: true } })
                                                        } else {
                                                            setFieldValue('Beneficiary', beneficiaries.filter((ben) => ben.Id === single)[0]);
                                                            setDuplicateTrip(false);
                                                        }
                                                    }}
                                                >
                                                    {values.Meeting.Trips.filter((trip: Trip) => trip.Beneficiary && trip.Beneficiary.Id === single).length > 0 ? 'Edit' : 'Create'}
                                                </Button>,
                                            ]}
                                            mainText={<BenefeciariesTable single={single} setSingle={setSingle} resetForm={resetForm} setDuplicateTrip={setDuplicateTrip} values={values} setFieldValue={setFieldValue} trips={navTrip} title={`There are participants from different beneficiaries for this meeting. Please select the beneficiary for which you would like to create a trip.`} />}
                                        />
                                        <MeetingDrawer collapsed={collapseDrawer} setCollapsed={setCollapseDrawer} data={values.Meeting} />
                                        <div className="flex gap-1">
                                            <Button
                                                className="border-b-0  rounded-b-none"
                                                onClick={() =>
                                                    navigate('/apps/projects/meetings/trip/list')
                                                }
                                            >
                                                Back to List
                                            </Button>
                                            <Button
                                                className="border-b-0  rounded-b-none"
                                                onClick={() =>
                                                    setCollapseDrawer(true)
                                                }
                                            >
                                                Meeting Information
                                            </Button>
                                            {values.Meeting.Id !== 0 && (
                                                <Button
                                                    className="border-b-0  rounded-b-none"
                                                    onClick={() =>
                                                        navigate(
                                                            `/apps/projects/meetings/${values.Meeting.Id}`,
                                                        )
                                                    }
                                                >
                                                    Go to Meeting
                                                </Button>
                                            )}
                                            {values.Id === 0 && (
                                                <Button
                                                    onClick={() => {
                                                        resetForm();
                                                    }}
                                                    className="bg-primary hover:text-primary text-white  border-b-0  rounded-b-none"
                                                >
                                                    Clear Form
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit} style={{ minWidth: 970 }}>
                                        <div className={`flex flex-col justify-center gap-4`}>
                                            <div
                                                data-testid="leaveTypeDropdown"
                                                className="flex gap-4 flex-col"
                                            >
                                                <div>
                                                    <FormCard title="Trip Context" icon={<ProductOutlined />}>
                                                        <TripContext values={values} setFieldValue={setFieldValue} approving={approving} data={data} />
                                                    </FormCard>
                                                </div>
                                                <div>
                                                    <FormCard
                                                        title="Basic Information"
                                                        icon={<InfoCircleOutlined />}
                                                    >
                                                        <TripBasicInformation
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            data={data}
                                                            approving={approving}
                                                        />
                                                    </FormCard>
                                                </div>
                                                <div>
                                                    <div className="flex  gap-1 items-center">
                                                        <FormCard title="Travellers" icon={<UserOutlined />}>
                                                            <AttendeesTransfer
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                data={dataMeeting}
                                                                approving={approving}
                                                            />
                                                        </FormCard>
                                                    </div>
                                                </div>

                                                <div>
                                                    <FormCard
                                                        title="Location"
                                                        icon={<EnvironmentOutlined />}
                                                    >
                                                        <Location
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            data={data}
                                                            approving={approving}
                                                        />
                                                    </FormCard>
                                                </div>
                                                <div className="flex justify-end">
                                                    {approving && (values.Status !== 2 && values.Status !== 7) &&
                                                        <div className='flex flex-row gap-2'>
                                                            <ActionBtn handleAction={handleReject} btnModalLabel='Reject' btnLabel='Reject' permission={'Trips_Approve'} bgColor={LeaveColors[2].value} hasComments={true} danger={true} modalTitle='Reject Trip' modalDescription={'Are you sure you want to reject this trip? Your action will remove it permanently.'} />
                                                            <ActionBtn handleAction={handleApprove} btnModalLabel='Approve' btnLabel='Approve' type={'primary'} permission={'Trips_Approve'} hasComments={true} bgColor={LeaveColors[1].value} modalTitle='Approve Trip' modalDescription={'Are you sure you want to approve this trip?'} />
                                                        </div>
                                                    }
                                                    {((values.Status !== 0 && values.Status !== 7) && !approving && values.CreatedBy.Id === id && (
                                                        <div className="flex gap-4">
                                                            {dayjs(data.EarliestDepartureTime).isAfter(
                                                                new Date(),
                                                            ) && (
                                                                    <>
                                                                        <ActionBtn handleAction={handleDelete} btnLabel='Cancel' btnModalLabel='Withdraw' danger hasComments={false} bgColor={LeaveColors[1].value} modalTitle='Withdraw Trip' modalDescription={'Are you sure you want to withdraw this trip? Your action will remove it permanently.'} />
                                                                        {values.Status !== 2 && <Button
                                                                            data-testid="submitLeave"
                                                                            type="primary"
                                                                            htmlType="submit"
                                                                            className="bg-primary"
                                                                        >
                                                                            <span className="indicator-label">
                                                                                Update
                                                                            </span>
                                                                        </Button>}
                                                                    </>
                                                                )}
                                                        </div>
                                                    ))}
                                                    {values.Status === 0 && (values.CreatedBy.Id === id || values.CreatedBy.Id === 0) && (
                                                        <div className='flex flex-row gap-2'>
                                                            {values.CreatedBy.Id === id && <ActionBtn modalTitle='Delete Trip' modalDescription={'Are you sure you want to withdraw this trip? Your action will remove it permanently.'} handleAction={handleDelete} hasComments={false} danger btnLabel='Delete' btnModalLabel='Delete' bgColor='' />}
                                                            <Tooltip title="Save as draft">
                                                                <Button onClick={() => saveStep(values)}>
                                                                    Save
                                                                </Button>
                                                            </Tooltip>
                                                            <Tooltip title="Submit for approval">
                                                                <Button
                                                                    data-testid="submitLeave"
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    className="bg-primary"
                                                                >
                                                                    <span className="indicator-label">Submit</span>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default TripForm;
