import { IEnv } from './env.type';

class EnvConfig {
  constructor() {}

  public getEnvKey(key: keyof IEnv) {
    const env = window.ENV[key];

    if (env === undefined) throw new Error(`Env variable ${key} not found`);
    return window.ENV[key];
  }
}

const envConfig = new EnvConfig();
export default envConfig;
