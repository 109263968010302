import React from 'react'
import LoomSelect from '../../../../components/StyledComponents/LoomSelect'
import { useAuth } from '../../../auth';
import { useMeetings } from '../../meeting/provider/useMeetings';
import dayjs from 'dayjs';
import { useTrip } from '../provider/useTrip';
import TextArea from 'antd/es/input/TextArea';
import { ErrorMessage } from 'formik';

type Props = {
    values: any;
    setFieldValue: any;
    approving: boolean | undefined;
    data: any;
}

const TripContext = ({ values, setFieldValue, approving, data }: Props) => {
    const { id, allowIf } = useAuth();
    const { meetings, getPersonalMeetings, getMeeting } = useMeetings();
    const { getBeneficiaries } = useTrip();

    const formatInitialData = async (searchTerm = '') => {
        await getPersonalMeetings({
            searchTerm,
        });
    };
    const formatInitialData2 = async (searchTerm = '') => {
        await getMeeting({
            searchTerm,
        });
    };
    console.log(values.Purpose)
    return (
        <div className={`flex flex-col justify-center gap-4`}>
            <LoomSelect<any>
                required
                name="Meeting.Name"
                label="Which meeting is this trip for?"
                disabled={
                    approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)
                }
                placeholder="Type meeting..."
                valueFunc={
                    values.Meeting && values.Meeting.Id !== 0
                        ? ({
                            value: values.Meeting.Id,
                            label: values.Meeting.Name,
                        } as any)
                        : undefined
                }
                onChange={async (val: number) => {
                    const meeting = meetings.filter(
                        (meeting) => meeting?.Id === val,
                    )[0]
                    setFieldValue(
                        'Meeting',
                        meetings.filter(
                            (meeting) => meeting?.Id === val,
                        )[0],
                    );
                    setFieldValue(
                        'Project',
                        meetings.filter(
                            (meeting) => meeting?.Id === val,
                        )[0].Project,
                    );

                    setFieldValue(
                        'Destination',
                        meetings.filter(
                            (meeting) => meeting?.Id === val,
                        )[0].Location,
                    );
                    setFieldValue(
                        'TimezoneOffset',
                        meetings.filter(
                            (meeting) => meeting?.Id === val,
                        )[0].TimezoneOffset,
                    );
                    if (meeting.AllDay) {
                        setFieldValue('EarliestDepartureTime', dayjs(meeting.To).utcOffset(meeting.TimezoneOffset).set('hour', 18).toISOString())
                        setFieldValue('LatestArrivalTime', dayjs(meeting.From).utcOffset(meeting.TimezoneOffset).set('hour', 9).toISOString())
                    } else {
                        setFieldValue('EarliestDepartureTime', dayjs(meeting.To).utcOffset(meeting.TimezoneOffset).toISOString())
                        setFieldValue('LatestArrivalTime', dayjs(meeting.From).utcOffset(meeting.TimezoneOffset).toISOString())
                    }


                    setFieldValue(
                        'Meeting',
                        meetings.filter(
                            (meeting) => meeting?.Id === val,
                        )[0],
                    );
                    const ben = await getBeneficiaries(meetings.filter(
                        (meeting) => meeting?.Id === val,
                    )[0].Project.Id);
                    if (ben && ben.length > 0) {
                        setFieldValue('Beneficiary', ben[0]);

                    }

                }}
                onSearch={(value: string) => {
                    if (allowIf(['Meetings_EditAll'])) {
                        formatInitialData2(value)
                    } else {
                        formatInitialData(value)
                    }

                    meetings.filter((meeting) => meeting.CreatedBy?.Id === id)
                }
                }
                options={meetings
                    .filter((meet) => meet.MeetingMode !== 1)
                    .map((d) => {
                        return { value: d.Id, label: d.Name };
                    })}
            />
            <div className="flex gap-2 flex-col w-full">
                <div>
                    <label className={`form-label required`}>
                        Purpose
                    </label>
                </div>
                <TextArea
                    name="Purpose"
                    rows={2}
                    data-testid="desc"
                    size="middle"
                    onChange={(e) =>
                        setFieldValue('Purpose', e.target.value)
                    }
                    value={values.Purpose}
                    placeholder="Explain the purpose of this meeting for the consideration of the approver."
                    disabled={
                        approving || (data?.Id !== 0 && id !== data?.CreatedBy?.Id)
                    }
                    style={{ color: 'black' }}
                />
                <ErrorMessage name="Purpose" className="text-red-500">
                    {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                </ErrorMessage>
            </div>

        </div>
    )
}

export default TripContext