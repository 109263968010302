import { Button, Checkbox, DatePicker, Divider, Input, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { Meeting } from '../../../../../api-requests/Meeting';
import { useAuth } from '../../../../auth';
import { ErrorMessage } from 'formik';
import { Project, fetchProjects, fetchProjectsParticipation } from '../../../../../api-requests/Projects';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import tzData from 'moment-timezone/data/meta/latest.json';

import {
    FormOutlined,
    EnvironmentOutlined,
    InboxOutlined,
    EyeOutlined,
    DeleteOutlined,
    SnippetsOutlined,
    InfoCircleOutlined,
    EditOutlined,
    FileOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { fetchCitiesWithUTCOffset } from '../../../../../utils/utcOffset';
import moment from 'moment';
import FormCard from '../../../../../utils/Forms/FormCard';
import LoomInput from '../../../../../components/StyledComponents/LoomInput';
import LoomSelect from '../../../../../components/StyledComponents/LoomSelect';
import WarningModal from '../../../../../components/modal/WarningModal';
import { LeaveColors } from '../../../../../../shared/colors';
import { initMeeting } from '../../../../../utils/FormValidation/CreateAccountWizardHelper';

const { Title } = Typography;
type Props = {
    values: Meeting;
    setFieldValue: any;
    countries: any;
    data: any;
}
dayjs.extend(utc);
dayjs.extend(timezone);

const MainstreamCities = [
    'America/New_York',
    'America/Los_Angeles',
    'America/Chicago',
    'America/Denver',
    'Europe/London',
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Madrid',
    'Asia/Tokyo',
    'Asia/Shanghai',
    'Asia/Hong_Kong',
    'Asia/Singapore',
    'Asia/Dubai',
    'Australia/Sydney',
    'Africa/Johannesburg',
    'America/Sao_Paulo',
    'America/Anchorage',
    'Pacific/Honolulu',
    'Europe/Moscow',
    'Asia/Karachi',
    'Asia/Dhaka',
    'Asia/Jakarta',
    'Pacific/Noumea',
    'Pacific/Auckland',
    'Europe/Athens',
    'Asia/Istanbul',
    'Europe/Amsterdam',
    'America/Bahia',
    'Africa/Cairo',
    'Europe/Copenhagen',
    'America/Montreal',
    'Europe/Brussels',
    // Add more cities as needed
];
const options = [
    { value: 0, label: 'Meeting Agenda' },
    { value: 1, label: 'Participant List' },
    { value: 2, label: 'Other' },
];
const generateOptions = (length: number, excludedOptions: any) => {
    const options = [];
    for (let value = 0; value < length; value++) {
        if (!excludedOptions.includes(value)) {
            options.push(value);
        }
    }
    return options;
};
const generateTimeOptions = (excludedOptions: any) => {
    const options = [];
    for (let i = 0; i < 60; i += 30) {
        if (!excludedOptions.includes(i)) {
            options.push(i);
        }
    }
    return options;
};
const disabledDateTime = () => ({
    disabledHours: () =>
        generateOptions(
            24,
            [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23,
            ].map((hour) => hour),
        ),
    disabledMinutes: (selectedHour: number) => {
        if (
            [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23,
            ].includes(selectedHour)
        ) {
            return generateOptions(60, [0, 30]);
        }
        return generateOptions(
            60,
            Array.from({ length: 60 }, (_, i) => i),
        );
    },
});
const BasicInformationMeeting = ({ values, setFieldValue, countries, data }: Props) => {
    const { id } = useAuth();
    const [projects, setProjects] = useState<Project[]>([]);
    const [defaultUTC, setDefaultUTC] = useState<any>();
    const [times, setTimes] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setTimes(fetchCitiesWithUTCOffset(MainstreamCities, -12, 12));

    }, []);

    useEffect(() => {
        fetchProjects({
            searchTerm: searchValue,
        }).then((res) => setProjects(res.Results));
    }, [searchValue])
    useEffect(() => {
        if (times.length > 0) {
            const t = countries.filter(
                (country: any) => country.Id === values.Location?.Country?.Id,
            )[0];
            if (t) {
                // @ts-ignore
                const countryTimezones = tzData.countries[t.ISOCode2];
                if (!countryTimezones || countryTimezones.zones.length === 0) {
                    return;
                }

                // Assume the first timezone in the list is the desired one
                const timezone = countryTimezones.zones[0];
                const offset = moment.tz(timezone).utcOffset();
                setDefaultUTC(
                    times.filter((option) => Number(option.value) === offset / 60)[0],
                );
            } else {
                setDefaultUTC(
                    times.filter(
                        (option) => Number(option.value) == dayjs().utcOffset() / 60,
                    )[0],
                );
            }
        }
    }, [times, values.Location?.Country?.Id]);
    const handleSelectChange = (
        v: number,
        from: any,
        to: any,
        setFieldValue: any,
    ) => {
        setDefaultUTC(times.filter((option) => Number(option.value) == v)[0]);
        const adjustedFromDate = dayjs(from)
            .utcOffset(v * 60)
            .format();
        const adjustedToDate = dayjs(to)
            .utcOffset(v * 60)
            .format();
        setFieldValue('TimezoneOffset', Number(v));
        setFieldValue('From', adjustedFromDate);
        setFieldValue('To', adjustedToDate);
    };
    const formatInitiaProjectslData = async (searchTerm = '') => {
        const fetchedData = await fetchProjects({
            searchTerm,
        });
        setProjects(fetchedData.Results);
    };

    return (
        <FormCard title="Basic Information" icon={<InfoCircleOutlined />}>
            <WarningModal
                title="Remove Attendee"
                isOpen={showModal}
                setIsOpen={setShowModal}
                footerButtons={[
                    <Button
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        Close
                    </Button>,
                    <Button
                        onClick={async () => {
                            setFieldValue('Attendees', []);
                            setFieldValue('Project', projects.filter((proj) => proj.Id === defaultUTC)[0]),
                                setShowModal(false)

                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Continue
                    </Button>,
                ]}
                mainText={`If you switch project all meeting attendees will be removed.`}
            />
            <div className='gap-4 flex flex-col'>
                <div className="flex justify-center flex-row w-full gap-4">
                    <div
                        data-testid="leaveTypeDropdown"
                        className="w-full flex gap-2 flex-col"
                    >
                        <LoomInput
                            name="Name"
                            required
                            label={"Title"}
                            placeholder="Enter title"
                            prefix={<FormOutlined />}
                            value={values.Name}
                            onChange={(e) =>
                                setFieldValue('Name', e.target.value)
                            }
                            disabled={values.CreatedBy && id !== values.CreatedBy?.Id} />
                    </div>
                </div>
                <div className="w-full flex flex-row gap-2 ">
                    <div className="w-full flex gap-2 flex-col">
                        <LoomSelect<number>
                            clearable={true}
                            name="Project.Acronym"
                            required
                            label="Project"
                            disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                            placeholder="Type project..."
                            valueFunc={
                                values.Project && values.Project.Id !== 0
                                    ? { value: values.Project.Id, label: values.Project.Acronym } as any
                                    : { value: 0, label: '' }
                            }
                            onChange={(val: number) => {
                                setDefaultUTC(val);
                                if (values.Attendees.length > 0)
                                    setShowModal(true);
                                else {
                                    if (val === undefined) {
                                        setFieldValue('Project', initMeeting.Project);
                                    } else {
                                        setFieldValue('Project', projects.filter((proj) => proj.Id === val)[0])

                                    }
                                }

                            }}
                            onSearch={(value: string) =>
                                formatInitiaProjectslData(value)
                            }
                            options={projects.map((d) => ({
                                label: d.Acronym,
                                value: d.Id,
                            }))}
                        />
                        {/* <ErrorMessage
                            name="Project.Acronym"
                            className="text-red-500"
                        >
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage> */}
                    </div>
                    <div
                        data-testid="leaveTypeDropdown"
                        className="w-full flex gap-2 flex-col"
                    >
                        <LoomSelect<number | { id: number, value: string } >
                            name="Mode"
                            label="Mode"
                            disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                            placeholder="Type..."
                            valueFunc={
                                values.MeetingMode !== 0
                                    ? values.MeetingMode
                                    : { id: 0, value: 'Physical' }
                            }
                            onChange={(v) => {
                                if (v === 1) {
                                    setFieldValue('IsVirtual', true);
                                    setFieldValue('Location', {});
                                }
                                setFieldValue('MeetingMode', v);
                            }}
                            options={[
                                { value: 0, label: 'Physical' },
                                { value: 1, label: 'Online' },
                                { value: 2, label: 'Hybrid' },
                            ]}
                        />
                    </div>
                    <div
                        data-testid="leaveTypeDropdown"
                        className="w-full flex gap-2 flex-col"
                    >
                        <LoomSelect<number | { id: number, value: string }>
                            name="Type"
                            label="Type"
                            disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                            placeholder="Type..."
                            valueFunc={
                                values.Type !== 0
                                    ? values.Type
                                    : { id: 0, value: 'Review' }
                            }
                            onChange={(v) => {
                                setFieldValue('Type', v);
                            }}
                            options={[
                                { value: 0, label: 'Review' },
                                { value: 1, label: 'General Assembly' },
                                { value: 2, label: 'Workshop' },
                                { value: 3, label: 'Other' },
                            ]}
                        />
                    </div>
                </div>

                {/* <div className="w-full grid grid-cols-12 gap-2">
                        <div
                            data-testid="commentInput"
                            className="flex flex-row   col-span-4"
                            style={{
                                alignItems: 'center',
                                gap: 12,
                                flex: '1 1 0',
                            }}
                        >
                            <div className="flex flex-col w-full gap-2">
                                <label>Start Date</label>
                                <DatePicker
                                    disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                                    style={{ color: 'black', width: '100%' }}
                                    onChange={(date) => {
                                        setFieldValue('From', date.format());
                                        if (date.isAfter(dayjs(values.To))) {
                                            setFieldValue(
                                                'To',
                                                date.add(30, 'minutes').format(),
                                            );
                                        }
                                    }}
                                    allowClear={false}
                                    disabledTime={disabledDateTime}
                                    className="cursor-pointer"
                                    showTime={
                                        values.AllDay
                                            ? false
                                            : {
                                                format: 'HH:mm',
                                                minuteStep: 30,
                                                defaultValue: dayjs('00:00', 'HH:mm'),
                                                disabledMinutes: () => timeOptions,
                                            }
                                    }
                                    format={
                                        values.AllDay
                                            ? 'DD MMM YYYY'
                                            : 'DD MMM YYYY HH:mm'
                                    }
                                    use12Hours={false}
                                    value={
                                        values.TimezoneOffset
                                            ? dayjs(values.From).utcOffset(
                                                values.TimezoneOffset,
                                            )
                                            : dayjs(values.From)
                                    }

                                // value={
                                //     values.From ? dayjs(values.From).toISOString() : dayjs(new Date())
                                // }
                                />
                                <ErrorMessage name="From" className="text-red-500">
                                    {(msg) => (
                                        <div style={{ color: 'red' }}>{msg}</div>
                                    )}
                                </ErrorMessage>
                            </div>
                            <ErrorMessage name="From" className="text-red-500">
                                {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                            </ErrorMessage>
                        </div>
                        <div
                            data-testid="commentInput"
                            className="flex flex-row w-full col-span-4"
                            style={{
                                alignItems: 'center',
                                gap: 12,
                                flex: '1 1 0',
                            }}
                        >
                            <div className="flex w-full flex-col gap-2">
                                <div>
                                    <label>End Date</label>
                                </div>

                                <DatePicker
                                    allowClear={false}
                                    use12Hours={false}
                                    minDate={dayjs(values.From).add(30, 'minutes')}
                                    onChange={(date) => {
                                        setFieldValue('To', date.format());
                                    }}
                                    disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                                    style={{ color: 'black', width: '100%' }}
                                    disabledTime={(current) => {
                                        const currentFromDate = dayjs(values.From);
                                        const selectedDate = dayjs(current);
                                        const minDate = currentFromDate.add(
                                            30,
                                            'minute',
                                        );

                                        if (
                                            selectedDate.isSame(currentFromDate, 'date')
                                        ) {
                                            // If it's the same date as values.From, disable times within 30 minutes from values.From
                                            const disabledMinutes = (
                                                selectedHour: any,
                                            ) => {
                                                if (
                                                    selectedHour === currentFromDate.hour()
                                                ) {
                                                    return [
                                                        ...Array(
                                                            currentFromDate.minute() + 30,
                                                        ).keys(),
                                                    ];
                                                }
                                                if (
                                                    selectedHour === minDate.hour() &&
                                                    currentFromDate.minute() > 30
                                                ) {
                                                    return [
                                                        ...Array(
                                                            currentFromDate.minute() - 30,
                                                        ).keys(),
                                                    ];
                                                }
                                                return [];
                                            };

                                            return {
                                                disabledHours: () => [
                                                    ...Array(currentFromDate.hour()).keys(),
                                                    ...(currentFromDate.minute() >= 30
                                                        ? [currentFromDate.hour()]
                                                        : []),
                                                ],
                                                disabledMinutes,
                                            };
                                        } else if (
                                            selectedDate.isBefore(currentFromDate, 'date')
                                        ) {
                                            // If it's before values.From date, disable all hours
                                            return {
                                                disabledHours: () => [...Array(24).keys()],
                                            };
                                        }

                                        return {}; // No disabled hours// No disabled hours
                                    }}
                                    showTime={
                                        values.AllDay
                                            ? false
                                            : {
                                                format: 'HH:mm',
                                                minuteStep: 30,
                                                defaultValue: dayjs('00:00', 'HH:mm'),
                                                // disabledMinutes: () => timeOptions
                                            }
                                    }
                                    format={
                                        values.AllDay
                                            ? 'DD MMM YYYY'
                                            : 'DD MMM YYYY HH:mm'
                                    }
                                    className="cursor-pointer"
                                    value={
                                        values.TimezoneOffset
                                            ? dayjs(values.To).utcOffset(
                                                values.TimezoneOffset,
                                            )
                                            : dayjs(values.To)
                                    }
                                />
                            </div>
                            <ErrorMessage name="From" className="text-red-500">
                                {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                            </ErrorMessage>
                        </div>
                        <div
                            data-testid="commentInput"
                            className="flex flex-row col-span-4"
                            style={{
                                alignItems: 'center',
                                gap: 12,
                                flex: '1 1 0',
                            }}
                        >

                            <div className="flex w-full flex-col gap-2">
                                <div>
                                    <label>Timezone</label>
                                </div>
                                <Select
                                    disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                                    style={{ color: 'black' }}
                                    placeholder="Add timezone"
                                    value={values.TimezoneOffset ? times.filter((option) => Number(option.value) === values.TimezoneOffset)[0] : times.filter((option) => Number(option.value) === dayjs().utcOffset() / 60)[0]}
                                    onChange={(v) =>
                                        handleSelectChange(
                                            v,
                                            values.From,
                                            values.To,
                                            setFieldValue,
                                        )
                                    }
                                    options={times.map((t) => {
                                        return {
                                            value: t.value,
                                            label: t.label,
                                        };
                                    })}
                                />
                            </div>
                        </div>
                    </div> */}
                {/* <Checkbox
                        onChange={() => setFieldValue('AllDay', !values.AllDay)}
                        checked={values.AllDay}
                        disabled={
                            values.CreatedBy && id !== values.CreatedBy?.Id
                        }
                    >
                        All day
                    </Checkbox> */}
            </div>
        </FormCard>
        // <div>
        //     <div className="bg-gray-50 w-full p-4 flex flex-col rounded-lg shadow-md ">
        //         <div>
        //             <h3 className="flex gap-titleIcon mt-2 ">
        //                 <InfoCircleOutlined />
        //                 Basic Information
        //             </h3>
        //             <Divider />
        //         </div>
        //         <div className='gap-4 flex flex-col'>
        //             <div className="flex justify-center flex-row w-full gap-4">
        //                 <div
        //                     data-testid="leaveTypeDropdown"
        //                     className="w-full flex gap-2 flex-col"
        //                 >
        //                     <div>
        //                         <label className="form-label">Title</label>
        //                     </div>
        //                     <Input
        //                         name="Name"
        //                         placeholder="Enter title"
        //                         prefix={<FormOutlined />}
        //                         value={values.Name}
        //                         onChange={(e) =>
        //                             setFieldValue('Name', e.target.value)
        //                         }
        //                         disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                         style={{ color: 'black' }}
        //                     />
        //                     {/* <LoomInput placeholder={"Title"} /> */}

        //                     <ErrorMessage name="Name" className="text-red-500">
        //                         {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
        //                     </ErrorMessage>
        //                 </div>
        //             </div>
        //             <div className="w-full flex flex-row gap-2 ">
        //                 <div className="w-full flex gap-2 flex-col">
        //                     <div>
        //                         <label className="form-label">Project</label>
        //                     </div>
        //                     <Select
        //                         disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                         style={{ color: 'black' }}
        //                         placeholder="Type project..."
        //                         showSearch
        //                         value={
        //                             values.Project.Id !== 0
        //                                 ? { value: values.Project.Id, label: values.Project.Acronym } as any
        //                                 : undefined
        //                         }
        //                         filterOption={false}
        //                         onChange={(val: number) => {
        //                             setFieldValue(
        //                                 'Project',
        //                                 projects.filter((proj) => proj.Id === val)[0],
        //                             );
        //                         }}
        //                         onSearch={(value: string) =>
        //                             formatInitiaProjectslData(value)
        //                         }
        //                         options={projects.map((d) => ({
        //                             label: d.Acronym,
        //                             value: d.Id,
        //                         }))}
        //                     />
        //                     <ErrorMessage
        //                         name="Project.Acronym"
        //                         className="text-red-500"
        //                     >
        //                         {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
        //                     </ErrorMessage>
        //                 </div>
        //                 <div
        //                     data-testid="leaveTypeDropdown"
        //                     className="w-full flex gap-2 flex-col"
        //                 >
        //                     <div>
        //                         <label className="form-label">Mode</label>
        //                     </div>
        //                     <Select
        //                         disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                         optionFilterProp="children"
        //                         placeholder="Type..."
        //                         defaultValue={0}
        //                         value={
        //                             values.MeetingMode !== 0
        //                                 ? values.MeetingMode
        //                                 : { id: 0, value: 'Physical' }
        //                         }
        //                         onChange={(v) => {
        //                             if (v === 1) {
        //                                 setFieldValue('IsVirtual', true);
        //                                 setFieldValue('Location', {});
        //                             }
        //                             setFieldValue('MeetingMode', v);
        //                         }}
        //                         filterOption={false}
        //                         options={[
        //                             { value: 0, label: 'Physical' },
        //                             { value: 1, label: 'Online' },
        //                             { value: 2, label: 'Hybrid' },
        //                         ]}
        //                     />
        //                 </div>
        //                 <div
        //                     data-testid="leaveTypeDropdown"
        //                     className="w-full flex gap-2 flex-col"
        //                 >
        //                     <div>
        //                         <label className="form-label">Type</label>
        //                     </div>
        //                     <Select
        //                         disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                         optionFilterProp="children"
        //                         placeholder="Type..."
        //                         defaultValue={0}
        //                         value={
        //                             values.Type !== 0
        //                                 ? values.Type
        //                                 : { id: 0, value: 'Review' }
        //                         }
        //                         onChange={(v) => {
        //                             setFieldValue('Type', v);
        //                         }}
        //                         filterOption={false}
        //                         options={[
        //                             { value: 0, label: 'Review' },
        //                             { value: 1, label: 'General Assembly' },
        //                             { value: 2, label: 'Workshop' },
        //                             { value: 3, label: 'Other' },
        //                         ]}
        //                     />
        //                 </div>
        //             </div>

        //             {/* <div className="w-full grid grid-cols-12 gap-2">
        //                 <div
        //                     data-testid="commentInput"
        //                     className="flex flex-row   col-span-4"
        //                     style={{
        //                         alignItems: 'center',
        //                         gap: 12,
        //                         flex: '1 1 0',
        //                     }}
        //                 >
        //                     <div className="flex flex-col w-full gap-2">
        //                         <label>Start Date</label>
        //                         <DatePicker
        //                             disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                             style={{ color: 'black', width: '100%' }}
        //                             onChange={(date) => {
        //                                 setFieldValue('From', date.format());
        //                                 if (date.isAfter(dayjs(values.To))) {
        //                                     setFieldValue(
        //                                         'To',
        //                                         date.add(30, 'minutes').format(),
        //                                     );
        //                                 }
        //                             }}
        //                             allowClear={false}
        //                             disabledTime={disabledDateTime}
        //                             className="cursor-pointer"
        //                             showTime={
        //                                 values.AllDay
        //                                     ? false
        //                                     : {
        //                                         format: 'HH:mm',
        //                                         minuteStep: 30,
        //                                         defaultValue: dayjs('00:00', 'HH:mm'),
        //                                         disabledMinutes: () => timeOptions,
        //                                     }
        //                             }
        //                             format={
        //                                 values.AllDay
        //                                     ? 'DD MMM YYYY'
        //                                     : 'DD MMM YYYY HH:mm'
        //                             }
        //                             use12Hours={false}
        //                             value={
        //                                 values.TimezoneOffset
        //                                     ? dayjs(values.From).utcOffset(
        //                                         values.TimezoneOffset,
        //                                     )
        //                                     : dayjs(values.From)
        //                             }

        //                         // value={
        //                         //     values.From ? dayjs(values.From).toISOString() : dayjs(new Date())
        //                         // }
        //                         />
        //                         <ErrorMessage name="From" className="text-red-500">
        //                             {(msg) => (
        //                                 <div style={{ color: 'red' }}>{msg}</div>
        //                             )}
        //                         </ErrorMessage>
        //                     </div>
        //                     <ErrorMessage name="From" className="text-red-500">
        //                         {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
        //                     </ErrorMessage>
        //                 </div>
        //                 <div
        //                     data-testid="commentInput"
        //                     className="flex flex-row w-full col-span-4"
        //                     style={{
        //                         alignItems: 'center',
        //                         gap: 12,
        //                         flex: '1 1 0',
        //                     }}
        //                 >
        //                     <div className="flex w-full flex-col gap-2">
        //                         <div>
        //                             <label>End Date</label>
        //                         </div>

        //                         <DatePicker
        //                             allowClear={false}
        //                             use12Hours={false}
        //                             minDate={dayjs(values.From).add(30, 'minutes')}
        //                             onChange={(date) => {
        //                                 setFieldValue('To', date.format());
        //                             }}
        //                             disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                             style={{ color: 'black', width: '100%' }}
        //                             disabledTime={(current) => {
        //                                 const currentFromDate = dayjs(values.From);
        //                                 const selectedDate = dayjs(current);
        //                                 const minDate = currentFromDate.add(
        //                                     30,
        //                                     'minute',
        //                                 );

        //                                 if (
        //                                     selectedDate.isSame(currentFromDate, 'date')
        //                                 ) {
        //                                     // If it's the same date as values.From, disable times within 30 minutes from values.From
        //                                     const disabledMinutes = (
        //                                         selectedHour: any,
        //                                     ) => {
        //                                         if (
        //                                             selectedHour === currentFromDate.hour()
        //                                         ) {
        //                                             return [
        //                                                 ...Array(
        //                                                     currentFromDate.minute() + 30,
        //                                                 ).keys(),
        //                                             ];
        //                                         }
        //                                         if (
        //                                             selectedHour === minDate.hour() &&
        //                                             currentFromDate.minute() > 30
        //                                         ) {
        //                                             return [
        //                                                 ...Array(
        //                                                     currentFromDate.minute() - 30,
        //                                                 ).keys(),
        //                                             ];
        //                                         }
        //                                         return [];
        //                                     };

        //                                     return {
        //                                         disabledHours: () => [
        //                                             ...Array(currentFromDate.hour()).keys(),
        //                                             ...(currentFromDate.minute() >= 30
        //                                                 ? [currentFromDate.hour()]
        //                                                 : []),
        //                                         ],
        //                                         disabledMinutes,
        //                                     };
        //                                 } else if (
        //                                     selectedDate.isBefore(currentFromDate, 'date')
        //                                 ) {
        //                                     // If it's before values.From date, disable all hours
        //                                     return {
        //                                         disabledHours: () => [...Array(24).keys()],
        //                                     };
        //                                 }

        //                                 return {}; // No disabled hours// No disabled hours
        //                             }}
        //                             showTime={
        //                                 values.AllDay
        //                                     ? false
        //                                     : {
        //                                         format: 'HH:mm',
        //                                         minuteStep: 30,
        //                                         defaultValue: dayjs('00:00', 'HH:mm'),
        //                                         // disabledMinutes: () => timeOptions
        //                                     }
        //                             }
        //                             format={
        //                                 values.AllDay
        //                                     ? 'DD MMM YYYY'
        //                                     : 'DD MMM YYYY HH:mm'
        //                             }
        //                             className="cursor-pointer"
        //                             value={
        //                                 values.TimezoneOffset
        //                                     ? dayjs(values.To).utcOffset(
        //                                         values.TimezoneOffset,
        //                                     )
        //                                     : dayjs(values.To)
        //                             }
        //                         />
        //                     </div>
        //                     <ErrorMessage name="From" className="text-red-500">
        //                         {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
        //                     </ErrorMessage>
        //                 </div>
        //                 <div
        //                     data-testid="commentInput"
        //                     className="flex flex-row col-span-4"
        //                     style={{
        //                         alignItems: 'center',
        //                         gap: 12,
        //                         flex: '1 1 0',
        //                     }}
        //                 >

        //                     <div className="flex w-full flex-col gap-2">
        //                         <div>
        //                             <label>Timezone</label>
        //                         </div>
        //                         <Select
        //                             disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
        //                             style={{ color: 'black' }}
        //                             placeholder="Add timezone"
        //                             value={values.TimezoneOffset ? times.filter((option) => Number(option.value) === values.TimezoneOffset)[0] : times.filter((option) => Number(option.value) === dayjs().utcOffset() / 60)[0]}
        //                             onChange={(v) =>
        //                                 handleSelectChange(
        //                                     v,
        //                                     values.From,
        //                                     values.To,
        //                                     setFieldValue,
        //                                 )
        //                             }
        //                             options={times.map((t) => {
        //                                 return {
        //                                     value: t.value,
        //                                     label: t.label,
        //                                 };
        //                             })}
        //                         />
        //                     </div>
        //                 </div>
        //             </div> */}
        //             {/* <Checkbox
        //                 onChange={() => setFieldValue('AllDay', !values.AllDay)}
        //                 checked={values.AllDay}
        //                 disabled={
        //                     values.CreatedBy && id !== values.CreatedBy?.Id
        //                 }
        //             >
        //                 All day
        //             </Checkbox> */}
        //         </div>
        //     </div>
        // </div>
    )
}

export default BasicInformationMeeting