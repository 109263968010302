import React from "react";
import { useFeature } from "../../app/features/feature/FeatureProvider";


type Props = {
    children: React.ReactNode;
    keyVal: string
}


const HideComponent = ({ children, keyVal }: Props) => {
    const { showFeature } = useFeature();

    if (showFeature(keyVal)) {
        return children;
    }
    return null;
};

export default HideComponent;