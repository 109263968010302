const generatePaginationNumbers = (
  pageSize: number,
  pageIndex: number,
  totalCount: number,
): number[] => {
  const totalPages = Math.ceil(totalCount / pageSize);
  let startPage: number, endPage: number;

  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (pageIndex <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (pageIndex + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = pageIndex - 5;
      endPage = pageIndex + 4;
    }
  }

  // create an array of page numbers
  const pageNumbers: number[] = Array.from(
    { length: endPage + 1 - startPage },
    (_, i) => startPage + i,
  );
  return pageNumbers;
};

export default generatePaginationNumbers;
