import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toAbsoluteUrl } from '../../helpers';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className="flex flex-col lg:flex-row p-0 w-screen h-screen">
      {/* begin::Body */}
      <div className="flex flex-col lg:flex-row h-full lg:w-1/2  justify-center  order-2 lg:order-1">
        {/* begin::Form */}
        <div className="flex justify-center items-center w-full flex-col">
          {/* begin::Wrapper */}

          <Outlet />

          {/* end::Wrapper */}
        </div>

      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className="flex lg:flex-row flex-col h-full lg:w-1/2 bg-cover bg-center order-1 order-lg-2"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('media/loom/auth-bg.png')})`,
        }}
      >
        {/* begin::Content */}
        <div className="flex flex-col justify-center items-center py-15 px-5 md:px-15 w-full h-full">
          {/* begin::Logo */}
          <div>
            <h1 className="text-center text-white fs-2qx fw-bolder mb-15 font-mono">
              LOOM
            </h1>
            {/* end::Logo */}

            {/* begin::Image */}
            <div className='flex justify-center'>
              <img
                className="mx-auto w-[275px] md:w-1/2  xl:w-[250px] mt-10 lg:mb-20"
                src={toAbsoluteUrl('media/loom/timesheet.svg')}
                alt=""
              />
            </div>
            {/* end::Image */}

            {/* begin::Title */}
            <h3 className="text-white fs-2  text-center mb-7 font-mono">
              Fast, Efficient and Productive
            </h3>
          </div>
          {/* end::Title */}

          {/* begin::Text */}

          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  );
};

export { AuthLayout };
