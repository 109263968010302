import { Checkbox, DatePicker, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Meeting } from '../../../../../api-requests/Meeting';
import { useAuth } from '../../../../auth';
import dayjs from 'dayjs';
import { ErrorMessage } from 'formik';
import FormCard from '../../../../../utils/Forms/FormCard';
import { FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import tzData from 'moment-timezone/data/meta/latest.json';
import { fetchCitiesWithUTCOffset } from '../../../../../utils/utcOffset';
import LoomSelect from '../../../../../components/StyledComponents/LoomSelect';
import LoomDatePicker from '../../../../../components/StyledComponents/LoomDatePicker';
dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
    values: Meeting;
    setFieldValue: any;
    countries: any;
};
const MainstreamCities = [
    'America/New_York',
    'America/Los_Angeles',
    'America/Chicago',
    'America/Denver',
    'Europe/London',
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Madrid',
    'Asia/Tokyo',
    'Asia/Shanghai',
    'Asia/Hong_Kong',
    'Asia/Singapore',
    'Asia/Dubai',
    'Australia/Sydney',
    'Africa/Johannesburg',
    'America/Sao_Paulo',
    'America/Anchorage',
    'Pacific/Honolulu',
    'Europe/Moscow',
    'Asia/Karachi',
    'Asia/Dhaka',
    'Asia/Jakarta',
    'Pacific/Noumea',
    'Pacific/Auckland',
    'Europe/Athens',
    'Asia/Istanbul',
    'Europe/Amsterdam',
    'America/Bahia',
    'Africa/Cairo',
    'Europe/Copenhagen',
    'America/Montreal',
    'Europe/Brussels',
    // Add more cities as needed
];
const generateOptions = (length: number, excludedOptions: any) => {
    const options = [];
    for (let value = 0; value < length; value++) {
        if (!excludedOptions.includes(value)) {
            options.push(value);
        }
    }
    return options;
};
const generateTimeOptions = (excludedOptions: any) => {
    const options = [];
    for (let i = 0; i < 60; i += 30) {
        if (!excludedOptions.includes(i)) {
            options.push(i);
        }
    }
    return options;
};
const disabledDateTime = () => ({
    disabledHours: () =>
        generateOptions(
            24,
            [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23,
            ].map((hour) => hour),
        ),
    disabledMinutes: (selectedHour: number) => {
        if (
            [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23,
            ].includes(selectedHour)
        ) {
            return generateOptions(60, [0, 30]);
        }
        return generateOptions(
            60,
            Array.from({ length: 60 }, (_, i) => i),
        );
    },
});
const DateTime = ({ values, setFieldValue, countries }: Props) => {
    const { id } = useAuth();
    const timeOptions = generateTimeOptions([]);
    const [times, setTimes] = useState<any[]>([]);
    const handleSelectChange = (
        v: number,
        from: any,
        to: any,
        setFieldValue: any,
    ) => {
        const adjustedFromDate = dayjs(from)
            .utcOffset(v * 60)
            .format();
        const adjustedToDate = dayjs(to)
            .utcOffset(v * 60)
            .format();
        setFieldValue('TimezoneOffset', Number(v));
        setFieldValue('From', adjustedFromDate);
        setFieldValue('To', adjustedToDate);
    };
    useEffect(() => {
        setTimes(fetchCitiesWithUTCOffset(MainstreamCities, -12, 12));
    }, []);
    useEffect(() => {
        if (times.length > 0) {
            const t = countries.filter(
                (country: any) => country.Id === values.Location?.Country?.Id,
            )[0];
            if (t) {
                // @ts-ignore
                const countryTimezones = tzData.countries[t.ISOCode2];
                if (!countryTimezones || countryTimezones.zones.length === 0) {
                    return;
                }

                // Assume the first timezone in the list is the desired one
            }
        }
    }, [times, values.Location?.Country?.Id]);
    return (
        <FormCard
            title="Date & Time"
            icon={<FieldTimeOutlined />}
            headerButton={
                <div className='flex gap-1 items-center'>
                    <Switch
                        size="small"
                        onChange={() => setFieldValue('AllDay', !values.AllDay)}
                        checked={values.AllDay}
                        disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                    />
                    All day
                </div>
            }
        >
            <div className=" flex flex-row gap-2">
                <div
                    data-testid="commentInput"
                    className='w-full'
                >
                    <div className="flex flex-col w-full gap-2">
                        <LoomDatePicker required name="From" label="Start Date" disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                            onChange={(date) => {
                                setFieldValue('From', date.format());
                                if (date.isAfter(dayjs(values.To))) {
                                    setFieldValue('To', date.add(30, 'minutes').format());
                                }
                            }}
                            disabledTime={disabledDateTime}
                            showTime={
                                values.AllDay
                                    ? false
                                    : {
                                        format: 'HH:mm',
                                        minuteStep: 30,
                                        defaultValue: dayjs('00:00', 'HH:mm'),
                                        disabledMinutes: () => timeOptions,
                                    }
                            }
                            format={values.AllDay ? 'DD MMM YYYY' : 'DD MMM YYYY HH:mm'}
                            value={
                                values.TimezoneOffset && !values.AllDay
                                    ? dayjs(values.From).utcOffset(values.TimezoneOffset)
                                    : dayjs(values.From)
                            } />


                    </div>
                    <ErrorMessage name="From" className="text-red-500">
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage>
                </div>
                <div
                    data-testid="commentInput"
                    className='w-full'
                >
                    <LoomDatePicker
                        name="From"
                        required
                        label="End Date"
                        minDate={dayjs(values.From).add(30, 'minutes')}
                        onChange={(date) => {
                            setFieldValue('To', date.format());
                        }}
                        disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                        disabledTime={(current) => {
                            const currentFromDate = dayjs(values.From);
                            const selectedDate = dayjs(current);
                            const minDate = currentFromDate.add(30, 'minute');

                            if (selectedDate.isSame(currentFromDate, 'date')) {
                                // If it's the same date as values.From, disable times within 30 minutes from values.From
                                const disabledMinutes = (selectedHour: any) => {
                                    if (selectedHour === currentFromDate.hour()) {
                                        return [...Array(currentFromDate.minute() + 30).keys()];
                                    }
                                    if (
                                        selectedHour === minDate.hour() &&
                                        currentFromDate.minute() > 30
                                    ) {
                                        return [...Array(currentFromDate.minute() - 30).keys()];
                                    }
                                    return [];
                                };

                                return {
                                    disabledHours: () => [
                                        ...Array(currentFromDate.hour()).keys(),
                                        ...(currentFromDate.minute() >= 30
                                            ? [currentFromDate.hour()]
                                            : []),
                                    ],
                                    disabledMinutes,
                                };
                            } else if (selectedDate.isBefore(currentFromDate, 'date')) {
                                // If it's before values.From date, disable all hours
                                return {
                                    disabledHours: () => [...Array(24).keys()],
                                };
                            }

                            return {}; // No disabled hours// No disabled hours
                        }}
                        showTime={
                            values.AllDay
                                ? false
                                : {
                                    format: 'HH:mm',
                                    minuteStep: 30,
                                    defaultValue: dayjs('00:00', 'HH:mm'),
                                    // disabledMinutes: () => timeOptions
                                }
                        }
                        format={values.AllDay ? 'DD MMM YYYY' : 'DD MMM YYYY HH:mm'}
                        value={
                            values.TimezoneOffset && !values.AllDay
                                ? dayjs(values.To).utcOffset(values.TimezoneOffset)
                                : dayjs(values.To)
                        } />
                    <ErrorMessage name="From" className="text-red-500">
                        {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage>
                </div>
                {(!values.AllDay) && <div
                    data-testid="commentInput"
                    className='w-full'
                >
                    <div className="flex w-full flex-col gap-2">
                        <LoomSelect<any>
                            required
                            label="Timezone"
                            name="TimezoneOffset"
                            placeholder="Add timezone"
                            disabled={values.CreatedBy && id !== values.CreatedBy?.Id}
                            valueFunc={
                                values.TimezoneOffset
                                    ? times.filter(
                                        (option) =>
                                            Number(option.value) === values.TimezoneOffset,
                                    )[0]
                                    : times.filter(
                                        (option) =>
                                            Number(option.value) === dayjs().utcOffset() / 60,
                                    )[0]
                            }
                            onChange={(v) =>
                                handleSelectChange(v, values.From, values.To, setFieldValue)
                            }
                            options={times.map((t) => {
                                return {
                                    value: t.value,
                                    label: t.label,
                                };
                            })}
                        />

                    </div>
                </div>}

            </div>
        </FormCard>
    );
};

export default DateTime;
