import { useEffect, useState } from 'react';
import {
  PieChartOutlined,
  UnorderedListOutlined,
  PrinterOutlined,
  CalendarOutlined,
  WalletOutlined,
  SafetyOutlined,
  CarryOutOutlined,
  SolutionOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  FieldTimeOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  CarOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Layout, Menu, Typography, Grid } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SidebarLogo } from './SidebarLogo';
import { useAuth } from '../../features/auth';
import CalendarModalForm from '../../features/leave/components/CalendarModalForm';
import { initLeaveRequest } from '../../utils/FormValidation/CreateAccountWizardHelper';
import { useFeature } from '../../features/feature/FeatureProvider';
import { AppColors } from '../../../shared/colors';
import ReportModal from '../../features/report/ReportModal';
import { useLeaves } from '../../features/leave/provider/useLeaves';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

type Props = {
  collapsed: boolean;
  setCollapsed: (d: boolean) => void;
};

const Sidebar = ({ collapsed, setCollapsed }: Props) => {
  const { xs } = useBreakpoint();
  const { allowIf } = useAuth();
  const { showFeature } = useFeature();
  const { fetchPersonalLeaves } = useLeaves();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [data, setData] = useState(initLeaveRequest);
  const [openReport, setOpenReport] = useState(false);
  const location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname,
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const menuItems: MenuProps['items'] = [
    { key: '/dashboard', icon: <PieChartOutlined />, label: <Link to="/dashboard">Dashboard</Link> },
    showFeature('LEAVES') ? { key: '/apps/leave/calendar', icon: <CalendarOutlined />, label: <Link to="/apps/leave/calendar">Calendar</Link> } : null,
    showFeature('TIMESHEET') ? {
      key: 'sub1',
      icon: <FieldTimeOutlined />,
      label: 'Timesheets',
      children: [
        allowIf(['Timesheets_Approve']) && {
          key: '/apps/timesheet/print-timesheet',
          icon: <PrinterOutlined />,
          label: <Link to="/apps/timesheet/print-timesheet">Print Timesheet</Link>,
        },
        {
          key: '/apps/timesheet/list',
          icon: <UnorderedListOutlined />,
          label: <Link to="/apps/timesheet/list">Timesheet List</Link>,
        },
        {
          key: '/apps/timesheet/list/wp',
          icon: <UnorderedListOutlined />,
          label: <Link to="/apps/timesheet/list/wp">Timesheet per Work Package</Link>,
        },
      ].filter(Boolean),
    } : null,

    showFeature('LEAVES') ? {
      key: 'leaves',
      icon: <CarryOutOutlined />,
      label: 'Leaves',
      children: [
        allowIf(['LeaveApprovals_ReadWrite']) && {
          key: '/apps/leave/approval-list',
          icon: <SafetyOutlined />,
          label: <Link to="/apps/leave/approval-list">Leave Approvals</Link>,
        },
        {
          key: '/apps/leave/list',
          icon: <WalletOutlined />,
          label: <Link to="/apps/leave/list">My Leaves</Link>,
        },
        allowIf(['LeaveHistory_ViewAll']) && {
          key: '/apps/leave/history',
          icon: <HistoryOutlined />,
          label: <Link to="/apps/leave/history">Leave History</Link>,
        },
      ].filter(Boolean),
    } : null,
    showFeature('MEETING') && allowIf(['Projects_Read']) ? {
      key: 'projects',
      icon: <SolutionOutlined />,
      label: 'Projects',
      children: [
        allowIf(['Meetings_Read']) && {
          key: '/apps/projects/meetings',
          icon: <HistoryOutlined />,
          label: <Link to="/apps/projects/meetings">Meetings</Link>,
        },
        // allowIf(['Projects_Read']) && {
        //   key: '/apps/projects/meetings/upcoming',
        //   icon: <ClockCircleOutlined />,
        //   label: <Link to="/apps/projects/meetings/upcoming">Upcoming Meetings</Link>,
        // },
        {
          key: '/apps/projects/meetings/trip/list',
          icon: <CarOutlined />,
          label: <Link to="/apps/projects/meetings/trip/list">Trips</Link>,
        },
        allowIf(['Trips_Approve']) && {
          key: '/apps/projects/meetings/trip/approval',
          icon: <SafetyOutlined />,
          label: <Link to="/apps/projects/meetings/trip/approval">Trip Approvals</Link>,
        }
      ].filter(Boolean),
    } : null,
    showFeature('HUMANRESOURCES') ? {
      key: 'human_resources',
      icon: <UserOutlined />,
      label: 'Human Resources',
      children: [
        {
          key: '/apps/human/teams',
          icon: <UsergroupAddOutlined />,
          label: <Link to="/apps/human/teams">Teams</Link>,
        },

      ].filter(Boolean),
    } : null,
  ].filter(Boolean) as MenuProps['items'];

  const items: MenuProps['items'] = [
    showFeature('TIMESHEET') ? {
      key: 'add_timesheet',
      label: <Link to="apps/timesheet/create">Timesheet</Link>,
      icon: <UnorderedListOutlined />,
    } : null,

    showFeature('LEAVES') ? {
      key: 'add_leave',
      icon: <CalendarOutlined />,
      label: (
        <Typography onClick={() => setCreateModalOpen(true)}>Leave</Typography>
      ),
    } : null,

    showFeature('MEETING') ? {
      key: 'add_projectMeeting',
      icon: <ClockCircleOutlined />,
      label: (
        <Link to="apps/projects/meetings/create">
          Meeting
        </Link>
      ),
    } : null,
    {
      key: 'add_trip',
      icon: <CarOutlined />,
      label: (
        <Link to="apps/projects/meetings/trip/create">
          Trip
        </Link>
      ),
    }
  ].filter(Boolean);

  return (
    <Sider
      collapsedWidth={xs ? 0 : 100}
      width={xs ? 250 : 250}
      collapsible
      collapsed={xs ? !collapsed : collapsed}
      trigger={!xs && null}
      onCollapse={(value) => { xs ? setCollapsed(!value) : setCollapsed(value) }}
      style={{
        overflowY: 'scroll',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 10,
      }}
    >
      <CalendarModalForm
        fetchData={fetchPersonalLeaves}
        isOpen={isCreateModalOpen}
        setIsOpen={setCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        data={data}
        setData={setData}
      />
      <ReportModal
        isOpen={openReport}
        onClose={() => setOpenReport(false)}
      />

      <div className={`flex flex-row items-center  ${collapsed ? 'justify-center mt-8' : 'mt-12'}`}>
        <Dropdown menu={{ items }} placement='bottom'>
          <Button
            type="primary"
            className='w-12 h-12 rounded-full text-xl shadow-lg'
            style={{
              display: xs ? !collapsed ? 'none' : 'flex' : 'flex',
              zIndex: 50,
              position: 'fixed',
              bottom: 40,
              left: collapsed ? 75 : 225,
              gap: 4,
              padding: 24,
              overflow: 'initial',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              background: AppColors.primary,
            }}
            data-testid="addEntryBtn"
          >
            <PlusCircleOutlined size={64} />
          </Button>
        </Dropdown>
        <SidebarLogo setCollapsed={setCollapsed} collapsed={collapsed} />
        <Button
          className="border-solid border border-gray-200 rounded-lg cursor-pointer shadow-2xl"
          icon={
            <LeftOutlined
              className={`text-gray-400 transform transition-all duration-300 ${collapsed ? 'rotate-180' : 'rotate-0'}`}
              style={{ fontSize: '12px' }}
            />
          }
          onClick={() => setCollapsed(!collapsed)}
          style={{
            position: 'fixed',
            top: 50,
            left: collapsed ? 80 : 230,
            zIndex: 50,
          }}
        />
      </div>
      <Menu
        inlineIndent={10}
        theme="dark"
        style={{
          marginTop: 20,
        }}
        selectedKeys={[current]}
        mode="inline"
        items={menuItems}
        overflowedIndicator
      />
      <div className='absolute bottom-0 w-full bg-red-500 transform transition-all duration-300' style={{ width: collapsed ? xs ? "100%" : 100 : 250, display: xs ? collapsed ? '' : 'none' : "" }}>
        <Button data-testid="reportBtn" onClick={() => setOpenReport(true)} className=' rounded-none mx-auto transform transition-all duration-300' style={{ width: '100%' }} icon={<ExclamationCircleOutlined />}>{collapsed && !xs ? '' : 'Report an issue'}</Button>
      </div>

    </Sider>
  );
};

export default Sidebar;
