import { createContext } from 'react';
import { Beneficiary } from '../../../../api-requests/Projects';

export interface ITripContext {
    trips: any[];
    getTrip: any;
    tripsTotal: number;
    loading: boolean;
    handleDeleteTripById: (d: number) => void;
    getBeneficiaries: (projectId: number | undefined) => Promise<Beneficiary[] | undefined>;
    beneficiaries: Beneficiary[];
    tripApprovals: any[];
    getApprovalTrips: any;
    tripApprovalTotal: number;
}

const TripContext = createContext<ITripContext | undefined>(
    undefined,
);

TripContext.displayName = 'Trip';

export default TripContext;
